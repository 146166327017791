/**
 * https://help.aliyun.com/document_detail/193141.html
 * 对 阿里滑块验证的样式封装, 维持与腾讯云滑块验证类似的使用
 * 所以不是 react 组件
 * */
import './AliCaptcha.scss';

class AliCaptcha {
  public nc: NC;
  private wrapperId = 'eid-ali-captcha-modal';
  private ncNodeId = 'eid-ali-captcha-modal-nc';

  constructor(
    appKey: string,
    scene: string,
    onSuccess: (data: { sessionId: string; sig: string; token: string }) => void,
    onError: (errorCode: string) => void,
  ) {
    this.createModal();
    AWSC.use('nc', (state, module) => {
      this.nc = module.init({
        appkey: appKey,
        scene,
        // test: module.TEST_PASS, // 用来调试用
        renderTo: this.ncNodeId, // 声明滑动验证需要渲染的目标ID。
        success: (data: { sessionId: string; sig: string; token: string }) => {
          if (onSuccess) onSuccess(data);
          this.hide();
        },
        fail: onError, // 滑动验证失败时触发该回调参数。
        error: onError, // 验证码加载出现异常时触发该回调参数。
      });
    });
  }

  createModal() {
    const exitNode = document.getElementById(this.wrapperId);
    if (exitNode) document.body.removeChild(exitNode);
    const modalWrapper = document.createElement('div');
    modalWrapper.id = this.wrapperId;
    const modalContent = document.createElement('div');
    modalContent.className = 'eid-ali-captcha-modal-content';
    const title = document.createElement('h4');
    title.className = 'eid-ali-captcha-modal-header';
    title.innerText = '安全验证';
    const closeIcon = document.createElement('i');
    closeIcon.className = 'iconfont icon-TxError eid-ali-captcha-modal-close-icon';

    closeIcon.addEventListener('click', () => {
      this.hide();
    });

    const node = document.createElement('div');
    node.id = this.ncNodeId;
    modalContent.appendChild(title);
    modalContent.appendChild(node);
    modalContent.appendChild(closeIcon);
    modalWrapper.appendChild(modalContent);
    document.body.appendChild(modalWrapper);
  }

  show() {
    const wrapper = document.getElementById(this.wrapperId);
    if (!wrapper) return;
    wrapper.className = 'eid-ali-captcha-modal-show';
  }
  hide() {
    const wrapper = document.getElementById(this.wrapperId);
    if (!wrapper) return;
    document.body.removeChild(wrapper);
    this.nc.hide();
  }
}

export default AliCaptcha;

import JSRuntime from 'common/utils/JSRuntime';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { LOGIN_RES_TYPE } from '../constants/Constants';
import { IAccount, IHandshake } from '../constants/Types';
import { INVITE_FROM, LOGIN_METHOD } from './constants/Constants';
import { IAccountResult } from './constants/Types';

class BaseStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<BaseStore>;
  public resetStore: () => void;

  public hasDealLogin = false;
  public loginMethod =
    JSRuntime.isTencentMeeting && !JSRuntime.isMobileDevice && !JSRuntime.isWechatMiniProgram
      ? LOGIN_METHOD.MEET_QRCODE
      : LOGIN_METHOD.MOBILE;
  // NOTICE： 会议微信扫码后，并没有拿到手机号，刚好需要手机号的后续步骤只有 invitation, 而会议扫码流程不走邀请
  public mobile = '';
  // NOTICE: 邮箱登录的也可能进入 invitation ， mobile | email 二选一
  public email = '';
  // NOTICE 这里注入了navigate，正常开发不要在Store中进行路由跳转
  public redirectTo: ((path: string) => void) | null = null;
  // 账号选择 结果
  public accountOptions = { accounts: [] as IAccount[], unavailable: [] as IAccount[] };
  // 自然人邀请 结果
  public handshakeOptions = { handshakes: [] as IHandshake[] };
  // next的type
  public nextType = '';
  // 邀请方式 手机号 | 邮箱
  public inviteFrom = INVITE_FROM.MOBILE;

  public annotationOverrides = {
    redirectTo: false,
  };

  public resetStoreProxy() {
    const redirect = this.redirectTo;
    this.resetStore();
    this.redirectTo = redirect;
  }

  // 社会认证源认证后也会触发这里
  public dealLoginSuccess(res: IAccountResult, mobile?: string, email?: string) {
    // 有第三个参数 email 证明是 邮箱登录进来的
    if (email) {
      this.updateStore({ inviteFrom: INVITE_FROM.EMAIL, email });
    } else {
      const realMobile = mobile || _.get(res, 'mobile') || '';
      if (realMobile) this.updateStore({ mobile: realMobile, inviteFrom: INVITE_FROM.MOBILE });
    }
    const successType = _.get(res, 'next.type');

    // 外部调用时也会走到 ACCOUNT_OPTIONS INVITATION_OPTIONS
    this.updateStore({
      hasDealLogin: true,
      accountOptions: _.get(res, 'next.accountOptions') || { accounts: [], unavailable: [] },
      handshakeOptions: _.get(res, 'next.handshakeOptions') || { handshakes: [] },
      nextType: successType,
    });

    switch (successType) {
      case LOGIN_RES_TYPE.ACCOUNT_CREATE:
        this.redirectTo?.('/login/choose_account');
        break;
      case LOGIN_RES_TYPE.ACCOUNT_OPTIONS: {
        this.redirectTo?.('/login/choose_account');
        break;
      }
      case LOGIN_RES_TYPE.INVITATION_OPTIONS:
        // NOTICE： 当前产品规划上，邮箱登录不会进入 邀请部分
        this.redirectTo?.('/login/invitation');
        break;
      case LOGIN_RES_TYPE.LOGIN_SUCCESS:
        // NOTICE 个人版账户之后，登录后只有个人版账号的时候才会返回这个状态 @张赟
        /**
         * 多个企业：返回账号选择
         * 单个企业版，无个人版：直接跳到portal页面
         * 单个个人版：返回LOGIN_SUCCESS
         */
        this.global.rootStore.redirectToLandingPage();
        break;
      default:
        break;
    }
    return Promise.resolve();
  }

  public reportLoginResult(params?: Record<string, any>) {
    this.global.rootStore.dataReport('e#login_result_web', params);
  }
}

export default BaseStore;

import VerifyCodeWithMsg, { IProps as OriginIProps } from './VerifyCodeWithMsg';

interface IProps extends OriginIProps {
  footer?: React.ReactNode;
}
const VerifyCodeWithFooter = (props: IProps) => {
  const { footer, ...restProps } = props;
  return (
    <>
      <VerifyCodeWithMsg {...restProps} />
      {footer}
    </>
  );
};
export default React.memo(VerifyCodeWithFooter);

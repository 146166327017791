import { CREDENTIAL_ERROR } from 'common/constants/Constants';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';
import { RESET_PWD_ERROR_CODE } from 'idpBase/Constants';
import { SET_PASSWORD_TIPS } from '../constants/Constants';

class SetPasswordStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<SetPasswordStore>;
  public resetStore: () => void;

  public isBlockLoading = true;

  public policy = {};

  public isSetLoading = false;

  public pwdErrorMessage = '';

  public redirectToLandingPage = () => {
    this.global.rootStore.redirectToLandingPage();
  };

  // 修改密码
  public async setPwd(password: string) {
    this.isSetLoading = true;
    const credential = await this.global.rootStore.encryptRequest({ password });
    (credential
      ? AuthnApi.setEnterprisePwd(credential)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } })
    )
      .then(() => {
        this.global.handleSuccess('密码设置成功');
        this.redirectToLandingPage();
      })
      .catch((error: Error) => {
        const errCode = _.get(error, 'data.errCode');
        const errMsg = _.get(error, 'data.errMessage');
        if (errCode === 'E0010170' || errCode === 'E0010171') {
          this.updateStore({
            pwdErrorMessage: errMsg,
          });
        } else if (errCode === 'E0010033') {
          this.global.handleError(SET_PASSWORD_TIPS.PASSWORD_V0.POLICY_UPGRADE);
          // 重新获取密码密码策略
          setTimeout(() => {
            this.getPwdPolicy();
          }, 0);
        } else {
          this.global.handleError(
            _.includes(RESET_PWD_ERROR_CODE, errCode) ? errMsg : '服务出现了一些问题，请再试一次',
          );
        }
      })
      .finally(() => {
        this.updateStore({
          isSetLoading: false,
        });
      });
  }

  // 获取密码策略
  public getPwdPolicy() {
    this.isBlockLoading = true;
    return AuthnApi.getPasswordPolicy()
      .then((res) => {
        this.updateStore({
          policy: res,
        });
      })
      .catch((error: Error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isBlockLoading: false,
        });
      });
  }
}

export default SetPasswordStore;

/**
 * 代替 lodash, 一些适用于 mobx 的工具方法,
 * import m from 'common/utils/mobx;
 *
 * m.get(object, path);
 * */
import * as mobx from 'mobx';
import _, { PropertyPath } from 'lodash';

class M {
  /**
   * @example
   * // 计算结果保持与 _.get 一致可以传入 普通对象或者 observable
   * var object = mobx.observable({ 'a': [{ 'b': { 'c': 3 } }] });
   *
   * m.get(object, 'a[0].b.c');
   * // => 3
   *
   * m.get(object, ['a', '0', 'b', 'c']);
   * // => 3
   *
   * m.get(object, 'a.b.c', 'default');
   * // => 'default'
   */
  public get(object: any, path: PropertyPath, defaultValue?: any) {
    // eslint-disable-next-line no-param-reassign
    path = mobx.isObservableArray(path) ? mobx.toJS(path) : path;

    if (!mobx.isObservable(object)) return _.get(object, path, defaultValue);

    const castPath = _.toPath(path);
    let result: any = object;
    let index = 0;
    const length = castPath.length;

    // eslint-disable-next-line eqeqeq
    while (result != null && index < length) {
      if (mobx.isObservableArray(result) && !mobx.has(result, castPath[index])) {
        result = undefined;
      } else {
        result = result[castPath[index]];
      }
      index += 1;
    }
    return index && index === length && result !== undefined ? result : defaultValue;
  }

  public isArray(value: any): value is any[] {
    if (mobx.isObservable(value)) return mobx.isObservableArray(value);
    return _.isArray(value);
  }

  /**
   * 贴合 _.isObject
   * function Array Object 都会返回 true, null 返回 false
   * */
  public isObject(value: any): value is object {
    return mobx.isObservable(value) || _.isObject(value);
  }

  /**
   * 贴合 _isPlainObject 是否为普通对象
   * function Array null 都会返回 false
   * */
  public isPlainObject(value: any): boolean {
    if (mobx.isObservable(value)) return mobx.isObservableObject(value);
    return _.isPlainObject(value);
  }
}

const m = new M();

export default m;

import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';

const Oauth2Transfer = () => {
  React.useEffect(() => {
    Utils.safeRedirect(`${JSRuntime.accountOauth2Site}/v1/authorize${window.location.search}`);
  }, []);

  return null;
};

export default React.memo(Oauth2Transfer);

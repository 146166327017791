import kiwiIntl from 'kiwi-intl';
import { getCurrentLang, LangEnum } from './languageDetector';
import enUsMessages from '../../.kiwi/en-US';
import zhCNMessages from '../../.kiwi/zh-CN';

const customLangs = {
  'en-US': enUsMessages,
  'zh-CN': zhCNMessages,
};

const curLang = getCurrentLang();

const I18N = kiwiIntl.init(curLang, customLangs);

export default I18N;

export { getCurrentLang, customLangs, LangEnum };

export default {
  USER: 'user',
  USERS: 'users',
  AUTHN: 'authn',
  PERSONAL_ACCOUNT: 'personal_account',
  SOCIAL_IDP: 'socialIdp',
  ACCOUNT: 'account',
  SSO_IDP: 'ssoIdp',
  ACCOUNTS: 'accounts',
  ROLES: 'roles',
  BLACK_AND_WHITELIST: 'blackAndWhiteList',
};

import { useState } from 'react';
import { FormInstanceFunctions } from 'tdesign';
import AccountLogo from 'common/components/AccountLogo';
import Button from 'common/components/Button';
import notFoundImg from 'common/static/images/notFound.png';
import successResult from 'common/static/images/successResult.png';
import Utils from 'common/utils';
import PasswordConfirm from 'idpBase/compositions/PasswordConfirm';
import { PWD_CONFIRM_TYPE } from 'idpBase/compositions/PasswordConfirm/Constants';
import { convert, useStore } from 'idpBase/store';
import ResetPwdScss from '../style/ResetPassword.module.scss';

const Pwd = () => {
  const pwdConfirmRef = React.useRef<FormInstanceFunctions>(null);
  const [success, setSuccess] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const { resetPasswordStore } = useStore();
  const { policy, pwdErrorMessage, isValid, logoUrl, userName, enterpriseName } =
    resetPasswordStore;

  const onSubmit = async () => {
    if (!pwdConfirmRef?.current) return;

    const validRes = await pwdConfirmRef.current?.validate?.();
    if (validRes !== true) return;
    const values = pwdConfirmRef.current?.getFieldsValue?.(true);
    const password = m.get(values, 'password');
    setIsResetLoading(true);
    const [err] = await Utils.resolvePromise(
      resetPasswordStore.resetPwd({
        password,
      }),
    );
    setIsResetLoading(false);
    !err && setSuccess(true);
  };

  const onPwdChange = () => {
    resetPasswordStore.updateStore({
      pwdErrorMessage: '',
    });
  };
  if (success) {
    return (
      <div className={ResetPwdScss.resultContainer}>
        <img className={ResetPwdScss.resultImg} src={successResult} />
        <h1 className={ResetPwdScss.status}>重置密码成功</h1>
        <Button
          className={ResetPwdScss.resultBtn}
          color="secondary"
          onClick={() => {
            Utils.redirectToIdp();
          }}
        >
          前往登录
        </Button>
      </div>
    );
  }

  return isValid ? (
    <div className={Utils.uniteClass('eid-card', ResetPwdScss.card)}>
      <AccountLogo className={ResetPwdScss.brand} logoUrl={logoUrl} companyName={enterpriseName} />
      <div className={ResetPwdScss.tipText}>您的登录用户名:&nbsp;{userName}</div>
      <div className={ResetPwdScss.tipText}>您的原密码已经失效，请设置新的密码。</div>
      <PasswordConfirm
        type={PWD_CONFIRM_TYPE.RESET}
        onEnter={onSubmit}
        formRef={pwdConfirmRef}
        policy={policy}
        pwdErrMsg={pwdErrorMessage}
        onChange={onPwdChange}
      />
      <Button loading={isResetLoading} className={ResetPwdScss.btn} onClick={onSubmit}>
        确认
      </Button>
    </div>
  ) : (
    // 这个div是必须的，否则不能正常排版
    <div>
      <img className={ResetPwdScss.notFoundImg} src={notFoundImg} />
      <h1 className={ResetPwdScss.expireTitle}>当前链接已失效</h1>
      <div className={ResetPwdScss.notFound}>页面未找到</div>
    </div>
  );
};

export default convert(Pwd);

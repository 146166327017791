import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';

class MeetingWecomQrCodeStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<MeetingWecomQrCodeStore>;
  public resetStore: () => void;

  public loginByWeMeetWecomQrCode(code: string) {
    return AuthnApi.weMeetWecomQrCodeLogin({ auth_code: code })
      .then((res) => {
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: 'wecom',
          login_result: 'success',
        });
        // 扫码登录不走 invitation 流程，不需要传 mobile
        return this.global.login.loginBaseStore.dealLoginSuccess(res as any);
      })
      .catch((err) => {
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: 'wecom',
          login_result: 'failed',
          login_error_code: _.get(err, 'data.errCode'),
        });
        this.global.handleError(err);
      });
  }
}

export default MeetingWecomQrCodeStore;

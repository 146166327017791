import { useLocation } from 'react-router-dom';
import PageLayoutScss from '../style/PageLayout.module.scss';
import publicStyles from 'common/styles/eid-variables-in-js.module.scss';

// idp主题色，后期根据需要可能会通过接口动态获取
const IDP_BG_COLOR = publicStyles.tdColorWhite; // #EEF5FE 淡蓝色 #585996 10% 淡紫色 #FFFFFF 白色
interface IProps {
  children: React.ReactNode;
}

const PageLayout: React.FC<IProps> = (props: IProps) => {
  const { children } = props;
  const location = useLocation();

  if (location.pathname === '/profile') {
    return <>{children}</>;
  }

  return (
    <div className={PageLayoutScss.layout} style={{ backgroundColor: IDP_BG_COLOR }}>
      <div className={PageLayoutScss.content}>{children}</div>
    </div>
  );
};

export default React.memo(PageLayout);

/*
 * API 接口配置 基本请求封装
 */

import axios from 'axios';
import * as AxiosTypes from 'axios';
import JSRuntime from 'common/utils/JSRuntime';
import { TRequestUrlParams } from './CommonTypes';
import RequestPromise from './RequestPromise';
import * as Utils from './Utils';

type TAxiosResData = AxiosTypes.AxiosResponse<any>['data'];

export type TResponse = RequestPromise<TAxiosResData>;

// request 请求配置
// url 允许传入字符串数组和字符串
export type TRequestConfig = Omit<AxiosTypes.AxiosRequestConfig, 'url'> & {
  url?: Array<string> | string;
  headers?: IRequestHeaders;
  params?: TRequestUrlParams;
  data?: TRequestBodyData;
};

// 初始化时生成 request instance 的配置
export interface IRequestInstanceConfig extends TRequestConfig {
  baseURL?: string;
  url?: string;
  responseInterceptors?: IResponseInterceptors;
}

export type TApiError = AxiosTypes.AxiosError<{
  errCode: string | number;
  errMessage: string;
  target?: string;
  details?: object;
}>;

// 请求response拦截器
export interface IResponseInterceptors {
  onSuccess: (response: AxiosTypes.AxiosResponse) => Promise<AxiosTypes.AxiosResponse['data']>;
  onError: (error: TApiError) => Promise<TApiError['response'] | { status: number }>;
}

interface IRequestHeaders {
  [k: string]: string;
}

type TRequestBodyData = {
  [k: string]: any;
} | null;

class Request {
  private readonly serviceName: string;

  protected baseURL: string;

  private readonly responseInterceptors: IResponseInterceptors;

  public axiosInstance: AxiosTypes.AxiosInstance;

  constructor(serviceName?: string, instanceConfig?: IRequestInstanceConfig) {
    this.serviceName = serviceName || '';
    const { baseURL, headers, responseInterceptors, ...otherConfig } = instanceConfig || {};

    this.baseURL = baseURL || '/';

    this.responseInterceptors = {
      ...this.getResponseInterceptors(),
      ...responseInterceptors,
    };

    const axiosConfig: AxiosTypes.AxiosRequestConfig = {
      baseURL: Utils.jointPath([this.baseURL]),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        ...headers,
      },
      timeout: 0,
      withCredentials: true,
      paramsSerializer: Utils.paramsSerializer,
      ...otherConfig,
    };
    this.axiosInstance = this.createInstance(axiosConfig);
  }

  private createInstance(axiosConfig: AxiosTypes.AxiosRequestConfig): AxiosTypes.AxiosInstance {
    const axiosInstance = axios.create(axiosConfig);
    const { onSuccess, onError: onResError } = this.responseInterceptors;

    _.set(axiosInstance, 'interceptors.request', axios.interceptors.request);

    axiosInstance.interceptors.response.use(onSuccess, (error: TApiError) => {
      // Tips: request abort
      if (axios.isCancel(error)) {
        // https://stackoverflow.com/questions/46234679/what-is-the-correct-http-status-code-for-a-cancelled-request
        // 499 Client Closed Request
        // Used when the client has closed the request before the server could send a response.
        return onResError({
          response: {
            status: 499,
          },
        } as TApiError);
      }
      return onResError(error);
    });
    return axiosInstance;
  }

  // Tips: 子类会重写该方法，例如DecoratorRequest.ts中
  // eslint-disable-next-line class-methods-use-this
  protected getResponseInterceptors(): IResponseInterceptors {
    const onSuccess = (response: AxiosTypes.AxiosResponse) =>
      Promise.resolve(_.get(response, 'data'));
    return {
      onSuccess,
      onError(error: TApiError) {
        return Promise.reject(error.response);
      },
    };
  }

  public request(config: TRequestConfig) {
    const { method = 'GET', url = '', data = {}, params, headers, timeout } = config;

    // url上传递token OR iframe parent传递token
    const specifiedToken = JSRuntime.urlToken || JSRuntime.tokenFromMessage;

    const requestHeaders = {
      ...headers,
      // 如果外部指定token，则需要动态加入headers
      ...(specifiedToken ? { Authorization: `Bearer ${specifiedToken}` } : {}),
    };

    const cancelSource = axios.CancelToken.source();
    let urlArr: string[] = [];
    if (_.isString(url) && url) {
      urlArr = [url];
    } else if (_.isArray(url)) {
      urlArr = url;
    }
    const promise = this.axiosInstance.request({
      url: Utils.jointPath([this.serviceName, ...urlArr]),
      method,
      headers: requestHeaders,
      timeout,
      params: { ...(method.toUpperCase() === 'GET' ? data : {}), ...params },
      data: method.toUpperCase() === 'GET' ? undefined : data,
      cancelToken: cancelSource.token,
    });
    const requestPromise = new RequestPromise<TAxiosResData>(promise, cancelSource.cancel);
    return requestPromise;
  }

  public get(url?: Array<string> | string | null, params?: TRequestUrlParams) {
    const ieMockParams = _.get(window, '__YUFU_GLOBAL__.BROWSER_ANALYSIS_RESULT.isIE')
      ? { send_time_for_ie_browser_t: +new Date() }
      : {};
    // Tips: 此处 send_time_for_ie_browser_t 比较特殊，所以使用了类型断言；
    const requestConfig = {
      url: url || '',
      params: { ...params, ...ieMockParams },
    } as TRequestConfig;
    return this.request(requestConfig);
  }

  public post(
    url?: Array<string> | string | null,
    data?: TRequestBodyData,
    params?: TRequestUrlParams,
  ) {
    return this.request({
      url: url || '',
      data,
      params,
      method: 'POST',
    });
  }

  public put(
    url?: Array<string> | string | null,
    data?: TRequestBodyData,
    params?: TRequestUrlParams,
  ) {
    return this.request({
      url: url || '',
      data,
      params,
      method: 'PUT',
    });
  }

  public del(
    url?: Array<string> | string | null,
    data?: TRequestBodyData,
    params?: TRequestUrlParams,
  ) {
    return this.request({
      url: url || '',
      data,
      params,
      method: 'DELETE',
    });
  }

  public patch(
    url?: Array<string> | string | null,
    data?: TRequestBodyData,
    params?: TRequestUrlParams,
  ) {
    return this.request({
      url: url || '',
      data,
      params,
      method: 'PATCH',
    });
  }
}

export default Request;

/**
 * 后端鉴权后返回页
 * */
import qs from 'qs';
import Utils from 'common/utils';
import message from 'common/components/Message';

const RedirectIdentityTransfer = () => {
  const queryObj = qs.parse(_.trimStart(window.location.search || '', '?'));
  const setPwd = m.get(queryObj, 'set_password');

  React.useEffect(() => {
    // PC 端父窗口消失
    if (!window.opener) {
      message.error('绑定失败', 24 * 60 * 60 * 1000);
      console.error('未找到父级窗口');
      return Utils.noop;
    }

    // 认证源登录会打开一个窗口，这里通过 postMessage 来根主窗口通信
    window.opener.postMessage(
      {
        type: 'POPUP_AUTH_SUCCESS',
        data: {
          isSetPassword: setPwd,
        },
      },
      '*',
    );
    return Utils.noop;
  }, []);

  return null;
};

export default React.memo(RedirectIdentityTransfer);

import { VERIFY_CODE_LIMIT_ERR } from 'common/constants/Constants';
import { useStore, convert } from 'idpBase/store';
import JSRuntime from 'common/utils/JSRuntime';
import Utils from 'common/utils';
import { CONTENT, INVITE_FROM } from '../constants/Constants';
import Agreement from 'idpBase/components/Agreement';
import UserAvatar from 'common/components/UserAvatar';
import OrgDefaultLogo from 'common/static/images/lightOrgLogo.png';
import Button from 'common/components/Button';
import Dialog from 'common/components/Dialog';
import VerifyDialog from './VerifyDialog';
import InvitationScss from '../style/Invitation.module.scss';

const InvitationContent = () => {
  const { invitationStore } = useStore();
  const { invitationInfo, isAcceptBtnLoading, inviteFrom } = invitationStore;
  const [agreement, setAgreement] = React.useState(false);

  const inviter = m.get(invitationInfo, 'inviter') || '-';
  const mobile = m.get(invitationInfo, 'mobile');
  const isNewMobile = m.get(invitationInfo, 'isNewMobile');
  const companyLogo = m.get(invitationInfo, 'account.companyInfo.logoUrl');
  const companyName = m.get(invitationInfo, 'account.companyInfo.companyName') || '-';

  const onAgreementChange = (checked: boolean) => {
    setAgreement(checked);
  };

  const getVerifyCode = () => {
    invitationStore.updateStore({ isAcceptBtnLoading: true });
    invitationStore
      .getVerifyCode(mobile)
      .then((codeRes) => {
        if (JSRuntime.isMobileDevice) {
          invitationStore.updateStore({
            currentContent: CONTENT.MOBILE_VERIFY,
            countdown: _.get(codeRes, 'expire') || 0,
            verificationSessionId: _.get(codeRes, 'verificationSessionId'),
          });
        } else {
          invitationStore.updateStore({
            dialogVisible: true,
            verificationSessionId: _.get(codeRes, 'verificationSessionId'),
            countdown: _.get(codeRes, 'expire') || 0,
          });
        }
      })
      .catch((err) => {
        const errorCode = _.get(err, 'data.errCode');
        if (VERIFY_CODE_LIMIT_ERR.has(errorCode)) {
          invitationStore.updateStore(
            JSRuntime.isMobileDevice
              ? {
                  currentContent: CONTENT.MOBILE_VERIFY,
                  codeErrMsg: errorCode,
                }
              : {
                  dialogVisible: true,
                  codeErrMsg: errorCode,
                },
          );
        } else {
          invitationStore.global.handleError(err);
        }
      })
      .finally(() => {
        invitationStore.updateStore({
          isAcceptBtnLoading: false,
        });
      });
  };

  // 短信加入流程
  // 1. user表中存在的老手机号在接受邀请时，不需要登录和验证，点击【立即加入】后，立即生成自然人账号和企业身份的真正绑定关系；
  // 2. 一个纯新手机号（user表中没有）在接受邀请时，点击了【立即加入】后，需要验证码验证
  // 邮箱加入流程
  // 1. 先发送接受邀请
  // 2. 如果管理员没有填写联系手机号，那么收到邀请的用户点击【立即加入】时，需要先填写手机号，再接收验证码。
  const join = () => {
    if (inviteFrom === INVITE_FROM.MOBILE) {
      // 通过短信收到的邀请，一定含有手机号，不存在输入手机号那一步
      if (isNewMobile) {
        getVerifyCode();
      } else {
        // 直接走接受邀请
        invitationStore.acceptInvitation();
      }
    }
    // 如果是邮箱验证，那么需要设置密码，会先触发接受邀请接口，然后决定是否设置密码
    if (inviteFrom === INVITE_FROM.EMAIL) {
      // 邮箱邀请，直接走接受邀请，在接口返回中判定是否需要设置密码
      invitationStore.acceptInvitation();
    }
  };

  const onJoin = () => {
    if (agreement) {
      join();
    } else {
      Dialog.alert?.({
        className: JSRuntime.isMobileDevice ? InvitationScss.mobileAgreeAlert : '',
        header: '同意协议并加入企业',
        body: (
          <span>
            同意
            <a
              className={InvitationScss.agreeLink}
              href={JSRuntime.agreementUrl.service}
              target="_blank"
            >
              软件许可服务协议
            </a>
            和
            <a
              className={InvitationScss.agreeLink}
              href={JSRuntime.agreementUrl.privacy}
              target="_blank"
            >
              隐私协议
            </a>
            并加入企业
          </span>
        ),
        confirmBtn: '同意协议并加入',
        onConfirm: () => {
          setAgreement(true);
          join();
        },
      });
    }
  };

  const onJoinClick = () => {
    // 先去check
    invitationStore.updateStore({ isAcceptBtnLoading: true });
    invitationStore
      .checkInvitationInfo()
      .then(() => {
        onJoin();
      })
      .catch(Utils.noop)
      .finally(() => {
        invitationStore.updateStore({ isAcceptBtnLoading: false });
      });
  };

  return (
    <>
      <div className={InvitationScss.content}>
        <UserAvatar
          size={120}
          className={InvitationScss.companyLogo}
          logoUrl={companyLogo}
          defaultLogoUrl={OrgDefaultLogo}
        />
        <div className={InvitationScss.inviter}>
          <span className={InvitationScss.inviterName}>{inviter}</span>
          <span className={InvitationScss.desc}>邀请您加入</span>
        </div>
        <span className={InvitationScss.companyName}>{companyName}</span>
        <div className={InvitationScss.btnWrapper}>
          <Button
            className={InvitationScss.btn}
            color="primary"
            loading={isAcceptBtnLoading}
            onClick={onJoinClick}
          >
            立即加入
          </Button>
        </div>
      </div>

      <Agreement
        className={InvitationScss.agreement}
        checked={agreement}
        onChange={onAgreementChange}
      />
      <VerifyDialog />
    </>
  );
};

export default convert(InvitationContent);

import { AuthnApi as CommonAuthnApi } from 'common/api';
import { AuthnApi } from 'idpBase/api';
import { SOCIAL_IDP_TYPE } from 'common/components/SocialIdpCertWindow';

// 初始化
// 需要获取QQ、企微、微信二维码需要的信息
export const init = (idpType: SOCIAL_IDP_TYPE) => {
  switch (idpType) {
    case SOCIAL_IDP_TYPE.QQ:
    case SOCIAL_IDP_TYPE.WECHAT:
      return AuthnApi.getSocialIdp(idpType);
    case SOCIAL_IDP_TYPE.WECOM:
      return CommonAuthnApi.getWeComInfo(SOCIAL_IDP_TYPE.WECOM).then((res) => {
        const curSsoClientId = m.get(res, 'ssoClientId');
        const state = m.get(res, 'state');
        return Promise.resolve({
          appId: curSsoClientId,
          state,
        });
      });
    default:
      return null;
  }
};

/**
 * 因为 editor 相关文件体积较大 @monaco-editor/react 默认使用 https://cdn.jsdelivr.net 提供的 CDN 资源
 * 这里为了确保在国内网络环境的访问情况，将其资源随着打包提交到项目所在的域名下（借助构建阶段实现）
 * 参考 ISSUE https://github.com/suren-atoyan/monaco-react/issues/419#issuecomment-1478846313
 * */

import Editor, { loader } from '@monaco-editor/react';
import JSRuntime from 'common/utils/JSRuntime';

loader.config({ paths: { vs: JSRuntime.getStaticPath('monaco-editor/min/vs') } });

export default Editor;

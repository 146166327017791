import { SOCIAL_IDP_TYPE, SOCIAL_IDP_TYPE_NAME } from 'common/components/SocialIdpCertWindow';
import { DEFAULT_COUNTDOWN_SECOND, VERIFY_CODE_SCENE } from 'common/constants/Constants';
import { TUpdateStore, TGlobalStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';
import { BIND_STEP } from './constants/Constants';

class SocialLoginStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<SocialLoginStore>;
  public resetStore: () => void;

  public socialIdpType = '';
  public socialIdpState = '';
  public isBindLoading = false;
  public mobile = '';
  public step = BIND_STEP.BIND_PHONE;
  public loginErrMsg = '';
  public isSendLoading = false;
  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;

  // 绑定手机号
  public bindSocialIdp(body: { verificationSessionId: string; verificationCode: string }) {
    this.isBindLoading = true;
    this.loginErrMsg = '';

    const promise =
      this.socialIdpType === SOCIAL_IDP_TYPE.WECOM
        ? AuthnApi.bindWeComIdp(this.socialIdpType, { ...body, state: this.socialIdpState })
        : AuthnApi.bindSocialIdp(
            this.socialIdpType as SOCIAL_IDP_TYPE.WECHAT | SOCIAL_IDP_TYPE.QQ,
            { ...body, state: this.socialIdpState },
          );

    return promise
      .then((res) => {
        this.global.handleSuccess('绑定成功');
        return Promise.resolve(res);
      })
      .catch((error) => {
        const errCode = m.get(error, 'data.errCode');
        switch (errCode) {
          case 'E0010120':
            this.global.handleError(
              `该手机号已绑定过${SOCIAL_IDP_TYPE_NAME[this.socialIdpType] || ''}`,
            );
            break;
          default:
            // NOTICE: 不像用户透露是否有注册过，一律按照 手机号验证码错误报 @南霄
            this.updateStore({
              loginErrMsg: '手机号或验证码错误',
            });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({
          isBindLoading: false,
        });
      });
  }

  public getVerifyCode(mobile: string) {
    return AuthnApi.sendVerificationCode({ mobile, event: VERIFY_CODE_SCENE.SOCIAL_LOGIN });
  }
}

export default SocialLoginStore;

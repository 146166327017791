import { INCLUDE, EXCLUDE } from 'common/constants/Constants';

export const POLICY_INCLUDE_TEXT = {
  [INCLUDE.MIN_LOWER_CASE]: '小写字母',
  [INCLUDE.MIN_UPPER_CASE]: '大写字母',
  [INCLUDE.MIN_NUMBER]: '数字字符0-9',
  [INCLUDE.MIN_SYMBOL]: '特殊字符（例如*_.$!等）',
};

export const POLICY_EXCLUDE_TEXT = {
  [EXCLUDE.CARD_NUM]: '工号',
  [EXCLUDE.EMAIL_PREFIX]: '邮箱前缀',
  [EXCLUDE.PHONE_NUM]: '手机号码',
  [EXCLUDE.USERNAME]: '登录用户名',
};

import {
  AccountApi,
  AuthnApi,
  SocialIdpApi,
  SsoIdpApi,
  UserApi,
  UsersApi,
  UsersApiV2,
} from 'common/api';
import UserAccountApi from 'common/api/services/UserAccountApi';
import { SOCIAL_IDP_TYPE, SOCIAL_IDP_TYPE_NAME } from 'common/components/SocialIdpCertWindow';
import {
  ACCOUNT_TYPE,
  APP_STORE_SIGN_ON_MODE,
  AUTHENTICATION_METHOD,
  CREDENTIAL_ERROR,
  EMAIL_VERIFY_CODE_SCENE,
  IDP_TYPE,
  USER_VERIFY_CODE_SCENE,
  VENDOR_TYPE,
  WECOM_BIND_STATUS,
} from 'common/constants/Constants';
import { IUserInfo } from 'common/constants/Types';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { AppStoreApi, PersonalEventsApi, PwdMatchedPolicyApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { DEFAULT_LOG_QUERY } from './constants/Constants';

class ProfileStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ProfileStore>;
  public resetStore: () => void;

  public isPersonalLoading = true;
  public isBlockLoading = true;
  public settings = { system: { smsEnabled: true } }; // 有登录态之后的 settings
  public features = { hasPortal: true, landingPage: '/' }; // 有登录态之后的 settings
  public weComInfo = {
    ssoTenantId: '',
    idpType: SOCIAL_IDP_TYPE.WECOM,
    status: WECOM_BIND_STATUS.DISABLED, // status: 1: 已绑定，0: 未绑定
  };

  public expire = { isExpire: false, expiredDate: '' };
  public showExpireTip = sessionStorage.getItem('showExpireTip') || 'true';
  public policy = {};
  public isPolicyLoading = false;

  public logQuery = DEFAULT_LOG_QUERY;
  public userLogs = {
    total: 0,
    list: [],
  };
  public isLogsLoading = false;
  public appStore = {};

  public get isPersonal() {
    const accountType = m.get(this.global.rootStore.userInfo, 'account.accountType');
    return accountType === ACCOUNT_TYPE.PERSONAL;
  }

  // 私有化 无法进accountManage 返回profile
  public get showAccountManageEntrance() {
    return !JSRuntime.isPrivate;
  }

  private matchDomain(domain: string) {
    const { host } = window.location;
    const configUrl = new URL(window.ONEID_PUBLIC_ORIGINAL_IDP_SITE);
    let currentDomain = _.replace(host, configUrl.host, '');
    currentDomain = _.trimEnd(currentDomain, '.');
    return currentDomain === domain;
  }

  public init() {
    return Promise.all([
      this.getWeComBindInfo(),
      this.getSettings(),
      this.checkExpired(),
      this.getAppStore(),
    ]);
  }

  // 个人版、企业版、账户管理中心的域名设置
  public domainSet = (userInfo: IUserInfo) => {
    const account = m.get(userInfo, 'account') || {};
    const domain = m.get(account, 'domain');

    const accountType = m.get(account, 'accountType');
    // 如果是个人版 直接走正常域名，不跳转企业级域名
    const configUrl = new URL(window.ONEID_PUBLIC_ORIGINAL_IDP_SITE);
    const { pathname, protocol, hash, search, host } = window.location;
    let currentDomain = _.replace(host, configUrl.host, '');
    currentDomain = _.trimEnd(currentDomain, '.');
    // NOTICE 目前一些个人版账号没有accountType
    if (accountType !== ACCOUNT_TYPE.COMPANY && currentDomain) {
      // 为了避免手动在个人版url上添加企业级域名，所以固定跳一下非企业级域名
      Utils.safeRedirect(`${protocol}//${configUrl.host}${pathname}${search}${hash}`);
    }

    // 如果是企业版，且当前企业标识，不等于企业域名的标识; 且当前不是账号管理中心页面
    if (hash?.includes('accountManage')) {
      // 非自然人登录 或者没有自然人的不能进accountManage 返回profile
      if (userInfo.authenticationMethod !== AUTHENTICATION_METHOD.PERSON || !userInfo.user) {
        Utils.safeRedirect(`${protocol}//${configUrl.host}${pathname}${search}#/profile`);
      } else {
        Utils.safeRedirect(`${protocol}//${configUrl.host}${pathname}${search}${hash}`);
      }
    } else if (
      !JSRuntime.isCustomDomain &&
      !this.matchDomain(domain) &&
      accountType === ACCOUNT_TYPE.COMPANY
    ) {
      Utils.safeRedirect(
        Utils.replaceUrlContent(window.ONEID_PUBLIC_ORIGINAL_IDP_SITE, { domain }),
      );
    }
  };

  public getSettings() {
    return AccountApi.getTenantSettings()
      .then((settings) => {
        const smsEnabled = _.get(settings, 'system.smsEnabled');
        const features = _.get(settings, 'account.features') || [];
        const featureMap = { ...this.features };
        features.forEach((feature: { code: string }) => {
          const code = _.get(feature, 'code') || '';
          const extras = _.get(feature, 'extras') || '';
          if (_.startsWith(code, 'menu:') || !extras || !_.isString(extras)) return;

          const props = Utils.safeParseJSON(extras);
          Object.assign(featureMap, props);
        });
        this.updateStore({
          features: featureMap,
          settings: { system: { smsEnabled } },
        });
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }

  // 仅用来校验及获得 avatarURL 地址
  public uploadAvatar(file: File) {
    const formData = new FormData();
    formData.append('avatar', file);
    return UsersApi.uploadAvatar(formData)
      .then((res) => _.get(res, 'avatar_url') || '')
      .catch((error) => Promise.reject(error));
  }

  // 获取企微绑定信息
  public getWeComBindInfo() {
    return AccountApi.getWeComBindInfo(IDP_TYPE.WeCom)
      .then((weComInfo) => {
        this.updateStore({ weComInfo });
      })
      .catch((error) => {
        this.global.handleError(error);
      });
  }

  // 给账户变更 avatarUrl
  public setAvatarUrl(avatarUrl: string) {
    return UsersApi.saveAvatar(avatarUrl)
      .then(() => {
        this.global.rootStore.getUserInfo().catch(Utils.noop);
      })
      .catch((error) => {
        this.global.handleError('更新失败，请稍后重试');
        return Promise.reject(error);
      });
  }

  public sendCode(event?: USER_VERIFY_CODE_SCENE, mobile?: string) {
    return UserApi.sendCode(event, mobile);
  }

  public verifyMobile(
    verificationCode: string,
    verificationSessionId: string,
    event?: USER_VERIFY_CODE_SCENE,
  ) {
    return UserApi.verifyMobile({
      verificationCode,
      verificationSessionId,
      event,
    });
  }

  // 自然人二次验证获取验证码
  public natureSendCode() {
    return AuthnApi.getSMS();
  }

  // 自然人二次验证验证码-会切换登录态
  public natureVerifyMobile(
    verificationCode: string,
    verificationSessionId: string,
    accountId: string,
  ) {
    return AuthnApi.verifyCode({
      verificationCode,
      verificationSessionId,
      accountId,
    });
  }

  public setMobile(data: { verificationCode: string; verificationSessionId: string }) {
    return UserApi.setMobile(data);
  }

  public async verifyOldPwd(password: string) {
    const credential = await this.global.rootStore.encryptRequest({ password });
    return credential
      ? UserApi.verifyOldPassword(credential)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }

  public async verifyEnterpriseOldPwd(password: string) {
    const credential = await this.global.rootStore.encryptRequest({ password });
    return credential
      ? UserApi.verifyEnterpriseOldPwd(credential)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }

  public async setOrResetPassword(password: string) {
    // 外部处理 catch 逻辑
    const credential = await this.global.rootStore.encryptRequest({ password });
    return credential
      ? UserApi.setOrResetPassword(credential)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }

  public async setOrResetEnterprisePwd(password: string) {
    const credential = await this.global.rootStore.encryptRequest({ password });
    return credential
      ? UserApi.setOrResetEnterprisePwd(credential)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }

  // 获取社会认证源信息 微信、QQ
  public getSocialIdpData(idpType: SOCIAL_IDP_TYPE) {
    // 企业微信的单独接口
    const promise =
      idpType === SOCIAL_IDP_TYPE.WECOM
        ? AuthnApi.getWeComInfo(SOCIAL_IDP_TYPE.WECOM)
        : AuthnApi.getSocialIdp(idpType);

    return promise.catch((err) => {
      this.global.handleError(err);
      return Promise.reject(err);
    });
  }

  public unbindSocialIdp(idpType: SOCIAL_IDP_TYPE) {
    // 企业微信的单独接口
    const promise =
      idpType === SOCIAL_IDP_TYPE.WECOM
        ? SsoIdpApi.unbindUser(SOCIAL_IDP_TYPE.WECOM)
        : SocialIdpApi.unbindSocialIdp(idpType);
    return promise.then(() => {
      this.global.rootStore.getUserInfo().catch(Utils.noop);
      this.getWeComBindInfo();
    });
    // 外部处理error
  }

  public bindSocialIdp(
    idpType: SOCIAL_IDP_TYPE,
    body: { state: string; code: string; redirectUri?: string },
  ) {
    // 企业微信的单独接口
    const promise =
      idpType === SOCIAL_IDP_TYPE.WECOM
        ? SsoIdpApi.bindUser(SOCIAL_IDP_TYPE.WECOM, _.pick(body, 'state', 'code'))
        : SocialIdpApi.bindSocialIdp(idpType, body);

    return promise.then(() => {
      this.global.handleSuccess(`${SOCIAL_IDP_TYPE_NAME[idpType]}绑定成功`);
      this.global.rootStore.getUserInfo().catch(Utils.noop);
      this.getWeComBindInfo();
    });
    // catch 交给外部处理
  }

  public logout() {
    return AuthnApi.logout(this.global.rootStore.getIDPRedirectUrl(window.location.search))
      .then(() => {
        this.global.rootStore.updateStore({ userInfo: null });
      })
      .catch(Utils.noop);
  }

  public checkExpired() {
    PwdMatchedPolicyApi.checkExpired()
      .then((expireData) => {
        // validResult为 true 表示需要提示
        if (_.get(expireData, 'validResult') === true) {
          this.updateStore({
            expire: {
              isExpire: true,
              expiredDate: _.get(expireData, 'expiredDate'),
            },
          });
        } else {
          this.updateStore({
            expire: {
              isExpire: false,
              expiredDate: '',
            },
          });
        }
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }

  // 获取密码策略
  public getPwdPolicy() {
    this.isPolicyLoading = true;
    UserAccountApi.getPasswordPolicy()
      .then((policy) => {
        this.updateStore({ policy: policy || {}, isPolicyLoading: false });
      })
      .catch((err) => {
        this.global.handleError(err);
        this.updateStore({ isPolicyLoading: false });
      });
  }

  // 更新个人版租户名称
  public updatePersonalName(bodyData: { name: string }) {
    return UsersApiV2.updatePersonalName(bodyData);
  }

  /* ========================== 以下为邮箱绑定相关接口  ==========================*/
  /**
   * 发送邮箱验证码
   * 1. 绑定、换绑（新、旧）、解绑、修改密码
   * */
  public sendEmailCode(email?: string, event?: EMAIL_VERIFY_CODE_SCENE) {
    return UserApi.sendEmailCode(email, event);
  }

  /**
   * 验证邮箱验证码
   * 1. 绑定、换绑（新、旧）、解绑、修改密码
   * */
  public verifyEmailCode(
    data: { verificationCode: string; verificationSessionId: string },
    event: EMAIL_VERIFY_CODE_SCENE,
  ) {
    return UserApi.verifyEmail({ ...data, event });
  }

  /**
   * 验证邮箱密码
   * 1. 换绑（旧）、解绑、修改密码
   * */
  public async verifyEmailPassword(password: string, event: EMAIL_VERIFY_CODE_SCENE) {
    const credential = await this.global.rootStore.encryptRequest({ password });
    return credential
      ? UserApi.verifyOldEmailPassword(credential, event)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }

  /**
   * 设置邮箱密码
   * 1. 绑定、换绑（新）、修改密码
   * */
  public async setEmailPassword(password: string, event: EMAIL_VERIFY_CODE_SCENE) {
    const credential = await this.global.rootStore.encryptRequest({ password });
    return credential
      ? UserApi.setEmailPassword(credential, event)
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }

  // 绑定
  public bindEmailAccount() {
    return UserApi.bindEmailAccount();
  }

  // 换绑
  public rebindEmailAccount() {
    return UserApi.rebindEmailAccount();
  }

  // 解绑
  public unbindEmailAccount() {
    return UserApi.unbindEmailAccount();
  }

  public getAppStore() {
    return AppStoreApi.getByParams({
      pageNumber: 1,
      pageSize: 30,
      signOnMode: APP_STORE_SIGN_ON_MODE.OIDC,
      vendorType: VENDOR_TYPE.Internal,
    })
      .then((res) => {
        const curAppStore = m.get(res, 'appStore.0') || {};
        this.updateStore({
          appStore: curAppStore,
        });
        if (m.get(curAppStore, 'settings.enable_privilege_grant')) {
          this.getUserLogs();
        }
      })
      .catch((error) => {
        this.global.handleError(error);
      });
  }

  // 获取用户日志
  public getUserLogs(params?: Record<string, any>) {
    this.isLogsLoading = true;
    const query = {
      ...this.logQuery,
      ...params,
    };
    PersonalEventsApi.getByParams(query)
      .then((res) => {
        this.updateStore({
          userLogs: res,
          logQuery: query,
        });
      })
      .catch((err) => {
        this.global.handleError(err);
      })
      .finally(() => {
        this.updateStore({
          isLogsLoading: false,
        });
      });
  }
}

export default ProfileStore;

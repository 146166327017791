// /**
//  * 登录后的 ERROR 页面展示
//  * */

import JSRuntime from 'common/utils/JSRuntime';
import Utils from 'common/utils';
import message from 'common/components/Message';

const CidpError = () => {
  const query = JSRuntime.parseLocationSearch() || {};
  React.useEffect(() => {
    // PC 端父窗口消失
    if (!window.opener) {
      message.error('绑定失败', 24 * 60 * 60 * 1000);
      console.error('未找到父级窗口');
      return Utils.noop; // 退出页面关闭
    }

    // 认证源登录会打开一个窗口，这里通过 postMessage 来根主窗口通信
    window.opener.postMessage(
      {
        type: 'POPUP_AUTH_FAIL',
        data: { ...query },
      },
      '*',
    );
    return Utils.noop;
  }, []);

  return null;
};

export default React.memo(CidpError);

import { IDENTITY_TYPE } from 'common/constants/Types';
export enum RESULT_TYPE {
  TEST_AUTH_FAIL = 'TEST_AUTH_FAIL',
  TEST_LOGIN_FAIL = 'TEST_LOGIN_FAIL',
  TEST_LOGIN_SUCCESS = 'TEST_LOGIN_SUCCESS',
  TEST_CREATE_FAIL = 'TEST_JIT_FAIL',
}

export const RESULT = {
  [RESULT_TYPE.TEST_AUTH_FAIL]: {
    title: '测试失败',
    desc: '',
  },
  [RESULT_TYPE.TEST_CREATE_FAIL]: {
    title: '登录失败',
    desc: '您的配置可正常工作但登录失败。根据您设置的匹配逻辑，{identityName}侧该人员无法匹配到系统侧任何人员，在根据未匹配逻辑在系统侧创建该人员时失败。',
  },
  [RESULT_TYPE.TEST_LOGIN_FAIL]: {
    title: '登录失败',
    desc: '您的配置可正常工作但登录失败。根据您设置的匹配逻辑，{identityName}侧该人员无法匹配到系统侧任何人员，根据未匹配逻辑被拒绝登录。',
  },
  [RESULT_TYPE.TEST_LOGIN_SUCCESS]: {
    title: '登录成功',
    desc: '您的配置可正常工作且登录成功。根据您设置的匹配逻辑{identityName}侧该人员匹配到了系统侧某人员，或根据未匹配逻辑被创建。',
  },
};
export const IDENTITY_MAP = {
  [IDENTITY_TYPE.DINGTALK]: {
    name: '钉钉',
    profileName: 'Dingtalk_Userprofile',
    courseLink: 'https://identity.tencent.com/docs/guides/IDPconfig/integration/dingtalk',
  },
  [IDENTITY_TYPE.LARK]: {
    name: '飞书',
    profileName: 'Lark_Userprofile',
    courseLink: 'https://identity.tencent.com/docs/guides/IDPconfig/integration/lark',
  },
};

import {
  DEFAULT_COUNTDOWN_SECOND,
  SAVE_ORG_MSG_CODE,
  MOBILE_SAFE_ERR_CODE,
  VERIFY_CODE_LIMIT_ERR,
} from 'common/constants/Constants';
import JSRuntime from 'common/utils/JSRuntime';
import { TUpdateStore, TGlobalStore } from 'idpBase/store';
import { UserApi, UserAccountsApi, AuthnApi } from 'idpBase/api';
import { ICompanyParams } from './constants/Types';
import { REGISTER_ERROR_MSG, REGISTER_USER_STEP, VERIFY_CODE_ERROR } from './constants/Constants';
import { IAccount } from '../login/constants/Types';

class RegisterStore {
  private readonly global: TGlobalStore;
  public updateStore: TUpdateStore<RegisterStore>;
  public resetStore: () => void;

  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;
  public isRegisterLoading = false;
  public isRegisterSuccess = false;
  public registerUserStep = REGISTER_USER_STEP.PHONE;
  public hideCreatePersonal = false;
  public mobile = '';
  public registerErrMsg = '';
  public isAccountsLoading = false;
  public accountOptions: {
    accounts: IAccount[];
    unavailable: IAccount[];
  } = { accounts: [], unavailable: [] };

  // 获取验证码
  public sendCode(mobile: string) {
    return UserApi.getRegisterCode(mobile)
      .then((res: { verificationSessionId: string }) => {
        this.updateStore({
          verificationSessionId: m.get(res, 'verificationSessionId'),
          countdown: m.get(res, 'expire') || 0,
          registerErrMsg: '',
        });
        return Promise.resolve(res);
      })
      .catch((error: Error) => {
        const errorCode = _.get(error, 'data.errCode');
        if (VERIFY_CODE_LIMIT_ERR.has(errorCode)) {
          this.updateStore({
            registerErrMsg: errorCode,
          });
        } else {
          this.global.handleError(error);
        }

        return Promise.reject(error);
      });
  }

  // 注册
  public register(values: { verificationCode: string }) {
    this.isRegisterLoading = true;
    const app = JSRuntime.backEndApp;
    return UserApi.register({ ...values, verificationSessionId: this.verificationSessionId, app })
      .then((data) => {
        this.global.rootStore.dataReport('e#new_register_result_web', {
          register_result: 'success',
        });
        this.updateStore({
          isRegisterSuccess: true,
          hideCreatePersonal: !!_.get(data, 'next.hideCreatePersonal'),
          isRegisterLoading: false,
          accountOptions: _.get(data, 'next.accountOptions') || {},
        });
      })
      .catch((error: Error) => {
        const errCode = _.get(error, 'data.errCode');
        this.global.rootStore.dataReport('e#new_register_result_web', {
          register_result: 'success',
          register_error_code: errCode,
        });
        if (_.includes(_.keys(VERIFY_CODE_ERROR), errCode)) {
          const errorMsg = _.get(VERIFY_CODE_ERROR, errCode);
          this.updateStore({ registerErrMsg: errorMsg });
        } else {
          const errCode = _.get(error, 'data.errCode');
          const errorMsg = _.get(REGISTER_ERROR_MSG, errCode);
          this.global.handleError(errorMsg || error);
          if (errCode === MOBILE_SAFE_ERR_CODE) {
            this.updateStore({ registerErrMsg: '验证失败，请稍后重试' }); // 风控报错展示
          }
        }
        this.updateStore({ isRegisterLoading: false });
      });
  }

  // 创建企业
  public createCompany(params: ICompanyParams) {
    return UserAccountsApi.create(params).catch((error: Error) => {
      const errCode = m.get(error, 'data.errCode');
      this.global.handleError(
        errCode === SAVE_ORG_MSG_CODE.NOT_COMPLIANCE ? '名称存在风险，请修改后重试' : error,
      );

      return Promise.reject(error);
    });
  }

  // 获取关联租户列表
  public getAccounts(app?: string) {
    this.isAccountsLoading = true;
    return AuthnApi.getAccountOptions(app)
      .then((res) => {
        this.updateStore({
          accountOptions: m.get(res, 'next.accountOptions') || {},
        });
        return res;
      })
      .catch((err) => {
        this.global.handleError(err);
        return Promise.reject();
      })
      .finally(() => {
        this.updateStore({
          isAccountsLoading: false,
        });
      });
  }
  // 解除所有企业绑定
  public unbindAllCompany() {
    return UserAccountsApi.unbindAccounts()
      .then((res) => {
        this.global.handleSuccess('解绑成功');
        return res;
      })
      .catch((err) => {
        this.global.handleError(err);
        return Promise.reject(err);
      });
  }
}

export default RegisterStore;

import { useStore, convert } from 'idpBase/store';
import { useSearchParams } from 'react-router-dom';
import Utils from 'common/utils';
import BlankPage from 'common/components/BlankPage';
import Invitation from './view/Invitation';
import { IInvitationInfo } from './constants/Type';

const InvitationEntry: React.FC = () => {
  const { invitationStore } = useStore();
  const [searchParams] = useSearchParams();
  const handshakeId = searchParams.get('handshakeId') || '';
  const accountId = searchParams.get('accountId') || '';
  const { isCheckLoading } = invitationStore;

  React.useEffect(() => {
    const inviteFrom = searchParams.get('inviteType') || ''; // 区别从短信还是邮件链接进入的
    invitationStore.updateStore({ inviteFrom, handshakeId, accountId });
    invitationStore
      .checkInvitationInfo()
      .then((res: IInvitationInfo) => {
        invitationStore.updateStore({
          invitationInfo: res,
          onlyApp: res.onlyApp,
          isCheckLoading: false,
        });
      })
      .catch(Utils.noop);
  }, []);

  React.useEffect(() => () => invitationStore.resetStore(), []);

  if (isCheckLoading) {
    return <BlankPage type="LOADING" fullHeight />;
  }

  return <Invitation />;
};

export default convert(InvitationEntry);

import Utils from 'common/utils';
import { KeyboardEvent, MouseEvent } from 'react';
import { dialog, DialogOptions, DialogInstance, DialogCloseContext } from 'tdesign';
import { Observer } from 'mobx-react';
import Button, { ButtonProps } from '../Button';

type DialogButton = Partial<ButtonProps> & { content: React.ReactNode };

type TReWriteOptions = 'confirmBtn' | 'cancelBtn' | 'onClose' | 'onConfirm';

export interface Options extends Omit<DialogOptions, TReWriteOptions> {
  /**
   * 取消按钮配置
   * */
  cancelBtn?: string | DialogButton;
  /**
   * 确定按钮配置
   * */
  confirmBtn?: string | DialogButton;
  /**
   * 关闭点击取消或者点击确定后的自动隐藏弹窗行为
   * 默认触发 onClose 和 onConfirm 后自动隐藏弹窗
   * 如果 onClose 与 onConfirm 有 Promise 类型的返回值，则在 返回 resolve 后自动关闭
   * */
  turnOffAutoHide?: boolean;
  /**
   * 关闭点击确定后的默认 loading 行为
   * 默认 Dialog.confirm 触发 onConfirm 后，如果有 Promise 类型的返回值
   * 则会在 promise 之前触发 loading，在 promise.finally 之后取消 loading
   * */
  turnOffAutoLoading?: boolean;
  /**
   * 触发关闭后的回调，默认触发后自动隐藏弹窗
   * 如果返回值是Promise，则在 resolve 后自动隐藏弹窗
   * */
  onClose?: (context: DialogCloseContext) => Promise<any> | void;
  /**
   * 触发提交后的回调，默认触发后自动隐藏弹窗，
   * 如果返回值是Promise，则在 resolve 后自动隐藏弹窗
   * */
  onConfirm?: (context: {
    e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLDivElement>;
  }) => Promise<any> | void;
}

// NOTICE： 目前只开放 confirmBtn 的 loading 配置 使用 confirmBtn 是为了后续扩展的统一性
type TUpdateOptions = Omit<DialogOptions, 'confirmBtn' | 'cancelBtn'> & {
  confirmBtn?: { loading?: boolean };
  cancelBtn?: { loading?: boolean };
};
type TUpdate = (props: TUpdateOptions) => void;

const commonConfig = {
  destroyOnClose: true,
  className: 'eid-dialog-plugin',
};

const genProxyFn = (options: {
  turnOffAutoHide?: boolean;
  turnOffAutoLoading?: boolean;
  instance?: DialogInstance;
  onClose?: Options['onClose'];
  onConfirm?: Options['onConfirm'];
  enableConfirmFnEmpty?: boolean;
}) => {
  /**
   * 为了保证 instance 在初始化之后能拿到准确的引用地址，这里不要提前解构出 instance,
   * 而是在用的地方在去获取 options.instance
   * */
  const { turnOffAutoHide, onClose, onConfirm, enableConfirmFnEmpty, turnOffAutoLoading } = options;
  const onProxyClose: DialogOptions['onClose'] = (context) => {
    if (!_.isFunction(onClose)) {
      if (turnOffAutoHide) return;
      options.instance?.hide(); // 关闭按钮即使外部没有定义 onClose 默认也要给他关闭
      return;
    }
    const result = onClose(context) as any;
    if (turnOffAutoHide) return;
    if (result instanceof Promise && _.isFunction(result.then)) {
      result.then(() => options.instance?.hide()).catch(Utils.noop);
    } else {
      options.instance?.hide();
    }
  };

  const onProxyConfirm: DialogOptions['onConfirm'] = (context) => {
    if (!_.isFunction(onConfirm)) {
      if (turnOffAutoHide || !enableConfirmFnEmpty) return;
      options.instance?.hide(); // 关闭按钮即使外部没有定义 onClose 默认也要给他关闭
      return;
    }
    const result = onConfirm(context) as any;
    if (turnOffAutoHide) return;
    if (result instanceof Promise && _.isFunction(result.then)) {
      if (!turnOffAutoLoading) options.instance?.update({ confirmBtn: { loading: true } });
      result
        .finally(() => {
          if (!turnOffAutoLoading) options.instance?.update({ confirmBtn: { loading: false } });
        })
        .then(() => options.instance?.hide())
        .catch(Utils.noop);
    } else {
      options.instance?.hide();
    }
  };

  return { onProxyClose, onProxyConfirm };
};

const confirm = (options?: Options) => {
  const {
    cancelBtn,
    confirmBtn,
    onClose,
    onConfirm,
    turnOffAutoHide,
    turnOffAutoLoading,
    ...extraOpts
  } = options || {};
  let instance = undefined as unknown as DialogInstance | undefined;
  const curryOptions = { turnOffAutoHide, turnOffAutoLoading, onClose, onConfirm, instance };
  const { onProxyClose, onProxyConfirm } = genProxyFn(curryOptions);

  // NOTICE: 借用 mobx 的可观察数据实现 弹窗组件 loading 的变换
  const confirmBtnStore = mobx.observable({
    confirmLoading: _.get(confirmBtn, 'loading') || false,
    cancelLoading: _.get(cancelBtn, 'loading') || false,
    confirmDisabled: _.get(confirmBtn, 'disabled') || false,

    setLoading(loading: boolean) {
      this.confirmLoading = loading;
    },
    setCancelLoading(loading: boolean) {
      this.cancelLoading = loading;
    },
    setDisabled(disabled: boolean) {
      this.confirmDisabled = disabled;
    },
  });

  const config = {
    ...commonConfig,
    ...extraOpts,
    onClose: onProxyClose,
    onConfirm: onProxyConfirm,
    cancelBtn: (
      <Observer>
        {() => (
          <Button
            color="secondary"
            {...(_.isObject(cancelBtn) ? cancelBtn : {})}
            autoFocus
            loading={confirmBtnStore.cancelLoading}
            onClick={(e) => {
              onProxyClose({ trigger: 'cancel', e });
            }}
          >
            {_.get(cancelBtn, 'content') || cancelBtn || '取消'}
          </Button>
        )}
      </Observer>
    ),
    confirmBtn: (
      <Observer>
        {() => (
          <Button
            color="danger"
            {...(_.isObject(confirmBtn) ? confirmBtn : {})}
            loading={confirmBtnStore.confirmLoading}
            disabled={confirmBtnStore.confirmDisabled}
            onClick={(e) => {
              onProxyConfirm({ e });
            }}
          >
            {_.get(confirmBtn, 'content') || confirmBtn}
          </Button>
        )}
      </Observer>
    ),
  };

  instance = dialog.confirm(config) as Omit<DialogInstance, 'update'> & {
    update: TUpdate;
  };
  curryOptions.instance = instance; // NOTICE: 更新 封装函数中 instance 的引用

  const oldUpdate = instance.update;
  // NOTICE： 目前只开放 confirmBtn 的 loading 配置 使用 confirmBtn 是为了后续扩展的统一性
  instance.update = (opts: TUpdateOptions) => {
    const { confirmBtn, cancelBtn, ...extraOpts } = opts;
    const confirmLoading = _.get(confirmBtn, 'loading');
    const confirmDisabled = _.get(confirmBtn, 'disabled');
    if (_.isBoolean(confirmLoading)) confirmBtnStore.setLoading(confirmLoading);

    const cancelLoading = _.get(cancelBtn, 'loading');
    if (_.isBoolean(cancelLoading)) confirmBtnStore.setCancelLoading(cancelLoading);
    if (_.isBoolean(confirmDisabled)) confirmBtnStore.setDisabled(confirmDisabled);
    if (!_.isEmpty(extraOpts)) oldUpdate.call(instance, opts);
  };

  return instance;
};

const alert = (options?: Options) => {
  const { confirmBtn, onClose, onConfirm, turnOffAutoHide, turnOffAutoLoading, ...extraOpts } =
    options || {};
  let instance = undefined as unknown as DialogInstance | undefined;
  const curryOptions = {
    turnOffAutoHide,
    turnOffAutoLoading: true, // Dialog.alert 默认关闭自动 loading
    onClose,
    onConfirm,
    instance,
    enableConfirmFnEmpty: true,
  };
  const { onProxyClose, onProxyConfirm } = genProxyFn(curryOptions);

  const config = {
    ...commonConfig,
    ...extraOpts,
    onClose: onProxyClose,
    onConfirm: onProxyConfirm,
    confirmBtn: (
      <Button
        {...(_.isObject(confirmBtn) ? confirmBtn : {})}
        onClick={(e) => {
          onProxyConfirm({ e });
        }}
      >
        {_.get(confirmBtn, 'content') || confirmBtn || '我知道了'}
      </Button>
    ),
  };

  instance = dialog.alert(config);
  curryOptions.instance = instance;

  return instance;
};

export default { alert, confirm };

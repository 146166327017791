import { AUTH_STEP } from '../../constants/Constants';

export enum FACE_AUTH_TYPE {
  FACE_AUTH = 0,
  FACE_VERIFICATION = 1,
}

export enum SMS_CODE_EVENT {
  BIND = 'account_personal_certify_phone_bind',
  CERTIFY = 'account_personal_certify_submit',
}

export const AUTH_STEP_OPTIONS = [
  {
    title: '选择认证类型',
    value: AUTH_STEP.CHOOSE,
  },
  {
    title: '上传认证资料',
    value: AUTH_STEP.UPLOAD,
  },
  {
    title: '验证身份',
    value: AUTH_STEP.CERTIFY,
  },
];

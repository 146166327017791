import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator';

class UsersApi extends BaseApi {
  public requestInstance = new Request(APINames.USERS);

  uploadAvatar(formData: FormData) {
    return this.sendFormData({
      url: ['avatar', ':upload'],
      data: formData,
    });
  }

  saveAvatar(avatarUrl: string) {
    return this.requestInstance.post(['avatar'], { avatarUrl });
  }

  // 人员自己注销所在公司的账号
  signOff() {
    return this.requestInstance.post(':signOff');
  }
}

export default new UsersApi();

import { AuthnApi } from 'common/api';
import { addQueryToRequest } from './hooks/utils';
import { handleError } from 'common/utils/ErrorHandler';

type TUpdateFn<SI> = (storeInstance: SI) => void;

class WeMeetQrCodeStore {
  constructor() {
    mobx.makeAutoObservable(this);
  }

  public updateStore(param: Partial<WeMeetQrCodeStore> | TUpdateFn<WeMeetQrCodeStore>): void {
    if (typeof param === 'function') {
      param(this);
    } else {
      Object.keys(param).forEach((key: string) => {
        this[key] = param[key];
      });
    }
  }

  public getQRCodeSessionKey(data: { uuid: string; instance_id: number }) {
    return AuthnApi.getQRCodeSessionKey(data, addQueryToRequest())
      .then((res) => _.get(res, 'data.session_key'))
      .catch((err) => {
        handleError(err);
      });
  }

  // NOTICE： EID 代理的接口与 会议原接口 字段格式上存在差异，这里手动转换一下，以方便对接
  public queryQRCodeStatus(data: { session_key: string }) {
    return AuthnApi.queryQRCodeStatus(data, addQueryToRequest())
      .then((res) => _.get(res, 'data'))
      .catch((err) => {
        handleError(err);
      });
  }
}

export type TWeMeetQrCodeStore = WeMeetQrCodeStore;

export default new WeMeetQrCodeStore();

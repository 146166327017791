import JSRuntime from 'common/utils/JSRuntime';
import ExternalLink from '../ExternalLink';

export function getBlockedErrMsg() {
  return (
    <>
      该账号存在风险已被封禁，
      <ExternalLink href={JSRuntime.agreementUrl.suspended}>点击此处了解详情</ExternalLink>
    </>
  );
}

import { useParams } from 'react-router-dom';
import BlankPage from 'common/components/BlankPage';
import utils from 'common/utils';
import { useStore } from 'idpBase/store';
import PageHead from 'idpBase/compositions/PageHead';
import ResetPasswordCard from './view/ResetPasswordCard';
import PWDScss from './style/ResetPassword.module.scss';

const ResetPassword: React.FunctionComponent = () => {
  const { resetPasswordStore } = useStore();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const { token = '' } = useParams();
  React.useEffect(() => {
    resetPasswordStore
      .init({
        reset_token: token,
      })
      .catch(utils.noop)
      .finally(() => {
        setIsInitialized(true);
      });
  }, []);
  return (
    <div className={PWDScss.container}>
      <PageHead />
      {isInitialized ? <ResetPasswordCard /> : <BlankPage type="LOADING" />}
    </div>
  );
};

export default ResetPassword;

/**
 * 可以通过 Tab 获取焦点的节点，获取焦点后点击 回车键可以直接触发 click
 * */

import Utils from 'common/utils';
import { KeyboardEventHandler, MouseEventHandler } from 'react';
import TabFocusDomScss from './TabFocusDom.module.scss';

interface ITabFocusWrapperProps {
  children: React.ReactNode;
  stopPreventDefault?: boolean;
}

// NOTICE: 样式要使用 :focus-visible 而不是 focus, 因为 focus 会在鼠标操作时触发，focus-visible 只会在键盘操作下触发
const TabFocusWrapper = React.memo((props: ITabFocusWrapperProps) => {
  const { children, stopPreventDefault } = props;

  // 监听 回车事件
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event?.code === 'Enter') {
      (event?.target as HTMLDivElement)?.click();
    }
  };

  const onMouseDown: MouseEventHandler<HTMLElement> = (event) => {
    if (!stopPreventDefault) event.preventDefault();
    const originMouseDown = _.get(children, 'props.onMouseDown');
    if (_.isFunction(originMouseDown)) originMouseDown(event);
  };

  if (_.isString(children) || _.isNumber(children)) {
    return (
      <div
        tabIndex={0}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        className={Utils.uniteClass(TabFocusDomScss.tabFocusDom, TabFocusDomScss.inline)}
      >
        {children}
      </div>
    );
  }
  if (!children) return null;

  // NOTICE， tabIndex 使得节点可以在 Tab 切换时被选中
  return React.cloneElement(children as React.ReactElement<any>, {
    tabIndex: 0,
    onKeyDown,
    onMouseDown,
  });
});

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  inline?: boolean; // 行内
  fullwidth?: boolean;
  stopPreventDefault?: boolean;
}

const TabFocusDom = (props: IProps) => {
  const { className, inline, fullwidth, stopPreventDefault, ...extra } = props;

  const content = (
    <div
      {...extra}
      className={Utils.uniteClass(
        TabFocusDomScss.tabFocusDom,
        fullwidth ? TabFocusDomScss.fullwidth : '',
        inline ? TabFocusDomScss.inline : '',
        className,
      )}
    />
  );

  return <TabFocusWrapper stopPreventDefault={stopPreventDefault}>{content}</TabFocusWrapper>;
};

export { TabFocusWrapper };

export default React.memo(TabFocusDom);

export enum STEP {
  VERIFY_MOBILE,
  VERIFY_CODE,
  RESET_PWD,
  SET_PWD,
}

export enum PWD_SET_TYPE {
  SET = 'RESET_PASSWORD',
  RESET = 'CHANGE_PASSWORD',
}

export const STEP_INFO = {
  [STEP.VERIFY_MOBILE]: {
    title: '重设密码',
    description: '',
  },
  [STEP.VERIFY_CODE]: {
    title: '',
    description: '',
  },
  [STEP.RESET_PWD]: {
    title: '',
    description: '',
  },
  [STEP.SET_PWD]: {
    title: '',
    description: '检测账号还未设置过密码，请设置',
  },
};

export const FORGET_PWD_ERROR_MSG = {
  E0010028: '手机号或验证码不正确，请重新输入', // 手机号+验证码: 验证码有误
};

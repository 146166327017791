/**
 * mobx 初始化，兼容非 Proxy 浏览器
 * */
import { configure } from 'mobx';

configure({
  /**
   * 1. 在支持 Proxy 的浏览器中开启 Proxy 的实现，否则降级到 non-proxy 实现
   * */
  useProxies: 'ifavailable',
  /**
   * 2. 设置 observed 来限制可观察对象必须通过 action 来修改
   * */
  enforceActions: 'observed',
  /**
   * 3. "观察者" 中没有用到"可观察对象" 时发出警告
   * 例如：组件通过 observer 包装了，但是并没有使用到 "可观察对象"
   * */
  reactionRequiresObservable: true,
  /**
   * 4. 在使用了 "可观察对象"，但是并没有观察者的情况下发出警告，
   * 例如：忘了给传入了"可观察对象"的组件，使用 observer 包装
   * 禁用：因为我们有的时候只是在事件中从 store 取值，不一定需要在取值有更新时，重新触发事件
   * */
  // observableRequiresReaction: true,
  /**
   * 5. 禁止在action或者reaction之外，直接获取未被观察的计算属性的值
   * 也就是可观察对象的 getter 的值获取需要包装到 reaction 或者 runInAction 里边
   * */
  // computedRequiresReaction: true,
});

import JSRuntime from 'common/utils/JSRuntime';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import {
  CONTENT,
  INVITE_FROM,
  VERIFY_CODE_ERROR,
  RESULT_TYPES,
  ACCEPTATION_ERROR_CODE,
  EXPIRE_CODE,
  EMAIL_SET_PWD_ERROR_CODE,
} from './constants/Constants';
import { VERIFY_CODE_SCENE } from 'common/constants/Constants';
import { AuthnApi } from 'idpBase/api';
import { IInvitationInfo } from './constants/Type';

class InvitationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<InvitationStore>;
  public resetStore: () => void;

  public currentContent = CONTENT.INVITING;
  public isCheckLoading = true;
  public handshakeId = '';
  public accountId = '';

  public invitationInfo = {} as IInvitationInfo;
  public dialogVisible = false;
  public verificationSessionId = '';
  public countdown = 0;
  public isJoinLoading = false; // 真正加入的loading
  public codeErrMsg = '';
  public isAcceptBtnLoading = false;
  public resultType = '';
  public inviteFrom = '';
  public expiredErrorText = '';
  public onlyApp = '';

  // 获取邀请信息：过期，人员信息等，也当作过期校验
  public checkInvitationInfo() {
    return AuthnApi.getInvitationInfo(this.accountId, this.handshakeId)
      .then((res) => Promise.resolve(res))
      .catch((err) => {
        const errCode = m.get(err, 'data.errCode');
        if (EXPIRE_CODE.has(errCode)) {
          const errInfo = EXPIRE_CODE.get(errCode);
          // 邀请链接过期
          this.updateStore({
            isCheckLoading: false,
            currentContent: CONTENT.RESULT_PAGE,
            resultType: RESULT_TYPES.EXPIRED,
            expiredErrorText: errInfo,
          });
        } else {
          this.global.handleError(err);
        }
        return Promise.reject(err);
      });
  }

  // 接受邀请
  public acceptInvitation() {
    const isEmail = this.inviteFrom === INVITE_FROM.EMAIL;
    const handshakeId = m.get(this.invitationInfo, 'handshakeId');
    const mobile = m.get(this.invitationInfo, 'mobile');
    const email = m.get(this.invitationInfo, 'email');

    const promise = isEmail
      ? AuthnApi.acceptEmailInvitation(handshakeId, email)
      : AuthnApi.acceptInvitation([{ mobile, handshakeId }], JSRuntime.backEndApp);

    return promise
      .then(() => {
        this.updateStore({
          currentContent: CONTENT.RESULT_PAGE,
          resultType: RESULT_TYPES.JOIN_SUCCESS,
        });
      })
      .catch((err: Error) => {
        const errCode = m.get(err, 'data.errCode');

        if (errCode === EMAIL_SET_PWD_ERROR_CODE) {
          if (JSRuntime.isMobileDevice) {
            this.updateStore({ currentContent: CONTENT.MOBILE_VERIFY });
          } else {
            this.updateStore({ dialogVisible: true });
          }
        } else if (EXPIRE_CODE.has(errCode)) {
          const errInfo = EXPIRE_CODE.get(errCode);
          // 邀请链接过期
          this.updateStore({
            currentContent: CONTENT.RESULT_PAGE,
            resultType: RESULT_TYPES.EXPIRED,
            expiredErrorText: errInfo,
          });
        } else if (ACCEPTATION_ERROR_CODE.has(errCode)) {
          const errInfo = ACCEPTATION_ERROR_CODE.get(errCode);
          this.updateStore({
            currentContent: CONTENT.RESULT_PAGE,
            resultType: errInfo?.resultType,
          });
        } else {
          this.global.handleError(err);
        }
      });
  }

  public getVerifyCode(mobile: string) {
    return AuthnApi.sendVerificationCode({ mobile, event: VERIFY_CODE_SCENE.INVITATION });
  }

  public verifyCode(code: string) {
    return AuthnApi.verifyCode({
      verificationSessionId: this.verificationSessionId,
      verificationCode: code,
      event: VERIFY_CODE_SCENE.INVITATION,
    })
      .then(() => {
        if (this.codeErrMsg) {
          this.updateStore({ codeErrMsg: '' });
        }
        return Promise.resolve();
      })
      .catch((err: Error) => {
        const errCode = m.get(err, 'data.errCode');
        // NOTICE 不使用后端返回的error message
        const errorMessage = VERIFY_CODE_ERROR[errCode];
        if (errorMessage) {
          this.updateStore({ codeErrMsg: errorMessage });
        } else {
          this.global.handleError(err);
        }
        return Promise.reject(errCode);
      });
  }

  public setEmailPassword(password: string) {
    // TODO: 临时，待后端问题查出后删除
    const email = m.get(this.invitationInfo, 'email');

    return AuthnApi.setEmailPassword({
      password,
      email,
      event: VERIFY_CODE_SCENE.EMAIL_HANDSHAKE,
    }).catch((error) => {
      const errCode = m.get(error, 'data.errCode');
      if (EXPIRE_CODE.has(errCode)) {
        const errInfo = EXPIRE_CODE.get(errCode);
        // 邀请链接过期
        this.updateStore({
          currentContent: CONTENT.RESULT_PAGE,
          resultType: RESULT_TYPES.EXPIRED,
          expiredErrorText: errInfo,
        });
      }
      return Promise.reject(error);
    });
  }
}

export default InvitationStore;

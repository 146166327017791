import {
  DEFAULT_COUNTDOWN_SECOND,
  VERIFY_CODE_LIMIT_ERR,
  CREDENTIAL_ERROR,
} from 'common/constants/Constants';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';
import { RESET_PWD_ERROR_CODE } from 'idpBase/Constants';
import { RESET_PWD_STEP, SSO_LOGIN_ERR_MSG } from './constants/Constants';
import { SET_PASSWORD_TIPS } from '../constants/Constants';

interface IVerifyUserParams {
  username: string;
  accountId: string;
}

class ResetPasswordStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ResetPasswordStore>;
  public resetStore: () => void;

  public step = RESET_PWD_STEP.USERNAME;

  public username = '';

  public isSendLoading = false;
  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;
  public loginErrMsg = '';

  public isVerifyLoading = false;

  public isResetLoading = false;

  public policy = {};
  public isPolicyLoading = false;
  public pwdErrorMessage = '';

  // 发送短信验证码
  public sendSms(params: IVerifyUserParams) {
    this.isSendLoading = true;
    this.username = m.get(params, 'username');

    this.isSendLoading = true;
    return AuthnApi.sendEnterpriseSms({ event: 'forget_enterprise_password', ...params })
      .then((res) => {
        this.updateStore({
          verificationSessionId: m.get(res, 'verificationSessionId'),
          countdown: m.get(res, 'expire') || DEFAULT_COUNTDOWN_SECOND, // 如果不发送短信，此时后端返回的expire为0，但是前端依然开始倒计时
          step: RESET_PWD_STEP.VERIFY_CODE,
          loginErrMsg: '',
        });
        return Promise.resolve(res);
      })
      .catch((error: Error) => {
        const errorCode = _.get(error, 'data.errCode');
        if (VERIFY_CODE_LIMIT_ERR.has(errorCode)) {
          this.updateStore({
            step: RESET_PWD_STEP.VERIFY_CODE,
            loginErrMsg: errorCode,
          });
        } else {
          this.global.handleError(SSO_LOGIN_ERR_MSG[errorCode] || error);
        }
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({
          isSendLoading: false,
        });
      });
  }

  // 验证短信验证码
  public verifyCode(code: string) {
    this.isVerifyLoading = true;
    return AuthnApi.verifyEnterpriseSms({
      verificationSessionId: this.verificationSessionId,
      verificationCode: code,
      event: 'forget_enterprise_password',
    })
      .then(() => {
        this.updateStore({
          step: RESET_PWD_STEP.PASSWORD,
        });
      })
      .catch((error: Error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isVerifyLoading: false,
        });
      });
  }

  // 更新密码
  public async resetPwd(pwd: string) {
    this.isResetLoading = true;
    const credential = await this.global.rootStore.encryptRequest({ password: pwd });

    return (
      credential
        ? AuthnApi.resetEnterprisePwd(credential)
        : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } })
    )
      .catch((error: Error) => {
        const errCode = _.get(error, 'data.errCode');
        const errMsg = _.get(error, 'data.errMessage');
        if (errCode === 'E0010170' || errCode === 'E0010171') {
          this.updateStore({
            pwdErrorMessage: errMsg,
          });
        } else if (errCode === 'E0010033') {
          this.global.handleError(SET_PASSWORD_TIPS.PASSWORD_V0.POLICY_UPGRADE);
          // 重新获取密码密码策略
          setTimeout(() => {
            this.getPwdPolicy();
          }, 0);
        } else {
          this.global.handleError(_.includes(RESET_PWD_ERROR_CODE, errCode) ? errMsg : error);
        }
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({
          isResetLoading: false,
        });
      });
  }

  // 获取密码策略
  public getPwdPolicy() {
    this.isPolicyLoading = true;
    return AuthnApi.getPasswordPolicy()
      .then((res) => {
        this.updateStore({
          policy: res,
        });
      })
      .catch((error: Error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isPolicyLoading: false,
        });
      });
  }
}

export default ResetPasswordStore;

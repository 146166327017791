import qs from 'qs';
import { TRequestUrlParams } from './CommonTypes';

export const jointPath = (paths: Array<string> | string = ''): string => {
  if (!_.isArray(paths)) {
    return paths || '';
  }
  return paths
    .join('/')
    .replace(/\/{2,}/g, '/')
    .replace(/\/\?/g, '?')
    .replace(/\/:/g, ':')
    .replace(/^(https?:\/)(\w)/, (match, p1, p2) => `${p1}/${p2}`);
};

// encode 搜索关键字
const parseKeyword = (params: TRequestUrlParams): TRequestUrlParams => {
  const parseArr = ['keywords', 'keyword'];
  parseArr.forEach((key) => {
    const value = _.get(params, key);
    if (_.isString(value) && value) {
      _.set(params, key, encodeURIComponent(value));
    }
  });
  return params;
};

// 序列化 url params
export const paramsSerializer = (params: TRequestUrlParams): string => {
  const parsedParams = parseKeyword(params);
  return qs.stringify(parsedParams, {
    encode: false,
    indices: false,
  });
};

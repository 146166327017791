/**
 * 官网地址，目前都给线上地址即可
 */
const officeSite = 'https://identity.tencent.com';

/**
 * 私有化环境变量
 */
export const getPrivateEnv = () => {
  const { protocol, host, pathname } = window.location;
  const curPathname = pathname.replace(/^(\/eid\/.*)/, '');
  const curPath = _.trimEnd(`${protocol}//${host}/${curPathname}`, '/');

  window.ONEID_PUBLIC_CDN_URL = `${curPath}/eid/public/`;
  window.ONEID_PUBLIC_GATEWAY_URL = `${curPath}/eid/gw/`;
  window.ONEID_PUBLIC_IDP_SITE = `${curPath}/eid/idp/`;
  window.ONEID_PUBLIC_ADMIN_SITE = `${curPath}/eid/`;
  window.ONEID_PUBLIC_PORTAL_SITE = `${curPath}/eid/`;
  window.ONEID_PUBLIC_DOCS_SITE = `${curPath}/eid/docs/`;
  window.ONEID_DINGTALK_IDENTITY_SITE = `${curPath}/eid/idp/`;
  window.ONEID_ACCOUNT_GATEWAY_URL = `${curPath}/eid/gw/`;
  window.ONEID_PUBLIC_OFFICIAL_SITE = officeSite;
};

/**
 * ci环境变量
 */
const getTestEnv = () => {
  const { hostname } = window.location;
  const env = hostname?.match(/ci-\d{1,}/)?.[0] || '';
  const metEnv = env.replace('ci-', '');
  const getPublicGetWay = () => {
    const paths = location.hostname.split('.');
    const codes = ['account', 'workspace'];
    return codes.find((code) => paths.includes(code));
  };

  window.ONEID_PUBLIC_CDN_URL =
    'https://toa-web-test-1258344699.cos-website.ap-guangzhou.myqcloud.com/public';
  window.ONEID_PUBLIC_IDP_SITE = `https://${env}.account.tencentcs.com`;
  window.ONEID_PUBLIC_ADMIN_SITE = `https://${env}.workspace.tencentcs.com`;
  window.ONEID_PUBLIC_PORTAL_SITE = `https://${env}.workspace.tencentcs.com`;
  window.ONEID_DINGTALK_IDENTITY_SITE = `https://${env}.account.tencentcs.com`;
  window.ONEID_PUBLIC_GATEWAY_URL = `https://api.${env}.${getPublicGetWay()}.tencentcs.com`;
  window.ONEID_ACCOUNT_GATEWAY_URL = `https://api.${env}.account.tencentcs.com`;
  window.ONEID_MEETING_SITE = `https://test-${metEnv}.cicd.tencentmeeting.com`;
  window.ONEID_PUBLIC_DOCS_SITE = `https://pre-account.tencent.com/docs`;
  window.ONEID_PUBLIC_OFFICIAL_SITE = officeSite;
};

/**
 * 预发环境变量
 */
const getPreEnv = () => {
  window.ONEID_PUBLIC_CDN_URL = 'https://identity.tencent.com/public';
  window.ONEID_PUBLIC_IDP_SITE = 'https://pre-account.tencent.com';
  window.ONEID_PUBLIC_ADMIN_SITE = 'https://pre-workspace.tencent.com';
  window.ONEID_PUBLIC_PORTAL_SITE = 'https://pre-workspace.tencent.com';
  window.ONEID_PUBLIC_GATEWAY_URL =
    RUNTIME_APP_NAME === 'idp'
      ? 'https://api.pre-account.tencent.com'
      : 'https://api.pre-workspace.tencent.com';
  window.ONEID_ACCOUNT_GATEWAY_URL = 'https://api.pre-account.tencent.com';
  window.ONEID_DINGTALK_IDENTITY_SITE = 'https://login.identity.tencent.com';
  window.ONEID_PUBLIC_OFFICIAL_SITE = officeSite;
  window.ONEID_MEETING_SITE = 'https://meeting.tencent.com';
};

/**
 * 线上环境变量
 */
const getProdEnv = () => {
  window.ONEID_PUBLIC_CDN_URL = 'https://identity.tencent.com/public';
  window.ONEID_PUBLIC_IDP_SITE = 'https://account.tencent.com';
  window.ONEID_PUBLIC_ADMIN_SITE = 'https://workspace.tencent.com';
  window.ONEID_PUBLIC_PORTAL_SITE = 'https://workspace.tencent.com';
  window.ONEID_PUBLIC_GATEWAY_URL =
    RUNTIME_APP_NAME === 'idp'
      ? 'https://api.account.tencent.com'
      : 'https://api.workspace.tencent.com';
  window.ONEID_ACCOUNT_GATEWAY_URL = 'https://api.account.tencent.com';
  window.ONEID_DINGTALK_IDENTITY_SITE = 'https://login.identity.tencent.com';
  window.ONEID_PUBLIC_OFFICIAL_SITE = officeSite;
  window.ONEID_MEETING_SITE = 'https://meeting.tencent.com';
};

export const getEnv = () => {
  if (RUNTIME_LOCAL_ENV === 'private') {
    getPrivateEnv();
  } else if (RUNTIME_LOCAL_ENV === 'dev') {
    getTestEnv();
  } else if (RUNTIME_LOCAL_ENV === 'pre') {
    getPreEnv();
  } else {
    getProdEnv();
  }
};

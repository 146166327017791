import JSRuntime from 'common/utils/JSRuntime';
import qs from 'qs';
import { SOCIAL_IDP_TYPE, SOCIAL_IDP_TYPE_NAME, SOCIAL_IDP_TYPE_URL } from './Constants';

export { SOCIAL_IDP_TYPE, SOCIAL_IDP_TYPE_NAME };

class SocialIdpWindow {
  private WINDOW_NAME = 'EID_SOCIAL_LOGIN_WINDOW';
  public MESSAGE = {
    POPUP_AUTH_SUCCESS: 'POPUP_AUTH_SUCCESS',
  };
  private windowName = '登录';
  private listener: (event: Event) => void;

  get windowFeatures() {
    const left = Math.max((screen.width - 800) / 2, 50);
    return `popup,left=${left},top=200,width=800,height=600`;
  }

  get window() {
    const socialIdpWindow = window[this.WINDOW_NAME] as Window;
    return socialIdpWindow && !socialIdpWindow.closed ? socialIdpWindow : null;
  }

  // 打开认证窗口前准备的空页面
  public prepare = () => {
    // window.open 在异步代码中触发，有些浏览器会被拦截，因此先在同步代码中打开一个空页面
    window[this.WINDOW_NAME] = window.open('about:blank', this.windowName, this.windowFeatures);
  };

  // 开始显示窗口认证 - QQ
  public openQQ = (appId: string, state: Record<string, any>) => {
    const params = qs.stringify({
      response_type: 'code',
      client_id: appId,
      redirect_uri: encodeURI(JSRuntime.getOriginalIdpSite(undefined, 'social_login/qq/transfer')),
      state: window.btoa(JSON.stringify(state)),
    });

    const url = `${SOCIAL_IDP_TYPE_URL[SOCIAL_IDP_TYPE.QQ]}?${params}`;

    if (this.window) {
      this.window.location.href = url;
    } else {
      window[this.WINDOW_NAME] = window.open(url, this.windowName, this.windowFeatures);
    }
  };

  // 开始显示窗口认证 - 微信
  public openWeChat = (appId: string, state: string) => {
    const params = qs.stringify({
      appid: appId,
      scope: 'snsapi_login',
      redirect_uri: JSRuntime.getOriginalIdpSite(undefined, 'social_login/weChat/transfer'),
      state: window.btoa(JSON.stringify({ state })),
      response_type: 'code',
    });

    const url = `${SOCIAL_IDP_TYPE_URL[SOCIAL_IDP_TYPE.WECHAT]}?${params}#wechat_redirect`;

    if (this.window) {
      this.window.location.href = url;
    } else {
      window[this.WINDOW_NAME] = window.open(url, this.windowName, this.windowFeatures);
    }
  };

  // 开始显示窗口认证 - 企业微信
  /**
   * 企业微信绑定条件：
   * 0. 即使一个新的租户什么也没开通，依然可以扫码，只是会报错
   * 1. 首先要企业管理员开通企业微信登录渠道，否则报错 "企业未开通此登录渠道，请联系管理员"
   * 2. 其次要用户需要给自己的企业微信登录打开并完成绑定，否则报错 "您尚未完成账号绑定"
   * */
  // TODO 除了企业信息页，其他页面传参待验证，先满足后端开发
  public openWeCom = (appId: string, state: string, usertype = 'member', accountId = '') => {
    const params = qs.stringify({
      appid: appId,
      redirect_uri: JSRuntime.getOriginalIdpSite(undefined, 'social_login/weCom/transfer'),
      state: window.btoa(JSON.stringify({ state, accountId })),
      usertype,
    });

    const url = `${SOCIAL_IDP_TYPE_URL[SOCIAL_IDP_TYPE.WECOM]}?${params}`;
    const transferUrl = JSRuntime.getOriginalIdpSite(
      undefined,
      `social_login/weCom/auth/redirect?state=${window.btoa(encodeURIComponent(url))}`,
    );
    if (this.window) {
      this.window.location.href = transferUrl;
    } else {
      window[this.WINDOW_NAME] = window.open(transferUrl, this.windowName, this.windowFeatures);
    }
  };

  // 关闭窗口
  public close = () => {
    this.window?.close();
  };

  // 监听
  addEventListener = (
    callback: (idpType: SOCIAL_IDP_TYPE, code: string, state: string, accountId: string) => void,
  ) => {
    if (this.listener) window.removeEventListener('message', this.listener);
    this.listener = (event: Event) => {
      if (_.get(event, 'data.type') === this.MESSAGE.POPUP_AUTH_SUCCESS) {
        const { state, code, idpType, accountId } = _.get(event, 'data.data') || {};
        callback(idpType, code, state, accountId);
      }
    };
    window.addEventListener('message', this.listener);
  };

  removeEventListener = () => {
    window.removeEventListener('message', this.listener);
  };
}

const socialIdpWin = new SocialIdpWindow();

export default socialIdpWin;

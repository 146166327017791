import { Popup, PopupPlacement } from 'tdesign';
import Utils from 'common/utils';
import Icon from 'common/components/Icon';
import { POLICY_EXCLUDE_TEXT } from './Constants';
import StrategyDescriptionScss from './StrategyDescription.module.scss';
import { EXCLUDE, INCLUDE } from 'common/constants/Constants';
export { pwdValidator } from './validator';

export interface IPolicy {
  minLength: number;
  maxLength: number;
  include: INCLUDE[];
  exclude: EXCLUDE[];
}

interface IProps {
  className?: string;
  policy: IPolicy;
  placement?: PopupPlacement;
}

const StrategyDescription = (props: IProps) => {
  const { className, policy, placement } = props;
  const minLength = m.get(policy, 'minLength') || 0;
  const maxLength = m.get(policy, 'maxLength') || 0;
  // const include = m.get(policy, 'include') || [];
  const exclude = m.get(policy, 'exclude') || [];

  // NOTICE: 密码策略临时下掉，写为固定的
  const renderPolicy = () => (
    <div className={StrategyDescriptionScss.policyContent}>
      <div>密码要求：</div>
      <ul>
        <li>{`· 长度${minLength}-${maxLength}位字符，必须包含大写字母、小写字母、数字和特殊字符`}</li>
        {/* {m.isArray(include) && include.length ? (
          <li>{`· 包含${include.map((str) => m.get(POLICY_INCLUDE_TEXT, str)).join('、')}`}</li>
        ) : null} */}
        {m.isArray(exclude) && exclude.length ? (
          <li>{`· 不可包含${exclude.map((str) => m.get(POLICY_EXCLUDE_TEXT, str)).join('、')}`}</li>
        ) : null}
        <li>· 不可与前3个历史密码重复</li>
      </ul>
    </div>
  );

  return (
    <Popup
      showArrow
      content={renderPolicy()}
      placement={placement || 'right'}
      overlayClassName={Utils.uniteClass(StrategyDescriptionScss.policyPopup, className)}
    >
      <Icon type="TxHelpCircle" className={StrategyDescriptionScss.helpIcon} />
    </Popup>
  );
};

export default React.memo(StrategyDescription);

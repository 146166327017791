export enum LOGIN_RES_TYPE {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS', // 直接登录成功
  ACCOUNT_OPTIONS = 'ACCOUNT_OPTIONS', // 进入账号选择

  ACCOUNT_CREATE = 'ACCOUNT_CREATE', // 进入企业创建页面
  CAPTCHA_OPTIONS = 'CAPTCHA_OPTIONS',
  INVITATION_OPTIONS = 'HANDSHAKE_OPTIONS', // 进入企业邀请页面

  MOBILE_BIND = 'MOBILE_BIND', // 会议扫码后进入手机号绑定
  MOBILE_REGISTED = 'MOBILE_REGISTED', // 会议扫码后绑定手机号，显示手机号已注册
  MOBILE_USED = 'MOBILE_USED', // 会议扫码后绑定手机号，显示手机号已经被占用
  MOBILE_LACK = 'MOBILE_LACK', // 个人版需要绑定手机号
  MOBILE_VERIFY = 'MOBILE_VERIFY', // 手机号验证弹窗
  WECHAT_VERIFY = 'WECHAT_VERIFY', // 微信验证弹窗
}

export default {
  accessNotGranted: '访问未授权',
  noAccess: '访问无权限',
  inaccessible: '无法访问',
  invalidOperation: '操作无效',
  connectionTimedOut: '连接超时',
  serverDifferent: '服务器异常',
  serverNo: '服务器无响应',
  siteIs: '站点正在维护',
  serverRoom: '服务器间连接异常',
  legalitySchool: 'API合法性校验失败',
  universal: '通用',
  networkNotConnected: '网络未连接',
  unknownMistake: '未知错误，请稍后重试',
};

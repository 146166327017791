import { AccountApi as CommonAccountApi } from 'common/api';
import Utils from 'common/utils';
import AccountApiV1 from 'idpBase/api/services/AccountApi';
import AccountApi from 'idpBase/api/servicesV2/AccountApi';
import { ENT_AUTH_TYPE } from 'idpBase/Constants';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { AUTH_STATUS, OCR_TYPE } from '../constants/Constants';
import { APP_TYPE, IAppInfoData } from '../constants/types';

class EntInformationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<EntInformationStore>;
  public isBlockLoading = true;
  public appInfoList: IAppInfoData[] = [];
  public draftId = { value: '' };
  public authInfo = {};

  public get shouldGotoResult() {
    const status = m.get(this.authInfo, 'apply_status');
    return status && status !== AUTH_STATUS.INITIAL;
  }

  public getAuthorize = () => {
    return AccountApi.getAuthorize()
      .then((res) => {
        return res;
      })
      .catch(Utils.noop);
  };

  public getAllAuthorize = () => {
    return AccountApi.getAllAuthorize()
      .then((res) => {
        this.updateStore({
          appInfoList: res.account_certify_authorizes,
        });
        return res;
      })
      .catch(Utils.noop);
  };

  public authorize = (appType?: APP_TYPE) => {
    return AccountApi.authorize(appType)
      .then(() => {
        return true;
      })
      .catch(Utils.noop);
  };
  public dealCertifyType: (type: string) => ENT_AUTH_TYPE = (type: string) => {
    const temp: Number = Number(type);
    for (const key in ENT_AUTH_TYPE) {
      if (Object.hasOwnProperty.call(ENT_AUTH_TYPE, key)) {
        const value = ENT_AUTH_TYPE[key as keyof typeof ENT_AUTH_TYPE];
        if (value === temp) return temp as ENT_AUTH_TYPE;
      }
    }
    return ENT_AUTH_TYPE.ENTERPRISE;
  };

  public getDraft(certifyType: ENT_AUTH_TYPE) {
    return AccountApi.getDraft(certifyType)
      .then((res) => {
        this.updateStore({ authInfo: res, draftId: m.get(res, 'draft_id') });
        return res;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        this.updateStore({
          isBlockLoading: false,
        });
      });
  }
  public getQRCode() {
    return AccountApi.getQr();
  }

  public clearQRStatus() {
    return AccountApi.clearQRStatus().catch(Utils.noop);
  }

  public getOcr(img_url: string, ocr_type: OCR_TYPE) {
    return AccountApi.getOcr({ img_url, ocr_type }).catch(Utils.noop);
  }

  public sendSmsCode(phone: string) {
    return AccountApi.sendSmsCode(phone).catch((err) => this.global.handleError(err));
  }

  public delAuthorization(appType?: APP_TYPE) {
    return AccountApi.delAuthorize(appType)
      .then(() => true)
      .catch(() => false);
  }
  public delTencentCloud() {
    return AccountApi.delTencentCloud()
      .then(() => true)
      .catch(() => false);
  }

  public saveAuthInfo(params: Record<string, any>) {
    return AccountApi.saveAuthInfo(
      this.draftId?.value ? { ...params, draft_id: this.draftId } : params,
    )
      .then((res) => {
        if (res.draft_id) {
          this.updateStore({
            draftId: {
              value: res.draft_id,
            },
          });
        }
        return res;
      })
      .catch(() => false);
  }

  public getQrStatus() {
    return AccountApi.getQrStatus();
  }

  public getAccountState = async () => {
    const [stateErr, res] = await Utils.resolvePromise(AccountApi.getAccountState());
    if (stateErr) {
      return;
    }
    return res;
  };

  public getAuthorizeResult(params: { state: string; code: string }) {
    // 授权，参数为code和state
    return AccountApi.getAuthorizeResult(params).then((res) => res);
  }

  public check = (simple_name: string) => {
    return AccountApi.check(simple_name)
      .then((res) => {
        return res;
      })
      .catch(() => false);
  };

  public getAuthInfo() {
    return AccountApi.getAuthInfo()
      .then((res) => {
        // 防止二维码轮询状态时一直重复渲染
        this.updateStore({
          authInfo: res,
        });
        return res;
      })
      .catch(() => false);
  }

  public async uploadImg({ upload_resource, img }: { upload_resource: string; img: File }) {
    return CommonAccountApi.uploadImg({
      upload_resource,
      img,
    }).then((res) => {
      return m.get(res, 'url');
    });
  }

  public async getSafeCheckUrl(params: { url: string }) {
    const [err, res] = await Utils.resolvePromise(AccountApiV1.sendForLogoSafe(params));
    if (err) {
      return;
    }
    return m.get(res, 'url');
  }
}

export default EntInformationStore;

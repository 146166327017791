export enum SOCIAL_IDP_TYPE {
  WECHAT = 'WeChat',
  QQ = 'QQ',
  WECOM = 'WeCom',
}

export const SOCIAL_IDP_TYPE_NAME = {
  [SOCIAL_IDP_TYPE.WECOM]: '企业微信',
  [SOCIAL_IDP_TYPE.WECHAT]: '微信',
  [SOCIAL_IDP_TYPE.QQ]: 'QQ',
};

export const SOCIAL_IDP_TYPE_URL = {
  [SOCIAL_IDP_TYPE.QQ]: 'https://graph.qq.com/oauth2.0/authorize',
  [SOCIAL_IDP_TYPE.WECHAT]: 'https://open.weixin.qq.com/connect/qrconnect',
  [SOCIAL_IDP_TYPE.WECOM]: 'https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect',
};

import { dealBeaconReport } from './beaconReport';
import { getGlobalReportParams } from './reportParams/getGlobalReportParams';
import { getUserReportParams } from './reportParams/getUserReportParams';

const beaconReport = (
  eventCode: string,
  options?: {
    /**
     * 页面跳转前需要上报数据使用该参数，onSendBeacon底层实现逻辑为navigator.sendBeacon
     */
    isSendBeacon?: boolean;
  },
  globalParams?: Record<string, any>,
  userParams?: Record<string, any>,
) => {
  const params = {
    ...getUserReportParams(userParams),
    ...getGlobalReportParams(eventCode, globalParams),
  };
  if (_.isFunction(dealBeaconReport)) dealBeaconReport(eventCode, params, options?.isSendBeacon);
};

export default beaconReport;

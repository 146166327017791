import { CaptchaPack } from 'common/apiRequest';
import BaseApi from 'common/apiRequest/apis/BaseApi';
import { VERIFY_CODE_SCENE } from 'common/constants/Constants';
import { EMAIL_VERIFY_CODE_SCENE, PASSWORD_LOGIN_TYPE } from 'idpBase/Constants';
import APINames from '../APINames';
import { Request } from '../apiConcentrator';

class AuthnApi extends BaseApi {
  public requestInstance = new Request(APINames.AUTHN);

  // 获得租户初始配置数据
  getSettings(params: { accountId: string }) {
    return this.requestInstance.get('settings', params);
  }

  // 获得登录后可能存在的 sso 应用列表
  getSSOApps() {
    return this.requestInstance.get(['apps']);
  }

  // 密码登录接口 手机号 | 邮箱
  loginByPwd(body: { credential: string; loginType: PASSWORD_LOGIN_TYPE; app?: string }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post('', { ...body, captchaVerification }),
    );
  }

  // 验证码登录接口 手机号
  loginByCode(body: { verificationSessionId: string; verificationCode: string; app?: string }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post('sms', { ...body, captchaVerification }),
    );
  }

  // 验证码登录接口 邮箱
  loginByEmailCode(body: {
    verificationSessionId: string;
    verificationCode: string;
    app?: string;
  }) {
    return this.requestInstance.post('email', body);
  }

  loginByEmailOrDomain(params: {
    email?: string;
    domain?: string;
    accountId?: string;
    domainName?: string;
  }) {
    Object.keys(params).forEach((key) => {
      _.set(params, key, encodeURIComponent(params[key]));
    });
    return this.requestInstance.get('sso', params);
  }

  // AD 认证源登录
  loginIdentity(accountId: string, idpInstanceId: string, data: { credential: string }) {
    return this.requestInstance.post(['sso', accountId, 'login', idpInstanceId], data);
  }

  // 获取社会认证源信息
  getSocialIdp(idpType: 'WeChat' | 'QQ') {
    return this.requestInstance.get(['sso', 'socialIdp', idpType]);
  }

  // 检测社会认证源与手机号绑定的结果
  checkSocialIdpBound(
    idpType: 'WeChat' | 'QQ',
    body: { code: string; state: string; redirectUri?: string },
  ) {
    return this.requestInstance.post(['sso', 'socialIdp', idpType, ':checkBound'], body);
  }

  // 没有与手机号绑定过的社会认证源进行绑定手机号操作
  bindSocialIdp(
    idpType: 'WeChat' | 'QQ',
    body: { verificationSessionId: string; verificationCode: string; state: string },
  ) {
    return this.requestInstance.post(['sso', 'socialIdp', idpType, ':bind'], body);
  }

  chooseAccount(id: string, app?: string) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post('account', { id, app, captchaVerification }),
    );
  }

  // IDP 这边的发送验证码，与 admin 不一样
  sendVerificationCode(params: { mobile: string; event: VERIFY_CODE_SCENE }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['credentials', 'sms:send'], {
        ...params,
        captchaVerification,
      }),
    );
  }

  // 手机验证码验证，非登录
  verifyCode(body: {
    verificationSessionId: string;
    verificationCode: string;
    event?: VERIFY_CODE_SCENE;
  }) {
    return this.requestInstance.post(['credentials', 'sms:verify'], body);
  }

  // 手机验证码验证，不完美自然人绑定手机用
  verifyMobile(body: { verificationSessionId: string; verificationCode: string; event?: string }) {
    return this.requestInstance.post(['sms:verifyMobile'], {
      ...body,
      event: body.event || 'complete_mobile',
    });
  }

  verifyWeChatAndSwitch(body: {
    session_key: string;
    status: number;
    account_client_type: number;
    auth_login_code: string;
    corp_id: string;
    identity_type: number;
    accountId: string;
  }) {
    return this.requestInstance.post(['factors', 'wechat', ':verify'], body);
  }

  verifyWeChat(body: {
    session_key: string;
    status: number;
    account_client_type: number;
    auth_login_code: string;
    corp_id: string;
    identity_type: number;
  }) {
    return this.requestInstance.post(['verifyWechat'], body);
  }

  resetPassword(credential: string) {
    return this.requestInstance.post(['credentials', 'reset_password'], { credential });
  }

  // 发送邮箱验证码
  sendEmailVerificationCode(params: { email: string; event: EMAIL_VERIFY_CODE_SCENE }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['credentials', 'email:send'], {
        ...params,
        captchaVerification,
      }),
    );
  }

  // 邮箱验证码验证
  verifyEmailCode(body: {
    verificationSessionId: string;
    verificationCode: string;
    event?: EMAIL_VERIFY_CODE_SCENE;
    app?: string;
  }) {
    return this.requestInstance.post(['credentials', 'email:verify'], body);
  }

  // 设置邮件密码，邀请页用
  setEmailPassword(data: { password: string; event?: string; app?: string; email?: string }) {
    return this.requestInstance.post(['credentials', 'email_password', ':set'], data);
  }

  // 设置或重置邮件密码，登录页用
  resetEmailPassword(data: { credential: string; event?: string }) {
    return this.requestInstance.post(['credentials', 'email_password', ':reset'], data);
  }

  // 区分忘记密码重置密码的条件：设置 | 重置 （手机号密码，及邮箱密码都是这个接口）
  checkPwdResetType() {
    return this.requestInstance.get(['forgetting_password_type']);
  }

  // 后端校验url是否正确，如果正确则直接302重定向
  redirectToLandingPage(url: string) {
    return this.requestInstance.get(['redirect'], { redirectUrl: encodeURIComponent(url) });
  }

  // 无企业用户-创建企业
  setupCompany(params: Record<string, any>) {
    return this.requestInstance.post(['accounts'], params);
  }

  getUserInfo(app?: string) {
    return this.requestInstance.get(':get_self_v2', { app });
  }

  logout() {
    return this.requestInstance.post(':logout');
  }

  // 登录（供管理员绑定企业
  bindLogin(params: { code: string; verificationCode: string; verificationSessionId: string }) {
    return this.requestInstance.post(['account_binding', ':login'], params);
  }

  // 获取要绑定企业的应用信息
  getAppInfo(params: { code: string; bound_info?: boolean }) {
    return this.requestInstance.get(['account_binding', ':get_app_info'], params);
  }

  // 绑定登录页面的短信验证码发送
  sendCode(mobile: string, code: string) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['account_binding', 'sms:send'], {
        mobile,
        code,
        captchaVerification,
      }),
    );
  }

  // 绑定企业
  bindAccount(id: string) {
    return this.requestInstance.post(['account_binding', ':bind'], { id });
  }

  // 发送绑定企业的短信（给所有超管发送）
  sendBindingSms() {
    return this.requestInstance.post(['account_binding', 'binding_code', ':send']);
  }

  // 获取“超管绑定登录页”的信息
  getBindingInfo(params: { bindingCode: string }) {
    return this.requestInstance.get(['account_binding', ':get_binding_info'], params);
  }

  // 登录并绑定企业/短信验证（仅供超管）
  bindLoginByPwd(body: { loginName: string; password: string; bindingCode: string }) {
    return this.requestInstance.post(['account_binding', ':admin_login'], body);
  }
  bindLoginByCode(body: {
    verificationSessionId: string;
    verificationCode: string;
    bindingCode: string;
  }) {
    return this.requestInstance.post(['account_binding', ':admin_sms_login'], body);
  }

  // 企微扫码登录
  wwScanLogin(idpType: string, params: { code: string; state: string; accountId?: string }) {
    return this.requestInstance.post(['sso', 'ssoIdp', `${idpType}`, ':login'], params);
  }

  // 企微绑定
  bindWeComIdp(
    idpType: string,
    params: { verificationSessionId: string; verificationCode: string; state: string },
  ) {
    return this.requestInstance.post(['sso', 'ssoIdp', `${idpType}`, ':bind'], params);
  }

  // 用户名密码登录忘记密码
  sendEnterpriseSms(params: { mobile?: string; username?: string; event: string }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['enterprise', 'credentials', 'sms', ':send'], {
        ...params,
        captchaVerification,
      }),
    );
  }

  // 用户名密码登录短信验证码验证
  verifyEnterpriseSms(params: {
    verificationSessionId: string;
    verificationCode: string;
    event: string;
  }) {
    return this.requestInstance.post(['enterprise', 'credentials', 'sms', ':verify'], params);
  }

  // 用户名密码登录设置新密码
  resetEnterprisePwd(credential: string) {
    return this.requestInstance.post(['enterprise', 'password', ':reset'], {
      credential,
    });
  }

  // 初次登录强制修改密码
  setEnterprisePwd(credential: string) {
    return this.requestInstance.post(['enterprise', 'password', ':reset'], {
      credential,
    });
  }

  // 账号密码登录
  loginEnterprise(params: { credential: string }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['enterprise'], { ...params, captchaVerification }),
    );
  }

  // 用户名校验
  forgetEnterprisePwd(params: {
    username: string;
    accountId: string;
    captchaVerification?: object;
  }) {
    return this.requestInstance.post(['enterprise', 'user', ':verify'], params);
  }

  // 登录前获取当前登录用户命中的密码策略
  getPasswordPolicy() {
    return this.requestInstance.get(['account', 'user', 'passwordPolicy']);
  }

  // 获取自然人下的租户列表
  getAccountOptions(app?: string) {
    return this.requestInstance.get(['accounts'], app ? { app } : undefined);
  }

  // 获取邀请信息
  getInvitationInfo(accountId: string, handshakeId: string) {
    return this.requestInstance.get(['accounts', accountId, 'handshakes', handshakeId]);
  }

  // 接受邀请
  acceptInvitation(handshakes: { handshakeId: string; mobile: string }[], app?: string) {
    return this.requestInstance.post(['handshakes', ':accept'], {
      handshakes,
      app,
    });
  }

  // 接受邮箱邀请
  acceptEmailInvitation(handshakeId: string, email: string) {
    return this.requestInstance.post(['handshake', handshakeId, ':accept'], { email });
  }

  // 拒绝邀请
  rejectInvitation(handshakeIds: string[], app?: string) {
    return this.requestInstance.post(['handshakes', ':reject'], { handshakeIds, app });
  }

  // 获取邀请(handshakes)列表
  getHandShakes() {
    return this.requestInstance.get(['handshakes']);
  }

  // 会议二维码登陆
  weMeetQrCodeLogin(data: Record<string, any>) {
    return this.requestInstance.post(['wemeet', 'qrcode'], data);
  }

  // 会议企微二维码登录
  weMeetWecomQrCodeLogin(data: { auth_code: string }) {
    return this.requestInstance.post(['qyWechat', 'qrcode'], data);
  }

  // 会议扫码后 微信绑定到手机号上
  bindWeChatToMobile(data: {
    verificationSessionId: string;
    verificationCode: string;
    app?: string;
  }) {
    return this.requestInstance.post(['wechat', ':bindMobile'], data);
  }

  // 会议扫码 -> 绑定手机号之后的登录 1: 绑定并登录 2:直接登录手机号 3: 解绑并登录
  loginAfterBindWechat(action: number) {
    return this.requestInstance.post(['wechat', ':phoneConflict'], { action });
  }

  // 个人版开通会议，获取权限
  getAppAuth(params: { app?: string; redirectUrl?: string }) {
    return this.requestInstance.post(['auto_get_app'], params);
  }

  // authCode换登录态
  getAuthCodeSession(params: Record<string, any>) {
    return this.requestInstance.post(['auth_code', 'session'], params);
  }
}
export default new AuthnApi();

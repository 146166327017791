import { VERIFY_CODE_LIMIT_ERR } from 'common/constants/Constants';

export const REGISTER_ERROR_MSG = {
  E0010061: '手机号已注册，请登录',
};

export const VERIFY_CODE_ERROR = {
  E0010028: '验证码不正确，请重新输入',
  ...VERIFY_CODE_LIMIT_ERR,
};

export enum REGISTER_USER_STEP {
  PHONE,
  VERIFY_CODE,
}

export const UNBIND_TEXT = {
  title: '手机号被他人注册/绑定 怎么办',
  subTitle:
    '因为通讯运营商可能会将回收的手机号二次售卖给您，所以您当前的号码可能已经被原号码拥有者注册/绑定过腾讯统一身份的账号。',
  list: [
    '如果您想解除该手机号与原账号的绑定关系，请点击申请解绑按钮。',
    '如果您曾使用该手机号注册/绑定过腾讯统一身份，建议您使用该手机号码登录原账号进行使用。',
  ],
  footer: '申请解绑后，原账号将无法使用您的手机号进行登录。',
};

import { SOCIAL_IDP_TYPE } from 'common/components/SocialIdpCertWindow';

export enum LOGIN_METHOD {
  MOBILE, // 手机号登录
  MEET_QRCODE, // 腾讯会议二维码登录
  MEET_WECOM_QRCODE, // 腾讯会议使用企微二维码登录（会议主体）
  EMAIL, // 邮箱登录
  WECOM, // 企业微信登录
  SSO, // SSO登录
  WECHAT, // 微信登录
  QQ, // QQ登录
}

const DATA_REPORT_LOGIN_METHOD_TYPE = {
  [LOGIN_METHOD.EMAIL]: 'email',
  [LOGIN_METHOD.MEET_QRCODE]: 'wechat',
  [LOGIN_METHOD.MEET_WECOM_QRCODE]: 'wecom',
  [LOGIN_METHOD.MOBILE]: 'phone',
  [LOGIN_METHOD.WECOM]: 'wecom',
  [LOGIN_METHOD.SSO]: 'sso',
  [LOGIN_METHOD.WECHAT]: 'WeChat',
  [LOGIN_METHOD.QQ]: 'QQ',
};

export enum MOBILE_LOGIN_STATUS {
  INPUT_MOBILE, // 输入手机号
  PASSWORD_LOGIN, // 手机号密码登录
  VERIFY_CODE_LOGIN, // 手机号验证码登录
  FORGET_PASSWORD_VERIFY_CODE, // 忘记密码-输入手机验证码
  FORGET_PASSWORD_NEW, // 忘记密码-输入新密码
}

export enum EMAIL_LOGIN_STATUS {
  INPUT_EMAIL, // 输入邮箱
  PASSWORD_LOGIN, // 邮箱密码登录
  VERIFY_CODE_LOGIN, // 邮箱验证码登录
  FORGET_PASSWORD_VERIFY_CODE, // 忘记密码-输入邮箱验证码
  FORGET_PASSWORD_NEW, // 忘记密码-输入新密码
}

export enum MEET_QRCODE_LOGIN_STEP {
  SCAN, // 扫码
  BIND_MOBILE, // 绑定手机号
  VERIFY_CODE, // 验证验证码
}

export enum MEET_BIND_WECHAT_LOGIN_ACTION {
  BIND_LOGIN = 1, // 绑定并登录
  LOGIN_MOBILE = 2, // 直接登录手机号
  UNBIND_LOGIN = 3, // 解绑旧账号然后创建新账号或者解绑后登录
}

export const SMS_LOGIN_ERROR_MSG = {
  E0010377: '验证码错误次数过多，请稍后再试',
};

export const PWD_LOGIN_ERROR_MSG = {
  E0010377: '密码错误次数过多，请稍后再试',
};

export const LOGIN_ERROR_MSG = {
  E0010028: '手机号或验证码不正确，请重新输入', // 手机号+验证码: 验证码有误
  E0010007: '手机号或密码不正确，请重新输入', // 手机号+密码： 验证码有误
  E0010035: '账号已被停用，请联系管理员', // 人员状态不可用（单个企业情况下）
  E0010036: '账号未绑定企业，登录失败！', // 账号未关联任何企业
  E0010022: '请求过于频繁，请稍后再试',
};

export const FORGET_PWD_ERROR_MSG = {
  E0010028: '手机号或验证码不正确，请重新输入', // 手机号+验证码: 验证码有误
  ...SMS_LOGIN_ERROR_MSG,
};

export const EMAIL_LOGIN_ERROR_MSG = {
  E0010028: '邮箱或验证码不正确，请重新输入', // 邮箱+验证码: 验证码有误
  E0010007: '邮箱或密码不正确，请重新输入', // 邮箱+密码： 验证码有误
  E0010035: '账号已被停用，请联系管理员', // 人员状态不可用（单个企业情况下）
  E0010036: '账号未绑定企业，登录失败！', // 账号未关联任何企业
  E0010022: '请求过于频繁，请稍后再试',
};

export const EMAIL_FORGET_PWD_ERROR_MSG = {
  E0010028: '邮箱或验证码不正确，请重新输入', // 邮箱+验证码: 验证码有误
  ...SMS_LOGIN_ERROR_MSG,
};

export enum PWD_SET_TYPE {
  SET = 'RESET_PASSWORD',
  RESET = 'CHANGE_PASSWORD',
}

export const SET_PWD_MSG = {
  successMsg: '密码设置成功！',
  failMsg: '密码设置失败',
  title: '设置密码',
  pwdLabel: '密码',
  pwdPlaceholder: '密码',
  pwdRequiredRule: '请输入密码',
  confirmPwdLabel: '请再次输入密码',
  confirmPwdPlaceholder: '请再次输入密码',
  confirmPwdRequiredRule: '请再次输入密码',
  button: '确定',
};

export const RESET_PWD_MSG = {
  successMsg: '密码重置成功！',
  failMsg: '密码重置失败',
  title: '重置密码',
  pwdLabel: '新密码',
  pwdPlaceholder: '新密码',
  pwdRequiredRule: '请输入新密码',
  confirmPwdLabel: '请再次输入密码',
  confirmPwdPlaceholder: '请再次输入密码',
  confirmPwdRequiredRule: '请再次输入密码',
  button: '确定',
};

export const WECHAT_VERIFY_ERROR = {
  E0010221: '请使用本人微信扫码',
};

export enum INVITE_FROM {
  MOBILE = 'Mobile',
  EMAIL = 'Email',
}

interface IMethodConfig {
  tip: string;
  hasDivider?: boolean;
  defaultIcon: string;
  activeIcon?: string;
  size?: number;
  hideInMobile?: boolean;
  dataReportType: string;
  socialIdpType?: SOCIAL_IDP_TYPE;
}

export const METHODS_CONFIG: { [key: string]: IMethodConfig } = {
  [LOGIN_METHOD.MEET_QRCODE]: {
    tip: '微信登录',
    hasDivider: false,
    defaultIcon: 'TxLogoWechat2',
    activeIcon: 'TxLogoWechat3',
    size: 32,
    hideInMobile: true,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.MEET_QRCODE],
  },
  [LOGIN_METHOD.MEET_WECOM_QRCODE]: {
    tip: '企业微信登录',
    hasDivider: false,
    defaultIcon: 'TxLogoWecom',
    activeIcon: 'TxLogoWecom2',
    size: 32,
    hideInMobile: true,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.MEET_WECOM_QRCODE],
  },
  [LOGIN_METHOD.WECOM]: {
    tip: '企业微信登录',
    hasDivider: false,
    defaultIcon: 'TxLogoWecom',
    activeIcon: 'TxLogoWecom2',
    size: 32,
    hideInMobile: true,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.WECOM],
  },
  [LOGIN_METHOD.MOBILE]: {
    tip: '手机号码登录',
    hasDivider: false,
    defaultIcon: 'TxMobile',
    size: 32,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.MOBILE],
  },
  [LOGIN_METHOD.EMAIL]: {
    tip: '邮箱登录',
    hasDivider: false,
    defaultIcon: 'TxLogoMail',
    size: 32,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.EMAIL],
  },
  [LOGIN_METHOD.SSO]: {
    tip: '使用SSO登录',
    hasDivider: true,
    defaultIcon: 'TxLogoSSO',
    size: 40,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.SSO],
  },
  [LOGIN_METHOD.WECHAT]: {
    tip: '微信登录',
    hasDivider: true,
    defaultIcon: 'TxLogoWechat2',
    activeIcon: 'TxLogoWechat3',
    size: 32,
    hideInMobile: true,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.WECHAT],
    socialIdpType: SOCIAL_IDP_TYPE.WECHAT,
  },
  [LOGIN_METHOD.QQ]: {
    tip: 'QQ登录',
    hasDivider: true,
    defaultIcon: 'TxLogoQQ',
    activeIcon: 'TxLogoQQ',
    size: 32,
    hideInMobile: true,
    dataReportType: DATA_REPORT_LOGIN_METHOD_TYPE[LOGIN_METHOD.QQ],
    socialIdpType: SOCIAL_IDP_TYPE.QQ,
  },
};

import Utils from 'common/utils';
import AccountApi from 'idpBase/api/servicesV2/AccountApi';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';

class ProgressStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ProgressStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public authInfo = {};

  /**
   * 获取认证信息
   */
  public getAuthInfo() {
    return AccountApi.getAuthInfo()
      .then((res) => {
        this.updateStore({
          authInfo: res,
        });
        return true;
      })
      .catch(() => false);
  }

  public revokeAuth() {
    return AccountApi.revokeAuth().catch(Utils.noop);
  }
}
export default ProgressStore;

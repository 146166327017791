import BaseApi from 'common/apiRequest/apis/BaseApi';
import { CaptchaPack } from 'common/apiRequest';
import JSRuntime from 'common/utils/JSRuntime';
import { EMAIL_VERIFY_CODE_SCENE, USER_VERIFY_CODE_SCENE } from 'common/constants/Constants';
import APINames from '../APINames';
import { Request } from '../apiConcentrator';

class UserApi extends BaseApi {
  public requestInstance = new Request(APINames.USER, {
    baseURL: `${JSRuntime.apiSite}/user/v1`,
  });

  setOrResetPassword(credential: string) {
    return this.requestInstance.post('password:reset', { credential });
  }

  // 老密码验证 手机号的密码
  verifyOldPassword(credential: string, event?: string) {
    return this.requestInstance.post('password:verify', { credential, event });
  }

  sendCode(event?: USER_VERIFY_CODE_SCENE, mobile?: string) {
    // mobile 不存在代表给当前用户发送验证码
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(
        'sms:send',
        mobile ? { mobile, event, captchaVerification } : { event, captchaVerification },
      ),
    );
  }

  // 验证手机号与验证码
  verifyMobile(data: {
    verificationCode: string;
    verificationSessionId: string;
    event?: USER_VERIFY_CODE_SCENE;
  }) {
    return this.requestInstance.post('sms:verify', data);
  }

  // 发送邮箱验证码 -- 个人设置
  sendEmailCode(email?: string, event?: EMAIL_VERIFY_CODE_SCENE) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(
        ['email:send'],
        email ? { email, event, captchaVerification } : { event, captchaVerification },
      ),
    );
  }

  // 邮箱验证码验证 -- 个人设置
  verifyEmail(data: {
    verificationCode: string;
    verificationSessionId: string;
    event?: EMAIL_VERIFY_CODE_SCENE;
  }) {
    return this.requestInstance.post('email:verify', data);
  }

  // 邮箱密码验证
  verifyOldEmailPassword(credential: string, event?: string) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post('email_password:verify', {
        credential,
        event,
        captchaVerification,
      }),
    );
  }

  // 邮箱密码设置 设置密码 | 修改密码
  setEmailPassword(credential: string, event?: EMAIL_VERIFY_CODE_SCENE) {
    return this.requestInstance.post('password:set', { credential, event });
  }

  // 邮箱绑定到租户
  bindEmailAccount() {
    return this.requestInstance.post('email:bind', {});
  }

  // 邮箱换绑到租户
  rebindEmailAccount() {
    return this.requestInstance.post('email:rebind', {});
  }

  // 邮箱解绑
  unbindEmailAccount() {
    return this.requestInstance.post('email:unbind', {});
  }

  // 用户重置手机号
  resetMobile() {
    return this.requestInstance.patch('mobile', {});
  }

  // 用户初次设置手机号
  setMobile(data: { verificationCode: string; verificationSessionId: string }) {
    return this.requestInstance.post(':assign', data);
  }

  // 企业登录 用户重置密码校验老密码
  verifyEnterpriseOldPwd(credential: string) {
    return this.requestInstance.post(['enterprise', 'password:verify'], { credential });
  }

  // 企业登录 用户设置新密码
  setOrResetEnterprisePwd(credential: string) {
    return this.requestInstance.post(['enterprise', 'password:reset'], { credential });
  }

  // 租户 换绑,发送验证码
  rebindTenantSendCode(accountId: string, data: { mobile: string }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['account', accountId, 'rebinding', 'sms:send'], {
        ...data,
        captchaVerification,
      }),
    );
  }
}

export default new UserApi();

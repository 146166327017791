import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/User';

class UserAccountsApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNTS);

  // 租户 换绑至其他手机号
  rebindMobileOfTenant(
    accountId: string,
    data: { verificationCode: string; verificationSessionId: string; mobile: string },
  ) {
    return this.requestInstance.post([accountId, ':rebind'], data);
  }
  // 解除手机号绑定的所有租户
  unbindAccounts() {
    return this.requestInstance.post([':unbind']);
  }
}
export default new UserAccountsApi();

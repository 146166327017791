import { useStore, convert } from 'idpBase/store';
import { Dialog } from 'tdesign';
import { INVITE_FROM } from '../constants/Constants';
import VerifyCode from './VerifyCode';
import EmailPwdSet from './EmailPwdSet';
import VerifyScss from '../style/Verify.module.scss';

const VerifyDialog = () => {
  const { invitationStore } = useStore();
  const { dialogVisible, inviteFrom } = invitationStore;

  const onClose = () => {
    invitationStore.updateStore({
      dialogVisible: false,
    });
  };

  const isEmail = inviteFrom === INVITE_FROM.EMAIL;
  const content = isEmail ? <EmailPwdSet /> : <VerifyCode />;
  const header = isEmail ? '设置密码' : '身份验证';

  return (
    <Dialog
      className={VerifyScss.verifyDialog}
      header={header}
      visible={dialogVisible}
      onClose={onClose}
      destroyOnClose
      footer={false}
    >
      {content}
    </Dialog>
  );
};

export default convert(VerifyDialog);

/**
 * 更多操作提取
 * */
import { useNavigate, useLocation } from 'react-router-dom';
import JSRuntime from 'common/utils/JSRuntime';
import Utils from 'common/utils';
import socialIdpWin, { SOCIAL_IDP_TYPE } from 'common/components/SocialIdpCertWindow';
// import { TabFocusWrapper } from 'common/components/TabFocusDom';
import { useStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';
// import LoginMethodsScss from './LoginMethods.module.scss';
import qqImg from 'common/static/images/qq.png';
import wechatImg from 'common/static/images/wechat.png';
import weComImg from 'common/static/images/weCom.png';

// import defaultQQImg from 'common/static/images/defaultQQ.png';
// import defaultWeChatImg from 'common/static/images/defaultWeChat.png';
// import defaultWeComImg from 'common/static/images/defaultWeCom.png';
import { init } from './Utils';
import { ERROR_MSG } from './Constants';

export { qqImg, wechatImg, weComImg };

// interface ILinkItem {
//   idpType: SOCIAL_IDP_TYPE;
//   img?: string;
//   disabled?: boolean;
//   defaultImg?: string;
// }

interface IProps {
  children?: React.ReactElement<any, any> | null;
  idpType: SOCIAL_IDP_TYPE;
  onClick?: () => void;
}

// const linkMap = {
//   WECHAT: { img: wechatImg, defaultImg: defaultWeChatImg, idpType: SOCIAL_IDP_TYPE.WECHAT },
//   QQ: {
//     img: qqImg,
//     defaultImg: defaultQQImg,
//     idpType: SOCIAL_IDP_TYPE.QQ,
//   },
//   WECOM: { img: weComImg, defaultImg: defaultWeComImg, idpType: SOCIAL_IDP_TYPE.WECOM },
// };

const SocialLoginMethod: React.FC<IProps> = (props: IProps) => {
  const {
    socialLoginStore,
    rootStore,
    login: { loginBaseStore },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [finished, setFinished] = React.useState(false);

  let loopTimer: NodeJS.Timeout;

  React.useEffect(() => {
    // 添加事件监听
    socialIdpWin.addEventListener((idpType: SOCIAL_IDP_TYPE, code: string, state: string) => {
      messageListener(idpType, code, state);
    });
    return () => {
      socialIdpWin.removeEventListener();
      if (loopTimer) clearTimeout(loopTimer);
      socialIdpWin.close();
    };
  }, []);

  const goBack = () => {
    if (_.get(location, 'state.goBack')) {
      navigate(-1);
    } else {
      navigate('/login', { replace: true });
    }
  };

  const loopChildWindow = () => {
    if (loopTimer) clearTimeout(loopTimer);
    loopTimer = setTimeout(() => {
      if (socialIdpWin.window) {
        loopChildWindow();
      } else if (!finished) {
        // 弹窗中认证成功才会有 finished 的状态
        goBack();
      }
    }, 30);
  };

  // 登录成功后跳转到选择企业页面
  const redirectToSelectOrg = (accountResult: any) => {
    // NOTICE: 当前组件,社会认证源直接点击登录已不再有引用，这里的 dealLoginSuccess 缺少了 mobile 的传入（invitation 需要）
    loginBaseStore.dealLoginSuccess(accountResult);
  };

  // 信息传输
  const messageListener = React.useCallback(
    (idpType: SOCIAL_IDP_TYPE, code: string, state: string) => {
      setFinished(true);
      clearTimeout(loopTimer);
      socialIdpWin.close();

      switch (idpType) {
        case SOCIAL_IDP_TYPE.QQ:
        case SOCIAL_IDP_TYPE.WECHAT:
          AuthnApi.checkSocialIdpBound(idpType, {
            code,
            state,
            redirectUri: encodeURI(
              JSRuntime.getIdpSite(undefined, `social_login/${idpType.toLowerCase()}/transfer`),
            ),
          })
            .then((result: any) => {
              const bound = m.get(result, 'bound');
              if (bound) {
                redirectToSelectOrg(result);
                return;
              }
              // NOTICE 跳转到绑定手机号页面
              navigate('/social_login/bind_phone');
              socialLoginStore.updateStore({
                socialIdpType: idpType,
                socialIdpState: state,
              });
            })
            .catch(Utils.noop);
          break;
        case SOCIAL_IDP_TYPE.WECOM: {
          const weComState = _.get(
            Utils.safeParseJSON(window.atob(_.isString(state) ? state : '')),
            'state',
          );
          AuthnApi.wwScanLogin(SOCIAL_IDP_TYPE.WECOM, { code, state: weComState })
            .then(() => {
              rootStore.redirectToLandingPage();
            })
            .catch((error: Error) => {
              const errorCode = _.get(error, 'data.errCode');

              // 企业开通了企业微信扫码，但是个人账号没有绑定
              if (errorCode === 'E0010127') {
                navigate('/social_login/bind_phone');
                socialLoginStore.updateStore({
                  socialIdpType: idpType,
                  socialIdpState: weComState,
                });
                return;
              }

              Utils.redirectToIdp();
              socialLoginStore.global.handleError(ERROR_MSG[errorCode] || error);
            });
          break;
        }
        default:
          break;
      }
    },
    [],
  );

  const onClick = () => {
    //  先进行初始化
    socialIdpWin.prepare();

    props?.onClick?.();

    // 获取信息
    init(props.idpType)
      ?.then((result: { appId: string; state: string }) => {
        const appId = m.get(result, 'appId') || '';
        const state = m.get(result, 'state') || '';
        if (!appId) return;
        if (!_.get(location, 'state.noOpen')) {
          switch (props.idpType) {
            case SOCIAL_IDP_TYPE.QQ:
              socialIdpWin.openQQ(appId, { state, socialIdp: SOCIAL_IDP_TYPE.QQ });
              break;
            case SOCIAL_IDP_TYPE.WECHAT:
              socialIdpWin.openWeChat(appId, state);
              break;
            case SOCIAL_IDP_TYPE.WECOM:
              socialIdpWin.openWeCom(appId, state);
            // eslint-disable-next-line no-fallthrough
            default:
              break;
          }
          loopChildWindow();
        }
      })
      .catch(() => {
        socialIdpWin.close();
      });
  };

  return props.children
    ? React.cloneElement(props.children, {
        onClick,
      })
    : null;
};

export default React.memo(SocialLoginMethod);

import Utils from 'common/utils';
import { TUpdateStore, TGlobalStore } from 'idpBase/store';
import { AuthnApi as CommonAuthnApi } from 'common/api';
import { AuthnApi } from 'idpBase/api';
import { ACCOUNT_TYPE, APP_EDITION_TYPE } from 'common/constants/Constants';
import {
  STEP_MODE,
  BINDING_ERROR_MSG,
  PERSONAL_BINDING_ERROR_MSG,
  LOGIN_RES_TYPE,
} from './constants/Constants';

class BindLoginStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<BindLoginStore>;
  public resetStore: () => void;

  public isInitLoading = true;
  public appInfo = {};

  public verificationSessionId = '';
  public countdown = 0;

  public isLoginLoading = false;

  public tenants = [];
  public selectedTenant = {};

  public mode = STEP_MODE.LOGIN;

  public isBindLoading = false;

  public isCreateLoading = false;

  public isSendLoading = false;
  public mobile = ''; // 手机号码
  public verifyErrorMessage = '';

  public createVisible = false;

  private bindSuccess(code: string) {
    this.global.handleSuccess('应用激活成功');
    // 获取instance id
    AuthnApi.getAppInfo({ code, bound_info: true })
      .then((res) => {
        const edition = m.get(res, 'edition');
        const appId = m.get(res, 'appInstanceId');
        // 如果是企业应用，进入到管理后台应用详情
        if (edition === APP_EDITION_TYPE.COMPANY) {
          Utils.redirectToAdmin('', `/application/${appId}`);
        } else {
          // 如果是个人版应用，直接进入服务商应用
          const redirectUrl = CommonAuthnApi.getLoginSSOLink(appId);
          Utils.safeRedirect(redirectUrl);
        }
      })
      .catch((err: Error) => {
        this.global.handleError(err);
      });
  }

  // 获取应用信息
  public getAppInfo(code: string) {
    this.isInitLoading = true;
    return AuthnApi.getAppInfo({ code })
      .then((res) => {
        this.updateStore({
          appInfo: res,
        });
      })
      .catch((err: Error) => {
        this.global.handleError(err);
      })
      .finally(() => {
        this.updateStore({
          isInitLoading: false,
        });
      });
  }

  // 获取验证码
  public getSmsCode(mobile: string, code: string) {
    return AuthnApi.sendCode(mobile, code)
      .then((res: { verificationSessionId: string }) => {
        this.updateStore({
          verificationSessionId: m.get(res, 'verificationSessionId'),
          countdown: m.get(res, 'expire') || 0,
        });
        return Promise.resolve(res);
      })
      .catch((error: Error) => {
        this.global.handleError(error);
        return Promise.reject(error);
      });
  }

  // 登录
  public bindLogin(
    params: {
      code: string;
      verificationCode: string;
      verificationSessionId: string;
    },
    code: string,
  ) {
    this.isLoginLoading = true;
    return AuthnApi.bindLogin(params)
      .then((res) => {
        const edition = m.get(this.appInfo, 'edition');
        const accounts = _.get(res, 'next.accountOptions.accounts') || [];

        if (edition === APP_EDITION_TYPE.COMPANY) {
          const type = _.get(res, 'next.type') || '';
          // 创建企业弹窗打开
          if (type === LOGIN_RES_TYPE.ACCOUNT_CREATE) {
            this.updateStore({
              createVisible: true,
            });
          } else {
            this.updateStore({
              tenants: accounts,
              mode: type,
            });
          }
        } else {
          // 如果是个人版，且有账号，直接绑定，如果没有账号，跳转到创建个人账号页面
          if (m.isArray(accounts) && accounts.length) {
            const accountId = m.get(accounts, [0, 'id']);
            this.bindTenant(accountId, code);
          } else {
            this.updateStore({
              mode: STEP_MODE.PERSONAL_CREATE,
            });
          }
        }

        return Promise.resolve(res);
      })
      .catch((error: Error) => {
        this.global.handleError(error);
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({
          isLoginLoading: false,
        });
      });
  }

  // 绑定企业
  public bindTenant(id: string, code: string) {
    this.isBindLoading = true;
    const edition = m.get(this.appInfo, 'edition');
    return AuthnApi.bindAccount(id)
      .then(() => {
        this.bindSuccess(code);
      })
      .catch((error: Error) => {
        const appName = m.get(this.appInfo, 'name');
        const errCode = _.get(error, 'data.errCode');
        const errorMessages =
          edition === APP_EDITION_TYPE.COMPANY ? BINDING_ERROR_MSG : PERSONAL_BINDING_ERROR_MSG;
        const errorMsg = _.get(errorMessages, errCode);
        const msg = _.isString(errorMsg) ? errorMsg.replace('应用', appName) : '';
        this.global.handleError(msg || error);
      })
      .finally(() => {
        this.updateStore({
          isBindLoading: false,
        });
      });
  }

  // 创建企业
  public createCompany(
    params: { companyName: string; accountType: ACCOUNT_TYPE; position: string },
    code: string,
  ) {
    this.isCreateLoading = true;
    return AuthnApi.setupCompany(params)
      .then(() => {
        this.bindSuccess(code);
      })
      .catch((error: Error) => {
        this.global.handleError(error);
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({ isCreateLoading: false });
      });
  }
}

export default BindLoginStore;

import qs from 'qs';
import { ConfigProvider } from 'tdesign';
import enConfig from 'tdesign-react/es/locale/en_US';
import zhConfig from 'tdesign-react/es/locale/zh_CN';
import BlankPage from 'common/components/BlankPage';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { VendorApi } from 'idpBase/api';
import { getCurrentLang, LangEnum } from 'idpBase/I18N';
import { convert, useStore } from 'idpBase/store';
import RouteEntry from './RouteEntry';
import PageLayout from './view/PageLayout';

const metLoadingWhiteList = [
  '#/auth',
  '#/auth/information',
  '#/auth/authorization',
  '#/app_authorization/authorize',
  '#/auth-enterprise',
  '#/auth-enterprise/progress',
  '#/auth-enterprise/information',
  '#/auth-enterprise/view',
];

const RootContainer = () => {
  const { rootStore } = useStore();
  const { isUserInfoLoading, isFetchSettingsLoading, settings } = rootStore;
  const { pathname, hash } = window.location;
  const query = JSRuntime.parseLocationSearch(undefined, {
    transferNum: false,
  });

  const appType = _.get(query, 'appType') || '';
  const cssString = m.get(settings, 'account.brands.css') || '';
  const customCssEnabled = m.get(settings, 'account.brands.customCssEnabled');

  React.useEffect(() => {
    rootStore.init({ domainName: window.location.hostname });
  }, [window.location.hostname]);

  React.useEffect(() => {
    const onPushStateChange = () => {
      rootStore.updateDataReportUrl();
    };
    window.addEventListener('pushState', onPushStateChange);
    window.addEventListener('replaceState', onPushStateChange);
    return () => {
      rootStore.resetStore();
      window.removeEventListener('pushState', onPushStateChange);
      window.removeEventListener('replaceState', onPushStateChange);
    };
  }, []);

  React.useLayoutEffect(() => {
    // NOTICE：进入idp给body添加class 'idp-theme'，进入src/idp/modules/profile文件时，去除此class，其样式和admin保持一致
    const body = document.body;
    body?.classList?.add?.('idp-theme');
  }, []);

  React.useEffect(() => {
    if (appType && appType !== rootStore.externalAppType) {
      rootStore
        .getExternalAppInfo(appType)
        .then((res) => {
          // 如果有第三方应用 /?appType=xxx, 则将其解析出的 oauth 地址作为 redirectUrl，其余部分流程与 sp 发起登录一致
          const redirectUri = _.get(res, 'redirectUri');
          if (!redirectUri) return;
          const redirectUrl = VendorApi.getAuthorizeUrl(
            qs.stringify({
              client_id: _.get(res, 'id'),
              response_type: 'code',
              redirect_uri: redirectUri,
              inaccessible_url: JSRuntime.getIdpSite('', 'app_authorization/fail'),
            }),
          );
          const nextSearch = qs.stringify({ ...query, redirectUrl });
          window.history.replaceState(
            { appType },
            document.title,
            `${pathname}?${nextSearch}${hash}`,
          );
        })
        .catch(Utils.noop);
    }
  }, [appType]);

  // 企业级登录页外观自定义-用postmessage接收样式信息
  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.from !== 'EID_ADMIN' || !event.data.brands) return;
      rootStore.setSettingsBrands(event.data.brands);
      const isFullScreen = m.get(event.data, 'isFullScreen');
      const isPc = m.get(event.data, 'isPc');
      rootStore.updateStore({
        demoParams: {
          isFullScreen: _.isBoolean(isFullScreen)
            ? isFullScreen
            : rootStore.demoParams.isFullScreen,
          isPc: _.isBoolean(isPc) ? isPc : rootStore.demoParams.isPc,
        },
      });
    });
  }, []);

  // 自定义登录样式的css代码变化，添加(替换)style标签
  React.useEffect(() => {
    Utils.resetThemeStyle(customCssEnabled ? cssString : '');
  }, [cssString, customCssEnabled]);
  const hashPath = Utils.getPureHashPath();
  const loadingContent = (
    <BlankPage
      type={metLoadingWhiteList.includes(hashPath) ? 'MET_LOADING' : 'LOADING'}
      fullHeight
    />
  );

  if (isUserInfoLoading || isFetchSettingsLoading) return loadingContent;

  const currentLang = getCurrentLang();
  const globalConfig = _.merge(currentLang === LangEnum['en-US'] ? enConfig : zhConfig, {
    form: { requiredMark: false },
  });

  return (
    <ConfigProvider globalConfig={globalConfig}>
      <PageLayout>
        <React.Suspense fallback={loadingContent}>
          <RouteEntry />
        </React.Suspense>
      </PageLayout>
    </ConfigProvider>
  );
};

export default convert(RootContainer);

import BaseApi from 'common/apiRequest/apis/BaseApi';
import { IMG_SAFE_CHECK_TYPE, UPLOAD_IMG_KEY } from 'common/constants/Constants';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { Request } from '../apiConcentrator';
import APINames from '../APINames';

class AccountApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNT);
  public personAccountInstance = new Request(APINames.ACCOUNT, {
    baseURL: `${JSRuntime.apiSite}/person/v1`,
  });

  // 获取企微绑定信息
  getWeComBindInfo(idpType: string) {
    return this.requestInstance.get(['ssoIdp', `${idpType}`]);
  }

  // 获得租户相关设置
  getTenantSettings() {
    return this.requestInstance.get('settings');
  }

  // 获取企业行业类型列表
  getIndustryTypes() {
    return this.requestInstance.get('industryTypes');
  }

  // 获取企业规模列表
  getEnterpriseSizes() {
    return this.requestInstance.get('enterpriseSizes');
  }

  getDomainConfig() {
    return this.requestInstance.get('');
  }

  /**
   * 获取上传图片token
   * @param
   * @returns
   */
  getUploadToken(params: {
    job_certify_id?: string;
    upload_resource: string;
    resource_type: string;
    safe_check_type?: IMG_SAFE_CHECK_TYPE;
  }) {
    return this.personAccountInstance.post(['personal', 'certify', 'upload_auth'], params);
  }

  /**
   * 上传图片到cos桶
   * @param
   * @returns
   */
  putImgToCos(params: { cos_url: string; cos_auth: string; img: File }) {
    const cosReq = new Request('', {
      baseURL: params.cos_url,
      headers: {
        'Content-Type': params.img.type,
        'Content-Length': params.img.size.toString(),
        Authorization: params.cos_auth,
      },
    });
    return cosReq.put('', params.img);
  }

  /**
   * 获取图片临时链接
   * @param
   * @returns
   */
  getTemporaryLink(params: {
    cos_id: string;
    job_certify_id?: string;
    upload_resource: string;
    safe_check_type?: IMG_SAFE_CHECK_TYPE;
  }) {
    return this.personAccountInstance.post(['personal', 'certify', 'download_url'], params);
  }

  /**
   * 整合图片上传流程，分三步
   * 1. 获取token
   * 2. 图片带上token上传到cos桶
   * 3. 获取图片的临时链接
   *
   * 拆三步的原因：上传cos桶因为要传文件 对后台压力很大 所以拆成三个，前端直接传cos桶就不用走后台那边了 @richardchli(李超)
   */
  async uploadImg({
    job_certify_id,
    upload_resource,
    img,
    safe_check_type,
  }: {
    job_certify_id?: string;
    upload_resource: string;
    img: File;
    safe_check_type?: IMG_SAFE_CHECK_TYPE; // 默认为 IMG_SAFE_CHECK_TYPE.PERSON 个人认证审核
  }) {
    const resourceType = `${img.name.match(/\.[a-zA-Z]+$/)?.[0]}`;
    if (!UPLOAD_IMG_KEY.has(upload_resource)) {
      const err = '错误的form name 上传图片需要唯一的form name';
      console.error(err);
      return Promise.reject(err);
    }
    const [tokenErr, tokenRes] = await Utils.resolvePromise(
      this.getUploadToken({
        job_certify_id,
        upload_resource,
        resource_type: resourceType,
        safe_check_type,
      }),
    );

    if (tokenErr) {
      return Promise.reject(tokenErr);
    }

    const { cos_url: cosUrl, put_auth: putAuth, cos_id: cosId } = tokenRes;
    const [cosErr] = await Utils.resolvePromise(
      this.putImgToCos({
        cos_url: cosUrl,
        cos_auth: putAuth,
        img,
      }),
    );
    if (cosErr) {
      return Promise.reject(cosErr);
    }
    const [linkErr, linkRes] = await Utils.resolvePromise(
      this.getTemporaryLink({
        cos_id: cosId,
        job_certify_id,
        upload_resource,
        safe_check_type,
      }),
    );
    if (linkErr) {
      return Promise.reject(linkErr);
    }
    return {
      url: m.get(linkRes, 'download_url'),
      cos_id: cosId,
    };
  }
}
export default new AccountApi();

import { TEMPLATES } from 'common/constants/Constants';
import { IDENTITY_TYPE } from 'common/constants/Types';

export const SET_PASSWORD_TIPS = {
  [IDENTITY_TYPE.PASSWORD]: {
    PASSWORD_EXPIRED: '您的登录密码已过期，请您重新设置',
    POLICY_UPGRADE: '管理员更新了密码策略，请您重新设置密码',
    INITIATIVE_CHANGE: '您刚刚输入的密码已失效，为了您后续的正常使用，请设置密码',
    default: '您刚刚输入的密码已失效，为了您后续的正常使用，请设置密码',
  },
};

export const DEFAULT_TEMPLATE_SETTING_STYLE = {
  [TEMPLATES.DOC]: {
    backgroundColor: '#fff',
    justifyContent: 'flex-end',
  },
  [TEMPLATES.SAAS]: {
    backgroundColor: 'rgba($td-color-primary-2, 0.5)',
    justifyContent: 'center',
  },
};

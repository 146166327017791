import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'common/api';
import { AuthnApi as BaseAuthnApi, AdminVendorApi, AccountApi } from 'idpBase/api';
import { TUpdateStore, TGlobalStore } from 'idpBase/store';
import { ACCOUNT_TYPE } from 'common/constants/Constants';
import { STEP_MODE } from './constants/Constants';

class AppAuthorizationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<AppAuthorizationStore>;
  public resetStore: () => void;

  public step = STEP_MODE.AUTH;

  public isCreateLoading = false;

  public isAccessLoading = true;

  public clientName = '';
  public logoUri = '';

  // 登出
  public logout() {
    const query = JSRuntime.parseLocationSearch(undefined, {
      transferNum: false,
    });
    const rootStore = this.global.rootStore;
    const redirectUrl = _.get(query, 'appType')
      ? rootStore.getIDPRedirectUrl(window.location.search)
      : '';
    return AuthnApi.logout(
      redirectUrl || JSRuntime.thirdLandingPage || rootStore.getIDPRedirectUrl(),
    )
      .then(() => {
        this.global.rootStore.updateStore({ userInfo: null });
      })
      .catch(Utils.noop);
  }

  // 创建
  public createCompany(params: { companyName: string; accountType: ACCOUNT_TYPE }) {
    this.isCreateLoading = true;
    return BaseAuthnApi.setupCompany(params)
      .catch((error: Error) => {
        this.global.handleError(error);
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({ isCreateLoading: false });
      });
  }

  // 判断是否有该服务商应用的权限
  public checkAccess(appId: string) {
    return AdminVendorApi.checkAccessibility(appId);
  }
  // 获取app信息
  public getAppMeta(clientId: string) {
    const accountId = this.global.rootStore.userInfo?.account?.accountId || '';
    return AccountApi.getAppMeta(accountId, clientId)
      .then((res) => {
        this.updateStore({
          clientName: _.get(res, 'client_name'),
          logoUri: _.get(res, 'logo_uri'),
        });
      })
      .catch(Utils.noop);
  }

  public checkAuthorization(params: { redirect_url: string; consent: boolean }): void {
    const accountId = this.global.rootStore.userInfo?.account?.accountId || '';
    return AccountApi.checkAuthorization(accountId, params);
  }
}

export default AppAuthorizationStore;

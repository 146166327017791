import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/Admin';

class VendorApi extends BaseApi {
  public requestInstance = new Request(APINames.VENDOR);

  // 获取当前登录人员是否有权限访问服务商应用
  checkAccessibility(appId: string) {
    return this.requestInstance.get(['apps', appId, 'accessibility:check']);
  }
}
export default new VendorApi();

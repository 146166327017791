import { useStore, convert } from 'idpBase/store';
import { CONTENT } from '../constants/Constants';
import JSRuntime from 'common/utils/JSRuntime';
import Utils from 'common/utils';
import AccountLogo from 'common/components/AccountLogo';
import InvitationContent from './InvitationContent';
import ResultPage from './ResultPage';
import VerifyMobilePage from './VerifyMobilePage';
import InvitationScss from '../style/Invitation.module.scss';

const Invitation = () => {
  const { invitationStore } = useStore();
  const { currentContent } = invitationStore;
  const isMobileDevice = JSRuntime.isMobileDevice;

  const onLogoClick = () => {
    const officialSite = JSRuntime.officialSite;
    window.open(officialSite, '_blank');
  };

  const renderContent = () => {
    switch (currentContent) {
      case CONTENT.INVITING:
        return <InvitationContent />;

      case CONTENT.MOBILE_VERIFY:
        return <VerifyMobilePage />;

      case CONTENT.RESULT_PAGE:
        return <ResultPage />;

      default:
        return null;
    }
  };

  return (
    <div
      className={Utils.uniteClass(
        InvitationScss.invitationPage,
        isMobileDevice ? InvitationScss.invitationPageMobile : '',
      )}
    >
      <AccountLogo logoUrl="" companyName="" clickFn={onLogoClick} />
      <div className={InvitationScss.invitationWrapper}>{renderContent()}</div>
    </div>
  );
};

export default convert(Invitation);

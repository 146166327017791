interface IProps {
  href: string;
  children: React.ReactNode;
}

const ExternalLink: React.FC<IProps> = (props: IProps) => {
  const { href, children } = props;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
      {children}
    </a>
  );
};

export default React.memo(ExternalLink);

import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/Admin';

class AdminUserApi extends BaseApi {
  public requestInstance = new Request(APINames.USER);

  // 退出企业
  writeOffTenant(accountId: string) {
    return this.requestInstance.post(['account', accountId, ':signOff']);
  }
}
export default new AdminUserApi();

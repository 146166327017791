import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator/User';
import APINames from '../APINames';

class UserPersonalAccountApi extends BaseApi {
  public requestInstance = new Request(APINames.PERSONAL_ACCOUNT);

  // 删除个人版
  delPersonalAccount(params: { companyName: string }) {
    return this.requestInstance.del([], params);
  }
}
export default new UserPersonalAccountApi();

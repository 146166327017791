import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator/Admin';
import APINames from '../APINames';

class AuthnApi extends BaseApi {
  public requestInstance = new Request(APINames.PWD_MATCHED_POLICY);

  checkExpired() {
    return this.requestInstance.get('', { policyType: 'expired' });
  }
}
export default new AuthnApi();

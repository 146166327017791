import {
  VERIFY_CODE_SCENE,
  DEFAULT_COUNTDOWN_SECOND,
  MOBILE_SAFE_ERR_CODE,
  CAPTCHA_ERROR,
  CREDENTIAL_ERROR,
} from 'common/constants/Constants';
import RequestPromise from 'common/apiRequest/Request/RequestPromise';
import AliCaptcha from 'common/components/AliCaptcha';
import JSRuntime from 'common/utils/JSRuntime';
import { TUpdateStore, TGlobalStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';
import { PASSWORD_LOGIN_TYPE } from 'idpBase/Constants';
import { IAccountResult, LoginValues } from '../../constants/Types';
import {
  LOGIN_ERROR_MSG,
  MOBILE_LOGIN_STATUS,
  PWD_SET_TYPE,
  SMS_LOGIN_ERROR_MSG,
  PWD_LOGIN_ERROR_MSG,
} from '../../constants/Constants';

class LoginMobileStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<LoginMobileStore>;
  public resetStore: () => void;

  public mobileLoginMethod = MOBILE_LOGIN_STATUS.VERIFY_CODE_LOGIN; // NOTICE 这里只有验证码和密码
  public mobileLoginStatus = MOBILE_LOGIN_STATUS.INPUT_MOBILE;
  public loginErrMsg = '';
  public forgetPwdErrMsg = '';
  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;
  public isLoginLoading = false;
  public isForgetPwdSendLoading = false;
  public isInputMobileSendLoading = false; // NOTICE 输入手机号页获取验证码接口loading不要与手机验证页的loading共用字段，会有渲染时机的问题
  public isForgetPwdVerifyLoading = false;
  public isSetPwdLoading = false;
  public hasSetPassword = false;

  public loginApi: RequestPromise<any> | undefined = undefined;
  public captcha: AliCaptcha | TencentCaptcha | null = null;

  public getVerifyCode(event: VERIFY_CODE_SCENE) {
    const curMobile = this.global.login.loginBaseStore.mobile;
    if (!curMobile) return Promise.reject();
    return AuthnApi.sendVerificationCode({ mobile: curMobile, event });
  }

  // 滑块验证后登录需要带verify
  public async login(values: LoginValues) {
    this.isLoginLoading = true;
    const isVerifyCodeLogin = this.mobileLoginStatus === MOBILE_LOGIN_STATUS.VERIFY_CODE_LOGIN;

    if (this.loginApi) this.loginApi.abort?.();

    if (isVerifyCodeLogin) {
      this.loginApi = AuthnApi.loginByCode({
        verificationCode: _.get(values, 'verificationCode'),
        verificationSessionId: _.get(values, 'verificationSessionId'),
        app: JSRuntime.backEndApp,
      }) as RequestPromise<any>;
    } else {
      const credential = await this.global.rootStore.encryptRequest({
        subject: _.get(values, 'mobile'),
        password: _.get(values, 'password'),
      });
      this.loginApi = credential
        ? (AuthnApi.loginByPwd({
            credential,
            loginType: PASSWORD_LOGIN_TYPE.MOBILE,
            app: JSRuntime.backEndApp,
          }) as RequestPromise<any>)
        : (Promise.reject({ data: { errCode: CREDENTIAL_ERROR } }) as RequestPromise<any>);
    }

    this.loginApi
      ?.then((res: IAccountResult) => {
        this.updateStore({
          isLoginLoading: false,
        });

        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: isVerifyCodeLogin ? 'phone' : 'password',
          login_result: 'success',
        });
        this.global.login.loginBaseStore.dealLoginSuccess(res, _.get(values, 'mobile'));

        return Promise.resolve();
      })
      .catch((error) => {
        const errCode = _.get(error, 'data.errCode');

        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: isVerifyCodeLogin ? 'phone' : 'password',
          login_result: 'failed',
          login_error_code: errCode,
        });
        mobx.runInAction(() => {
          this.isLoginLoading = false;

          if (errCode === CAPTCHA_ERROR) {
            this.global.handleError(error);
            return;
          }

          const errorMap = Object.assign(
            LOGIN_ERROR_MSG,
            isVerifyCodeLogin ? SMS_LOGIN_ERROR_MSG : PWD_LOGIN_ERROR_MSG,
          );

          const loginErrMsg = errorMap[errCode] || '登录失败，请稍后重试';

          if (this.mobileLoginStatus === MOBILE_LOGIN_STATUS.VERIFY_CODE_LOGIN) {
            this.updateStore({
              loginErrMsg,
            });
            if (errCode === MOBILE_SAFE_ERR_CODE) {
              // 手机号触发风控，提示报错
              this.global.handleError(error);
            }
          } else {
            this.global.handleError(loginErrMsg);
          }
        });
      });
  }

  public forgetPwdVerifyCode(data: { verificationCode: string; verificationSessionId: string }) {
    this.isForgetPwdVerifyLoading = true;
    return AuthnApi.verifyCode({ ...data, event: VERIFY_CODE_SCENE.FORGET_PASSWORD })
      .then(() => AuthnApi.checkPwdResetType())
      .then((res) => {
        this.updateStore({
          mobileLoginStatus: MOBILE_LOGIN_STATUS.FORGET_PASSWORD_NEW,
          isForgetPwdVerifyLoading: false,
          hasSetPassword: m.get(res, 'type') === PWD_SET_TYPE.RESET,
        });
      })
      .catch((err) => {
        this.updateStore({ isForgetPwdVerifyLoading: false });
        return Promise.reject(err);
      });
  }

  public async setOrResetPassword(password: string) {
    this.isSetPwdLoading = true;
    const credential = await this.global.rootStore.encryptRequest({ password });
    // 外部处理 catch 逻辑
    return (
      credential
        ? AuthnApi.resetPassword(credential)
        : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } })
    ).finally(() => {
      this.updateStore({ isSetPwdLoading: false });
    });
  }
}

export default LoginMobileStore;

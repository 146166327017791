import { DEFAULT_COUNTDOWN_SECOND, VERIFY_CODE_SCENE } from 'common/constants/Constants';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/api';
import type { IQRCodeStatus } from 'common/compositions/WeMeetQrCode';
import { ScanClientType } from 'common/compositions/WeMeetQrCode';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { LOGIN_RES_TYPE } from '../../../constants/Constants';
import {
  LOGIN_ERROR_MSG,
  MEET_BIND_WECHAT_LOGIN_ACTION,
  MEET_QRCODE_LOGIN_STEP,
} from '../../constants/Constants';

class MeetingQrCodeStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<MeetingQrCodeStore>;
  public resetStore: () => void;

  public step = MEET_QRCODE_LOGIN_STEP.SCAN;
  public mobile = '';
  public sendVerifyCodeLoading = false;
  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;
  public loginErrMsg = '';
  public isBindMobileLoading = false;

  public loginByWeMeetQrCode(data: IQRCodeStatus) {
    const scanType = data.account_client_type;
    return AuthnApi.weMeetQrCodeLogin(data)
      .then((res) => {
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: scanType === ScanClientType.wechat ? 'wechat' : 'qr_code',
          login_result: 'success',
        });
        const successType = _.get(res, 'next.type');

        // 需要手机号绑定的进入下一个 step
        if (successType === LOGIN_RES_TYPE.MOBILE_BIND) {
          this.updateStore({ step: MEET_QRCODE_LOGIN_STEP.BIND_MOBILE });
        } else {
          // 会议扫码登录不走 invitation 流程，不需要传 mobile
          this.global.login.loginBaseStore.dealLoginSuccess(res as any);
        }
      })
      .catch((err) => {
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: scanType === ScanClientType.wechat ? 'wechat' : 'qr_code',
          login_result: 'failed',
          login_error_code: _.get(err, 'data.errCode'),
        });
        this.global.handleError(err);
      });
  }

  public getVerifyCode(bindMobile: string) {
    return AuthnApi.sendVerificationCode({
      mobile: bindMobile,
      event: VERIFY_CODE_SCENE.BIND_MOBILE,
    });
  }

  public bindMobile(code: string) {
    return AuthnApi.bindWeChatToMobile({
      verificationCode: code,
      verificationSessionId: this.verificationSessionId,
      ...(JSRuntime.isTencentMeeting ? { app: 'meeting' } : {}),
    })
      .then((res) => {
        const successType = _.get(res, 'next.type');

        // MOBILE_REGISTED， MOBILE_USED 交给弹窗处理
        if (![LOGIN_RES_TYPE.MOBILE_REGISTED, LOGIN_RES_TYPE.MOBILE_USED].includes(successType)) {
          // 会议扫码登录不走 invitation 流程，不需要传 mobile
          this.global.login.loginBaseStore.dealLoginSuccess(res as any);
          return;
        }

        return res;
      })
      .catch((err) => {
        const loginErrMsg = LOGIN_ERROR_MSG[_.get(err, 'data.errCode')];
        this.updateStore({ loginErrMsg: loginErrMsg || '验证失败，请稍后重试' });
      });
  }

  public loginAfterBindWechat(action: MEET_BIND_WECHAT_LOGIN_ACTION) {
    return AuthnApi.loginAfterBindWechat(action)
      .then((res) => {
        // 会议扫码登录不走 invitation 流程，不需要传 mobile
        this.global.login.loginBaseStore.dealLoginSuccess(res as any);
      })
      .catch((err) => {
        this.global.handleError(err);
        return Promise.reject(err);
      });
  }
}

export default MeetingQrCodeStore;

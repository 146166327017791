import Utils from 'common/utils';
import PageHeadScss from './PageHead.module.scss';
interface IProps {
  renderRight?: () => React.ReactNode;
}
const PageHead = (props: IProps) => (
  <header className={Utils.uniteClass(PageHeadScss.headContainer, 'page-head')}>
    <img src={Utils.renderBrand()} width={150} />
    <div />
    {props.renderRight?.()}
  </header>
);

export default React.memo(PageHead);

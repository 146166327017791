export enum CONTENT {
  INVITING = 'INVITING', // 邀请主要内容
  MOBILE_VERIFY = 'MOBILE_VERIFY', // 移动端手机号和验证码输入页 | 邮箱设置密码
  RESULT_PAGE = 'RESULT_PAGE', // 邀请链接失效、加入成功、已拒绝、已在该企业
}

export const BTN_TEXT = {
  meeting: '开始使用腾讯会议',
  doc: '开始使用腾讯文档',
};

export enum INVITE_FROM {
  MOBILE = 'Mobile',
  EMAIL = 'Email',
}

export const VERIFY_CODE_ERROR = {
  E0010028: '验证码不正确，请重新输入',
};

export enum RESULT_TYPES {
  REFUSED = 'REFUSED', // 已拒绝
  JOIN_SUCCESS = 'JOIN_SUCCESS', // 加入成功
  HAS_JOINED = 'HAS_JOINED', // 已经在企业中了，无需加入
  EXPIRED = 'EXPIRED',
}

export const ACCEPTATION_ERROR_CODE = new Map([
  ['E0010208', { resultType: RESULT_TYPES.HAS_JOINED, error: '您已经在该企业中，无需重复加入' }],

  // 邮箱 报错
  ['E0010354', { resultType: RESULT_TYPES.HAS_JOINED, error: '您已经在该企业中，无需重复加入' }],
]);

export const EMAIL_SET_PWD_ERROR_CODE = 'E0010353';

export const EXPIRE_CODE = new Map([
  ['E0010002', '页面未找到'],
  ['E0010035', '您在企业中状态不可用，链接已失效'],
  ['E0010357', '您在企业中已被激活，若需绑定邮箱，请前往登录后在个人中心操作'],
]);

export const enum LOGIN_RES_TYPE {
  EMAIL_PWD_SET = 'EMAIL_PWD_SET', // 去设置邮箱密码
}

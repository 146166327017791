import Editor from 'common/components/Editor';
import { useSearchParams } from 'react-router-dom';
import Utils from 'common/utils';
import qs from 'qs';
import { RESULT, RESULT_TYPE, IDENTITY_MAP } from './Constants';
import TestResultScss from './SSOTestResult.module.scss';

const TestResult = () => {
  const [saerchParams] = useSearchParams();
  const queryObj = qs.parse(_.trimStart(window.location.search || '', '?'));
  const resultType = m.get(queryObj, 'resultType');
  const errorMsg = m.get(queryObj, 'errorMsg');
  const userProfiles = m.get(queryObj, 'userProfiles');
  const errorMsgObj = errorMsg ? JSON.parse(window.decodeURIComponent(window.atob(errorMsg))) : '';
  const identityName = saerchParams.get('identityName') || '';
  const name = IDENTITY_MAP[identityName]?.name || '认证源';

  const userProfilesObj = _.isString(userProfiles)
    ? JSON.parse(
        identityName && _.has(IDENTITY_MAP, identityName)
          ? window
              .decodeURIComponent(window.atob(userProfiles))
              .replace('idpUserprofile', IDENTITY_MAP[identityName]?.profileName)
              .replace('eidUserprofile', 'OneID_Userprofile')
          : window.decodeURIComponent(window.atob(userProfiles)),
      )
    : '';
  const onLinkClick = () => {
    if (identityName && _.has(IDENTITY_MAP, identityName)) {
      Utils.safeRedirect(IDENTITY_MAP[identityName]?.courseLink);
    }
  };
  let desc;
  if (resultType === RESULT_TYPE.TEST_AUTH_FAIL) {
    desc = (
      <>
        检测您的配置有误，请根据
        <a target="_blank" onClick={onLinkClick}>
          配置教程
        </a>
        检查您的配置再试。
      </>
    );
  } else {
    desc = m.get(RESULT, `${resultType}.desc`).replace('{identityName}', name);
  }
  const title = m.get(RESULT, `${resultType}.title`).replace('{identityName}', name);
  return (
    <div className={TestResultScss.wrapper}>
      <div className={TestResultScss.resultTitle}>{title}</div>
      <div className={Utils.uniteClass('eid-desc', TestResultScss.resultTips)}>
        {desc}
        以下为获取到的具体人员信息：
      </div>
      <Editor
        language="json"
        height={400}
        value={`${errorMsgObj ? JSON.stringify(errorMsgObj, null, 2) : ''}\n${
          userProfilesObj ? JSON.stringify(userProfilesObj, null, 2) : ''
        }`}
        width={640}
        options={{
          readOnly: true,
          domReadOnly: true,
          accessibilitySupport: 'off',
          copyWithSyntaxHighlighting: false,
          hideCursorInOverviewRuler: true,
          suggestOnTriggerCharacters: false,
          minimap: {
            enabled: false,
          },
        }}
      />
    </div>
  );
};

export default React.memo(TestResult);

/**
 * 顶部 logo 展示部分
 * */
import UserAvatar from 'common/components/UserAvatar';
import Utils from 'common/utils';
import AccountLogoScss from './AccountLogo.module.scss';

interface IProps {
  logoUrl: string | undefined;
  companyName: string | undefined;
  clickFn?: () => void;
  className?: string;
}

const AccountLogo: React.FC<IProps> = (props: IProps) => {
  const { logoUrl, companyName, className, clickFn } = props;

  return (
    <div className={Utils.uniteClass(AccountLogoScss.logoWrapper, className)}>
      <div
        className={Utils.uniteClass(
          AccountLogoScss.logoInner,
          clickFn ? undefined : AccountLogoScss.defaultCurSor,
        )}
        onClick={clickFn}
      >
        <UserAvatar
          logoUrl={logoUrl || Utils.renderLogo()}
          defaultLogoUrl={Utils.renderLogo()}
          displayName={companyName}
          className={AccountLogoScss.logo}
        />
        <h2
          className={Utils.uniteClass(
            AccountLogoScss.accountName,
            Utils.computedOverflowLabel(companyName, 14).hasOverflow ? AccountLogoScss.small : '',
            Utils.computedOverflowLabel(companyName, 21).hasOverflow ? AccountLogoScss.mini : '',
          )}
        >
          {companyName || '腾讯统一身份'}
        </h2>
      </div>
    </div>
  );
};

export default React.memo(AccountLogo);

/**
 * eid-authorize-sdk.js SDK 选择 popup 登录后的与主窗口的通信页面
 * */
import qs from 'qs';
import Utils from 'common/utils';
import message from 'common/components/Message';

const SDKTransfer = () => {
  const queryObj = qs.parse(_.trimStart(window.location.search || '', '?'));
  const code = m.get(queryObj, 'code');

  React.useEffect(() => {
    // PC 端父窗口消失
    if (!window.opener) {
      message.error('认证失败', 24 * 60 * 60 * 1000);
      console.error('未找到父级窗口');
      return Utils.noop; // 退出页面关闭
    }

    // SDK会打开一个窗口，这里通过 postMessage 来根主窗口通信

    window.opener.postMessage(
      code
        ? {
            type: 'ONEID_POPUP_AUTH_SUCCESS',
            data: queryObj, // code \ state
          }
        : {
            type: 'ONEID_POPUP_AUTH_FAIL',
            data: queryObj, // error \ errorMessage
          },
      '*',
    );
    return Utils.noop;
  }, []);

  return null;
};

export default React.memo(SDKTransfer);

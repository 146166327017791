import { EMAIL_WHITELIST_ERR } from 'common/constants/Constants';
import { BLOCKED_ERROR_CODE, BLOCKED_ERROR_MSG } from 'idpBase/Constants';

export const CHOOSE_ACCOUNT_ERROR_MSG = {
  E0010035: '您在当前企业已被停用，请联系管理员', // 在该企业中为停用状态
  [BLOCKED_ERROR_CODE]: BLOCKED_ERROR_MSG[BLOCKED_ERROR_CODE],
  [EMAIL_WHITELIST_ERR.code]: EMAIL_WHITELIST_ERR.msg,
};

export enum ACCOUNT_TYPE {
  ACCOUNT,
  UNAVAILABLE,
}

import { MouseEvent } from 'react';
import { Input, InputProps, InputRef } from 'tdesign';
import Icon from 'common/components/Icon';
import publicStyles from 'common/styles/eid-variables-in-js.module.scss';
import pwdScss from './PasswordInput.module.scss';

const PasswordInput: React.FC<InputProps> = (props: InputProps) => {
  const { value, onChange, onClear, onFocus, onBlur, clearable = true, ...restProps } = props;
  const [delIconVisible, setDelIconVisible] = React.useState(false);
  const [pwdVisible, setPwdVisible] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const ref = React.useRef<InputRef>(null);
  const onValChange = (val: string) => {
    setDelIconVisible(!!val);
    onChange?.(val);
  };

  return (
    <Input
      ref={ref}
      value={value}
      type={pwdVisible ? 'text' : 'password'}
      onChange={onValChange}
      onFocus={(...args) => {
        setFocused(true);
        onFocus?.(...args);
      }}
      onBlur={(...args) => {
        setFocused(false);
        onBlur?.(...args);
      }}
      suffixIcon={
        <>
          {delIconVisible && focused && clearable ? (
            <Icon
              className={pwdScss.delIcon}
              size={24}
              type="TxFilled"
              pointer
              color={publicStyles.tdFontColorSubDesc}
              // 鼠标按下事件优先级高于onBlur会先执行
              onMouseDown={(e) => {
                onValChange('');
                onClear?.({ e: e as MouseEvent<SVGElement, globalThis.MouseEvent> });
                setTimeout(() => {
                  ref.current?.focus?.();
                }, 0);
              }}
            />
          ) : null}

          <Icon
            size={24}
            color={pwdVisible ? publicStyles.tdFontColorLink : publicStyles.tdFontColorSubDesc}
            onClick={() => setPwdVisible(!pwdVisible)}
            type={pwdVisible ? 'TxBrowse' : 'TxBrowseOff'}
          />
        </>
      }
      {...restProps}
    />
  );
};

export default PasswordInput;

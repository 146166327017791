import { randomString, getCookie, getRequest, isWeMeetApp } from '@tencent/web-base';
import { getCurrentLang } from 'common/utils/I18N';

// 转成 公共参数格式
export const getCLang = (currentLang: string) => {
  // 其他语言的 c_lang 字段值注意与后端同学沟通
  const cLangMap = {
    en: 'en-us',
    'en-US': 'en-us',
    'zh-CN': 'zh-cn',
    'zh-TW': 'zh-tc',
  };
  return cLangMap[currentLang] || 'en-us';
};

/**
 * 腾讯会议侧接口公共参数拼接，逻辑按照会议侧的实现
 * */
export function addQueryToRequest(): object {
  const cToken = getCookie('ticket') || getCookie('we_meet_token') || getRequest?.('token') || '';
  return {
    c_os: 'web', // 系统
    c_os_version: encodeURIComponent(navigator?.userAgent), // 系统版本号
    c_os_model: 'web', // 系统类型
    c_timestamp: +new Date(), // 请求发起的时间戳（秒）
    c_instance_id: 5, // 终端类型, web是 5
    c_nonce: randomString(), // 随机字符
    // 用户uid【登录后相关请求需要携带】EID 这边基本上结果都是 false
    c_app_uid: getCookie('app_uid') || getRequest('app_uid', window?.location.href),
    // 用户uid【登录后相关请求需要携带】EID 这边基本上结果都是 false
    c_app_id: getCookie('app_id') || getRequest('app_id', window?.location.href),
    c_app_version: '', // 腾讯会议终端版本号
    c_lang: getCLang(getCurrentLang()),
    c_district: 0, // IS_I18N ? 1 : 0,
    c_platform: isWeMeetApp(navigator?.userAgent) ? '5' : '0', // 本地开发时，使用0，端上用5
    c_token: cToken,
  };
}

export function uuid(): string {
  return 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

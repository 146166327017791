/*
 * 创建 ApiConcentrator 以及 Request
 * */
import ApiCreator, { RequestConfig } from 'common/apiRequest';
import JSRuntime from 'common/utils/JSRuntime';
import ResponseInterceptors from '../Interceptors';

// 获得当前 API baseURL，baseURL 有当前 host 与 path 拼成
const baseURL = `${JSRuntime.apiSite}/admin/v1`;

// 通过当前模块的 baseURL 以及请求拦截函数创建 API
const Api = ApiCreator(baseURL, () => ResponseInterceptors);

// 提供 services 中使用的 Request
export const Request = Api.Request;

export type TRequestConfig = RequestConfig;

export default Api;

import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator/adminV2';
import APINames from '../APINames';

class UsersApi extends BaseApi {
  public requestInstance = new Request(APINames.USERS);

  // 修改个人版名称
  updatePersonalName(params: { name: string }) {
    return this.requestInstance.patch(['selfUserName'], params);
  }

  // 获取图片审核状态
  getSafeCheckStatus(params: { img_url: string }) {
    return this.requestInstance.post(['imgs', 'certify', 'get_safe_check_status'], params);
  }
}

export default new UsersApi();

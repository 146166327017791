import { AccountApi as CommonAccountApi } from 'common/api';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { AccountApi } from 'idpBase/api';
import { AUTH_TYPE } from 'idpBase/Constants';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { FACE_AUTH_TYPE } from './constants/Constants';
import { AUTH_STATUS } from '../constants/Constants';

const FACE_POLLING_SECOND = 1000;

class InformationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<InformationStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public authInfo = {};

  public isFaceAuthLoading = false;
  public faceAuthInfo = {};

  public isSmsCodeLoading = false;
  public codeInfo = {};

  public isSaveLoading = false;

  public pollingCheckTimer: NodeJS.Timeout | null = null;
  public isStopPolling = false;

  public faceCheckInfo = {};
  // 是否要跳转到结果页
  public get shouldGotoResult() {
    const status = m.get(this.authInfo, 'status');
    return status !== AUTH_STATUS.INIT;
  }

  /**
   * 获取认证信息
   */
  public getAuthInfo(authType: AUTH_TYPE) {
    return AccountApi.getAuthInfo(authType)
      .then((res) => {
        // 防止二维码轮询状态时一直重复渲染
        if (!_.isEqual(res, this.authInfo)) {
          this.updateStore({
            authInfo: res,
          });
        }
        return Promise.resolve(res);
      })
      .catch((error: Error) => {
        this.global.handleError(error);
        this.updateStore({
          isBlockLoading: false,
        });
        return Promise.reject(error);
      });
  }

  /**
   * 获取人脸识别token和url信息
   * @param faceType 0 人脸识别，1人脸核身
   * @returns {Promise}
   */
  public getFaceAuthInfo(faceType: FACE_AUTH_TYPE) {
    this.updateStore({
      isFaceAuthLoading: true,
    });

    const userInfo = this.global?.rootStore?.userInfo;

    return AccountApi.getFaceAuthInfo({
      redirect_url: `${JSRuntime.originalIdpSite}/oneid-auth/face-result`,
      business_type: faceType,
      access_source: 'web',
      job_certify_id: m.get(this.authInfo, 'job_certify_id'),
      account_id: m.get(userInfo, 'account.accountId'),
    })
      .then((res) => {
        this.updateStore({
          faceAuthInfo: res,
        });
      })
      .catch((error: Error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isFaceAuthLoading: false,
        });
      });
  }

  /**
   * 发送手机验证码
   * @param phone 手机号
   * @param event 验证码对应的事件
   * @returns {Promise}
   */
  public getSmsCode(phone?: string, event?: string) {
    this.isSmsCodeLoading = true;
    return AccountApi.sendSmsCode({ phone, event })
      .then((res) => {
        this.updateStore({
          codeInfo: res,
        });
      })
      .catch((error: Error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isSmsCodeLoading: false,
        });
      });
  }

  /**
   * 保存验证信息
   * @param params
   * @returns
   */
  public save(params: Record<string, any>) {
    this.isSaveLoading = true;
    return AccountApi.saveAuthInfo(params)
      .then((res) => Promise.resolve(res))
      .catch((error: Error) => {
        this.global.handleError(_.get(error, 'data.errMessage'));
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({
          isSaveLoading: false,
        });
      });
  }

  /**
   * 图片上传，分三步
   * 1. 获取token
   * 2. 图片带上token上传到cos桶
   * 3. 获取图片的临时链接
   */
  public async uploadImg({
    job_certify_id,
    upload_resource,
    img,
  }: {
    job_certify_id: string;
    upload_resource: string;
    img: File;
  }) {
    return CommonAccountApi.uploadImg({
      job_certify_id,
      upload_resource,
      img,
    });
  }

  public async getSafeCheckUrl(params: { url: string }) {
    const [err, res] = await Utils.resolvePromise(this.sendForLogoSafe(params));
    if (err) {
      this.global.handleError(err);
      return;
    }
    return m.get(res, 'url');
  }

  public sendForLogoSafe(params: { url: string }) {
    return AccountApi.sendForLogoSafe(params);
  }

  /**
   * 清除二维码状态检测轮询
   */
  public clearFaceStatusCheck() {
    if (this.pollingCheckTimer) clearTimeout(this.pollingCheckTimer as any);
    this.pollingCheckTimer = null;
    this.faceCheckInfo = {};
    this.isStopPolling = true;
  }

  /**
   * 扫码实名后，获取认证成功状态
   */

  public pollingFaceCheckStatus(
    businessType: FACE_AUTH_TYPE,
    options?: { onFail?: () => void; onFinish?: () => void },
  ) {
    return AccountApi.getFaceCheckResult({
      biz_token: m.get(this.faceAuthInfo, 'biz_token'),
      business_type: businessType,
    })
      .then((res) => {
        const isCertify = m.get(res, 'is_certify');
        const isFaceAuth = m.get(res, 'is_face_auth');
        const authSuccess = businessType === FACE_AUTH_TYPE.FACE_AUTH ? isCertify : isFaceAuth;
        if (this.pollingCheckTimer) clearTimeout(this.pollingCheckTimer);
        if (!authSuccess && !this.isStopPolling) {
          this.pollingCheckTimer = setTimeout(() => {
            this.pollingFaceCheckStatus(businessType, options);
          }, FACE_POLLING_SECOND);
        } else {
          this.updateStore({
            faceCheckInfo: res,
          });
          if (_.isFunction(options?.onFinish)) options?.onFinish();
        }
      })
      .catch(() => {
        if (this.pollingCheckTimer) clearTimeout(this.pollingCheckTimer);
        this.pollingCheckTimer = setTimeout(() => {
          this.pollingFaceCheckStatus(businessType, options);
        }, FACE_POLLING_SECOND);
      });
  }
}
export default InformationStore;

export default {
  accessNotGranted: 'Access not granted',
  noAccess: 'No access',
  inaccessible: 'Inaccessible',
  invalidOperation: 'Invalid operation',
  connectionTimedOut: 'Connection timed out',
  serverDifferent: 'Server exception',
  serverNo: 'The server is not responding',
  siteIs: 'The site is under maintenance',
  serverRoom: 'Exceptional connection between servers',
  legalitySchool: 'Failed to verify the validity of the API',
  universal: 'General',
  networkNotConnected: 'No network connection',
  unknownMistake: 'Unknown error. Please try again later',
};

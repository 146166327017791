/**
 * 后端鉴权后返回页
 * */
import Utils from 'common/utils';
import _ from 'lodash';
import qs from 'qs';
import validator from 'validator';

const DingTalkTransfer = () => {
  React.useEffect(() => {
    const queryObj = qs.parse(_.trimStart(window.location.search || '', '?'));
    const authCode = m.get(queryObj, 'authCode');
    const state = m.get(queryObj, 'state');

    const result = Utils.safeParseJSON(window.atob(_.isString(state) ? state : ''));
    const cb = _.get(result, 'cb');
    if (validator.isURL(cb)) {
      Utils.safeRedirect(`${cb}?${qs.stringify({ authCode, state })}`);
      return;
    }
    return Utils.noop;
  }, []);

  return null;
};

export default React.memo(DingTalkTransfer);

import { DEFAULT_COUNTDOWN_SECOND, VERIFY_CODE_SCENE } from 'common/constants/Constants';
import { AuthnApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { STEP, PWD_SET_TYPE } from './constants/Constants';

class ForgetPwdStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ForgetPwdStore>;
  public resetStore: () => void;

  public currentStep: STEP = STEP.VERIFY_MOBILE;
  public isVerifyLoading = false;
  public isSubmitLoading = false;
  public verifyErrorMessage = '';
  public mobile = '';

  public isSendLoading = false;
  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;

  // 验证 手机号与验证码是否匹配
  public verifyMobile(data: { verificationCode: string; verificationSessionId: string }) {
    this.isVerifyLoading = true;

    return AuthnApi.verifyCode({ ...data, event: VERIFY_CODE_SCENE.FORGET_PASSWORD })
      .then(() => AuthnApi.checkPwdResetType())
      .then((res) => {
        const hasSetPassword = m.get(res, 'type') === PWD_SET_TYPE.RESET;
        this.updateStore({
          isVerifyLoading: false,
          currentStep: hasSetPassword ? STEP.RESET_PWD : STEP.SET_PWD,
        });
      })
      .catch((err) => {
        this.updateStore({ isVerifyLoading: false });
        return Promise.reject(err);
      });
  }

  public setOrResetPassword(password: string) {
    this.isSubmitLoading = true;
    // 外部处理 catch 逻辑
    return AuthnApi.resetPassword(password).finally(() => {
      this.updateStore({ isSubmitLoading: false });
    });
  }

  public getVerifyCode(mobile: string) {
    return AuthnApi.sendVerificationCode({ mobile, event: VERIFY_CODE_SCENE.FORGET_PASSWORD });
  }
}

export default ForgetPwdStore;

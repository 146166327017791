import Utils from 'common/utils';
import BlankScss from './BlankPage.module.scss';

/**
 * Error 相关，空白展示页，统一样式
 * */

const COMMON_CLS = Utils.uniteClass('eid-blank-page', BlankScss.blankPage);

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  img: string;
  errorCode: string;
  title: string;
  desc: string;
}

const ErrorBlankPage: React.FC<IProps> = (props: IProps) => {
  const { className, style, errorCode, img, desc, title } = props;
  return (
    <div
      className={Utils.uniteClass(COMMON_CLS, 'eid-card', BlankScss.notFound, className)}
      style={style}
    >
      <img src={img} alt={errorCode} />
      <div className={BlankScss.content}>
        <p className={BlankScss.text}>{errorCode}</p>
        <p className={BlankScss.title}>{title}</p>
        <p className={BlankScss.desc}>{desc}</p>
      </div>
    </div>
  );
};

export default React.memo(ErrorBlankPage);

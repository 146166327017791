import Utils from 'common/utils';
import TabFocusDom from 'common/components/TabFocusDom';
import VerificationCode from 'common/components/VerificationCode';
import { DEFAULT_COUNTDOWN_SECOND, VERIFY_CODE_LIMIT_ERR } from 'common/constants/Constants';
import VerifyCodeScss from './VerifyCode.module.scss';

export interface IProps {
  mobile?: string;
  errorMsg?: string; // 特殊处理 VERIFY_CODE_LIMIT_ERR 验证码发送已达上限的报错
  promptMsg?: string;
  onSendCode: () => Promise<any> | void;
  onCodeChange?: (code: string) => void;
  seconds?: number;
  title?: string;
  disabled?: boolean;
  topComp?: React.ReactNode;
}

const prefixCls = 'eid-verify-code';

const VerifyCode: React.FC<IProps> = (props: IProps) => {
  const {
    mobile,
    errorMsg,
    onSendCode,
    onCodeChange,
    seconds,
    promptMsg,
    title,
    disabled,
    topComp,
  } = props;

  const [countdown, setCountDown] = React.useState<number>(
    _.isNumber(seconds) ? seconds : DEFAULT_COUNTDOWN_SECOND,
  );
  const timerRef = React.useRef<number | undefined>();

  React.useEffect(() => {
    if (_.isNumber(seconds)) setCountDown(seconds);
  }, [seconds]);

  React.useEffect(() => {
    if (_.isNumber(countdown) && countdown > 0) {
      timerRef.current = window.setTimeout(() => {
        setCountDown(countdown - 1);
      }, 1000);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [countdown]);

  const onSend = () => {
    if (_.isFunction(onSendCode)) {
      const reqInstance: Promise<any> | void = onSendCode();
      if (reqInstance instanceof Promise && _.isFunction(reqInstance.then)) {
        reqInstance
          .then((res) => {
            const expire = _.get(res, 'expire') || 0;
            setCountDown(expire || DEFAULT_COUNTDOWN_SECOND);
          })
          .catch(() => {
            setCountDown(0);
          });
      } else {
        console.warn('Function onSendCode should return a Promise.');
      }
    }
  };

  const isVerifyCodeOverflow = !!errorMsg && VERIFY_CODE_LIMIT_ERR.has(errorMsg);
  const errMsg = isVerifyCodeOverflow ? VERIFY_CODE_LIMIT_ERR.get(errorMsg) : errorMsg;

  const renderCountdown = () => {
    if (isVerifyCodeOverflow) return null;
    if (countdown) {
      return (
        <div
          className={Utils.uniteClass(
            'eid-desc',
            VerifyCodeScss.countdown,
            `${prefixCls}-countdown-desc`,
          )}
        >
          <span>{countdown}</span>
          <span>s</span>
          后可重新发送验证码
        </div>
      );
    }
    return (
      <TabFocusDom
        className={Utils.uniteClass(VerifyCodeScss.regainBtn, `${prefixCls}-regainBtn`)}
        onClick={onSend}
      >
        重新发送验证码
      </TabFocusDom>
    );
  };

  const msg = errMsg || promptMsg;

  return (
    <>
      {topComp || null}
      <div className={Utils.uniteClass('eid-text-3', `${prefixCls}-title`)}>
        {title || '短信验证码'}
      </div>
      {msg || mobile ? (
        <div
          className={Utils.uniteClass(
            VerifyCodeScss.tips,
            'eid-desc',
            errorMsg ? VerifyCodeScss.errorTips : '',
            errorMsg ? `${prefixCls}-error-tips` : '',
            `${prefixCls}-tips`,
          )}
        >
          {msg || (
            <>
              验证码已发送至
              <span className={`${prefixCls}-tip-mobile`}>{Utils.phoneHide(mobile)}</span>
              ，5分钟内有效
            </>
          )}
        </div>
      ) : null}
      <VerificationCode
        onChange={(value: string) => {
          if (_.isFunction(onCodeChange)) onCodeChange(value);
        }}
        error={!!errorMsg}
        disabled={disabled || isVerifyCodeOverflow}
      />
      {renderCountdown()}
    </>
  );
};

export default VerifyCode;

export enum AUTH_STATUS {
  INIT = 0,
  REVIEW = 1,
  SUCCESS = 2,
  FAIL = 3,
  REVOCATION = 4,
  EXPIRE = 5,
  DISABLED = 6, // 认证失效,例如举报失效
}

export enum AUTH_STEP {
  CHOOSE = 0,
  UPLOAD = 1,
  CERTIFY = 2,
}

/**
 * 阅读同意软件服务协议
 * */
import { Checkbox, CheckboxProps } from 'tdesign';
import { TabFocusWrapper } from 'common/components/TabFocusDom';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import AgreementScss from './Agreement.module.scss';

interface IProps extends CheckboxProps {
  shaking?: boolean;
}

export const checkIsOwnAgreementApps = () => {
  return JSRuntime.isTencentDocs || JSRuntime.isSogouInput;
};

const APP_PROTOCOL = {
  [JSRuntime.front2BackApp.docs]: {
    service: 'https://docs.qq.com/doc/p/8c9b7590b5484c7532c636a1011da1d915829ac8',
    privacy: 'https://docs.qq.com/doc/p/198d55cb4f92b4acb5c9d82c060ab65bebe8a4db',
  },
  [JSRuntime.front2BackApp.sogouinput]: {
    service: 'https://rule.tencent.com/rule/202311260003',
    privacy: 'https://rule.tencent.com/rule/202311260002',
  },
};

const Agreement = (props: IProps) => {
  const { shaking, ...extraProps } = props;
  const [shakingClass, setShakingClass] = React.useState('');

  const searchParams = JSRuntime.parseLocationSearch();
  const app = _.get(searchParams, 'app');

  // 借助生命周期，外部可以在每次更改 shaking 时先setShaking(false); 再setShaking(true); 来实现预期的抖动效果
  React.useEffect(() => {
    if (shaking) {
      setShakingClass('animate__animated animate__headShake');
    } else {
      setShakingClass('');
    }
  }, [shaking]);

  const agreementContent = () => {
    if (checkIsOwnAgreementApps()) {
      return (
        <>
          {`我已阅读并同意${JSRuntime.suiteAppName}的`}
          <a href={APP_PROTOCOL[app]?.service} target="_blank">
            《服务协议》
          </a>
          和
          <a href={APP_PROTOCOL[app]?.privacy} target="_blank">
            《隐私政策》
          </a>
          {`，${JSRuntime.suiteAppName}适用腾讯统一身份账号体系，方便您进行统一身份管理`}。
        </>
      );
    }

    return (
      <>
        我已阅读并同意
        <a href={JSRuntime.agreementUrl.service} target="_blank">
          《服务协议》
        </a>
        和
        <a href={JSRuntime.agreementUrl.privacy} target="_blank">
          《隐私政策》
        </a>
      </>
    );
  };

  const content = agreementContent();

  return content ? (
    <TabFocusWrapper stopPreventDefault>
      <Checkbox className={Utils.uniteClass(AgreementScss.agreement, shakingClass)} {...extraProps}>
        {content}
      </Checkbox>
    </TabFocusWrapper>
  ) : null;
};

export default React.memo(Agreement);

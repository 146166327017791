import { useStore, convert } from 'idpBase/store';
import { CONTENT, INVITE_FROM } from '../constants/Constants';
import Utils from 'common/utils';
import EmailPwdSet from './EmailPwdSet';
import VerifyCode from './VerifyCode';
import Icon from 'common/components/Icon';
import Button from 'common/components/Button';
import VerifyScss from '../style/Verify.module.scss';

const VerifyMobilePage = () => {
  const { invitationStore } = useStore();
  const { isJoinLoading, inviteFrom } = invitationStore;

  const onMobileBack = () => {
    invitationStore.updateStore({ currentContent: CONTENT.INVITING });
  };

  const isEmail = inviteFrom === INVITE_FROM.EMAIL;
  const content = isEmail ? <EmailPwdSet /> : <VerifyCode />;
  const header = isEmail ? '设置密码' : '身份验证';

  return (
    <div className={Utils.uniteClass(VerifyScss.mobilePageWrapper)}>
      <Button
        className={VerifyScss.mobileBackBtn}
        color="secondary"
        disabled={isJoinLoading}
        onClick={onMobileBack}
      >
        <Icon className={VerifyScss.backIcon} type="TxArrowRight" size={16} pointer />
        返回
      </Button>
      <div className={VerifyScss.mobilHead}>{header}</div>
      {content}
    </div>
  );
};

export default convert(VerifyMobilePage);

export enum ButtonMode {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  MINIMAL = 'minimal',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary', // 灰
  PRIMARY2 = 'primary2', // 绿
  DANGER = 'danger',
}

export enum ButtonSize {
  DEFAULT = 'default',
  LARGE = 'large',
  SMALL = 'small',
}

export enum ButtonShape {
  DEFAULT = 'default',
  SQUARE = 'square', // 正方形
}

import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import BlankPage from 'common/components/BlankPage';
import { ACCOUNT_TYPE } from 'common/constants/Constants';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { convert, useStore } from 'idpBase/store';
import CidpRouteEntry from '../cidp/RouteEntry'; // 体量小，不用 lazy，不用网络请求可以更快的展示
import Invitation from '../invitation';
import Logout from '../logout'; // 体量小，不用 lazy，不用网络请求可以更快的展示
import ResetPassword from '../resetPassword';
// import Preview from '../preview';

// const Login = React.lazy(() => import('../loginV0'));
const Login = React.lazy(() => import('../login/RouteEntry'));
const SocialLogin = React.lazy(() => import('../socialLogin/RouteEntry'));
const ForgetPwd = React.lazy(() => import('../forgetPwd'));
const Register = React.lazy(() => import('../register'));
const BindTenant = React.lazy(() => import('../bindTenant'));
const AppAuthorization = React.lazy(() => import('../appAuthorization/RouteEntry'));
const Profile = React.lazy(() => import('../profile/RouteEntry'));
const SSO = React.lazy(() => import('../sso/RouteEntry'));
const SelectAccount = React.lazy(() => import('../selectAccount/selectAccount'));
const SelectTenant = React.lazy(() => import('../selectAccount/selectTenant'));
const Consent = React.lazy(() => import('../selectAccount/consent'));
const Auth = React.lazy(() => import('../auth/RouteEntry'));
const AuthEnterprise = React.lazy(() => import('../authEnterprise/RouteEntry'));

const RouteEntry = () => {
  const { rootStore } = useStore();
  const isMustSsoLoginEnv = JSRuntime.isCustomDomain;
  const { userInfo } = rootStore;
  const query = JSRuntime.parseLocationSearch(undefined, {
    transferNum: false,
  });

  const fromApp = !!_.get(query, 'appType');

  const IndexElement = () => {
    if (!!userInfo && !isMustSsoLoginEnv) {
      return fromApp ? (
        <Navigate to="/app_authorization" />
      ) : (
        (rootStore.redirectToLandingPage(),
        // 用逗号保证一定返回null(否则可能会报错
        null)
      );
    }

    return <Navigate to="/login" />;
  };

  useEffect(() => {
    const hash = Utils.getPureHashPath();
    if (hash === '#/app_authorization/authorize') return;
    const meta = document.createElement('meta');
    meta.httpEquiv = 'Content-Security-Policy';
    meta.content = 'form-action none';
    document.head.appendChild(meta);
  }, []);

  const isPersonal = m.get(userInfo, 'account.accountType') === ACCOUNT_TYPE.PERSONAL;

  // 自定义域名及私有化环境下，不可访问 login 和 social_login
  return useRoutes([
    {
      index: true,
      element: <IndexElement />,
    },
    // TODO 临时路由，测试idp基础组件样式换肤
    // { path: 'preview', element: <Preview /> },
    { path: 'login/*', element: isMustSsoLoginEnv ? <Navigate to="/sso_login" /> : <Login /> },
    { path: 'sso_login/*', element: <SSO /> },
    {
      path: 'social_login/*',
      element: isMustSsoLoginEnv ? <Navigate to="/sso_login" /> : <SocialLogin />,
    },
    { path: 'forget', element: <ForgetPwd /> },
    { path: 'cidp/*', element: <CidpRouteEntry /> },
    { path: 'logout', element: <Logout /> },
    // 开启注册功能或者会议来源，开放注册路由
    JSRuntime.isTencentMeeting || JSRuntime.isTencentDocs || JSRuntime.isSogouInput
      ? { path: 'register', element: <Register /> }
      : {},
    { path: 'init_account', element: <BindTenant /> },
    { path: 'app_authorization/*', element: <AppAuthorization /> },
    { path: 'profile/*', element: <Profile /> },
    { path: 'invitation', element: <Invitation /> },
    !JSRuntime.isPrivate ? { path: 'resetPwd/:token', element: <ResetPassword /> } : {},
    // 选择账号
    { path: 'select_account', element: <SelectAccount /> },
    // 切换租户
    { path: 'select_tenant', element: <SelectTenant /> },
    // 当前登录账号+选择其他账号
    { path: 'consent', element: <Consent /> },
    // 个人认证
    isPersonal ? { path: 'auth/*', element: <Auth /> } : {},
    { path: 'auth-enterprise/*', element: <AuthEnterprise /> },
    { path: 'notFound', element: <BlankPage type="NOT_FOUND" /> },
    { path: '*', element: <Navigate to="/notFound" /> },
  ]);
};

export default convert(RouteEntry);

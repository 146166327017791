import { AuthnApi as CommonAuthnApi } from 'common/api';
import { redirectIOA } from 'common/compositions/RedirectIOA';
import { ICodeReq } from 'common/compositions/VerifyDialog/Types';
import {
  ACCOUNT_TYPE,
  CAPTCHA_ERROR,
  MOBILE_SAFE_ERR_CODE,
  VERIFY_CODE_SCENE,
} from 'common/constants/Constants';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { CHOOSE_ACCOUNT_ERROR_MSG } from './constants/Constants';
import { WECHAT_VERIFY_ERROR } from '../base/constants/Constants';
import { LOGIN_RES_TYPE } from '../constants/Constants';
import { IAccount, IVerifyWeChat } from '../constants/Types';
class ChooseAccountStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ChooseAccountStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public activeAccountId = '';
  public showBindMobileVerify = false;
  public accounts: IAccount[] = [];
  public unavailable: IAccount[] = [];
  public isCreateLoading = false;
  // 显示手机号验证弹窗
  public showMobileVerifyDialog = false;
  // 显示微信验证弹窗
  public showWeChatVerifyDialog = false;
  public mobile = '';
  public weChat = '';

  private disablePersonalApps = [JSRuntime.front2BackApp.docs, JSRuntime.front2BackApp.sogouinput];
  public handleAccounts(originAccounts: IAccount[], originUnavailable?: IAccount[]) {
    const unavailable = (() => {
      // Tips: 有开通能力的放在前面
      const accessUnavailable: IAccount[] = [];
      const noAccessUnavailable: IAccount[] = [];
      originUnavailable?.forEach((a: IAccount) => {
        if (a.noAccess) noAccessUnavailable.push(a);
        else accessUnavailable.push(a);
      });
      const newUnavailable = [...accessUnavailable, ...noAccessUnavailable];
      // Tips: 会议展示 1. 未开通会议的个人版租户 2. 开通了会议但未拥有license的企业版租户
      if (JSRuntime.isTencentMeeting) {
        return (
          newUnavailable?.filter(
            (item) =>
              item.accountType !== ACCOUNT_TYPE.COMPANY || (!item.noInstance && item.noLicense),
          ) || []
        );
      }
      if (this.disablePersonalApps.includes(JSRuntime.appFromUrl)) {
        return newUnavailable?.filter((item) => item.accountType !== ACCOUNT_TYPE.PERSONAL) || [];
      }

      return newUnavailable || [];
    })();

    const accounts = (() => {
      if (this.disablePersonalApps.includes(JSRuntime.appFromUrl)) {
        return originAccounts?.filter((item) => item.accountType !== ACCOUNT_TYPE.PERSONAL) || [];
      }
      return originAccounts || [];
    })();

    const isNoAccount =
      !(m.isArray(accounts) && accounts.length) && !(m.isArray(unavailable) && unavailable.length);
    if (isNoAccount) {
      this.updateStore({
        accounts: [],
        unavailable: [],
        isBlockLoading: false,
      });
    } else {
      if (accounts.length === 1 && !unavailable?.length) {
        // 仅一个租户
        this.selectAccount(accounts[0]).catch(() => {
          this.global.login.loginBaseStore.resetStoreProxy();
          this.global.login.loginBaseStore.redirectTo?.('/login');
        });
      } else {
        mobx.runInAction(() => {
          const personalAccIdx = accounts.findIndex(
            (account: IAccount) => account.accountType === ACCOUNT_TYPE.PERSONAL,
          );
          const hasPersonalAccount = personalAccIdx >= 0;
          if (hasPersonalAccount) {
            accounts.push(accounts.splice(personalAccIdx, 1)[0]);
          }
          this.accounts = accounts.map((account: IAccount) => ({
            ...account,
            accountType: account.accountType || ACCOUNT_TYPE.COMPANY,
          }));
          this.unavailable = unavailable;
          this.isBlockLoading = false;
        });
      }
    }
  }

  public selectAccount(account: IAccount, skipSelect?: boolean) {
    return AuthnApi.chooseAccount(account.id, JSRuntime.backEndApp)
      .then((res) => {
        const type = m.get(res, 'next.type');
        if (redirectIOA(res)) return Promise.resolve();
        switch (type) {
          case LOGIN_RES_TYPE.MOBILE_VERIFY:
            this.updateStore({
              showMobileVerifyDialog: true,
              mobile: m.get(res, 'next.mobile'),
            });
            return Promise.resolve();

          case LOGIN_RES_TYPE.WECHAT_VERIFY:
            this.updateStore({
              showWeChatVerifyDialog: true,
              weChat: m.get(res, 'next.wechat'),
            });
            return Promise.resolve();

          case LOGIN_RES_TYPE.MOBILE_LACK:
            this.updateStore({
              showBindMobileVerify: true,
            });
            return Promise.resolve();
        }

        this.loginAccount(skipSelect);
        return Promise.resolve();
      })
      .catch((error) => {
        this.updateStore({
          activeAccountId: '',
        });
        const errCode = m.get(error, 'data.errCode');
        const chooseOrgErrMsg = CHOOSE_ACCOUNT_ERROR_MSG[errCode] || '登录失败，请稍后重试';
        const isAccountUnSafe = errCode === MOBILE_SAFE_ERR_CODE || errCode === CAPTCHA_ERROR;
        this.global.handleError(isAccountUnSafe ? error : chooseOrgErrMsg);
        return Promise.reject(error);
      });
  }

  // 选择账号后登录账号
  public loginAccount(skipSelect?: boolean) {
    // 个人版 | 企业版都走同一个逻辑
    this.global.rootStore.redirectToLandingPage({ skipSelect });
  }

  // 创建账号
  public createAccount(params: { accountType: ACCOUNT_TYPE; name: string; companyName?: string }) {
    this.isCreateLoading = true;
    return AuthnApi.setupCompany({ ...params, position: 'QA' })
      .then(() => {
        this.global.rootStore.redirectToLandingPage();
      })
      .catch((error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isCreateLoading: false,
        });
      });
  }
  public getSMS() {
    // return Promise.resolve({
    //   expire: 60,
    //   verificationSessionId: 1111111,
    // });
    return AuthnApi.sendVerificationCode({
      mobile: this.mobile,
      event: VERIFY_CODE_SCENE.COMPLETE_MOBILE,
    });
  }

  public getBindMobileSMS(mobile: string) {
    return CommonAuthnApi.sendVerificationCode({
      mobile,
      event: VERIFY_CODE_SCENE.NECESSARY_MOBILE,
    });
  }

  public verifyBindMobileCode(params: ICodeReq) {
    return AuthnApi.verifyMobile({ ...params, event: VERIFY_CODE_SCENE.NECESSARY_MOBILE });
  }

  public verifyCode(params: ICodeReq) {
    // console.log(params);
    // return Promise.resolve({
    //   next: {},
    // });
    return AuthnApi.verifyMobile(params);
  }

  public verifyWeChat(params: IVerifyWeChat) {
    return AuthnApi.verifyWeChat(params)
      .then((res) => (redirectIOA(res) ? Promise.reject(true) : res))
      .catch((err) => {
        if (err === true) return Promise.reject();
        const errCode = m.get(err, 'data.errCode');
        if (WECHAT_VERIFY_ERROR[errCode]) {
          return Promise.reject(WECHAT_VERIFY_ERROR[errCode]);
        }
        this.global.handleError(err);
        return Promise.reject();
      });
  }
}

export default ChooseAccountStore;

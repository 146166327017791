import * as AxiosTypes from 'axios';
import JSRuntime from 'common/utils/JSRuntime';

const noop = () => {};

class RequestPromise<T> {
  private abortFn?: AxiosTypes.Canceler;

  constructor(promise: Promise<T>, abortFn: AxiosTypes.Canceler) {
    Object.getOwnPropertyNames(Object.getPrototypeOf(promise)).forEach((prop) => {
      if (prop === 'constructor') {
        return;
      }
      const propVal = promise[prop];
      if (_.isFunction(propVal)) {
        this[prop] = propVal.bind(promise);
      } else {
        this[prop] = propVal;
      }
    });
    this.setAbort(abortFn);
  }

  public get abort(): AxiosTypes.Canceler {
    if (!_.isFunction(this.abortFn)) {
      JSRuntime.warn('abort in RequestPromise is NOT a function!');
      return noop;
    }
    return this.abortFn;
  }

  private setAbort(abortFn: AxiosTypes.Canceler) {
    if (!_.isFunction(abortFn)) {
      this.abortFn = noop;
      JSRuntime.error('abort in RequestPromise MUST be a function!');
      return;
    }
    this.abortFn = abortFn;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, no-redeclare
interface RequestPromise<T> extends Promise<T> {}

export default RequestPromise;

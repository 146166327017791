/* eslint-disable @typescript-eslint/naming-convention */
/**
 * 获取当前语言
 */

export enum LangEnum {
  'zh-CN' = 'zh-CN',
  'zh-cn' = 'zh-CN',
  'en-US' = 'en-US',
  'en' = 'en-US', // 认为en等同于en-US
  'zh' = 'zh-CN', // 认为zh开头的tag language都使用zh-CN
  'zh-HK' = 'zh-CN',
  'zh-TW' = 'zh-CN',
}

function navigatorLookUp(): [boolean, string] {
  const foundLangs: Array<string> = [];

  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      // 只有chrome有languages
      for (const lang of navigator.languages) {
        foundLangs.push(lang);
      }
    }
    // 兼容ie
    if (_.get(navigator, 'userLanguage')) {
      foundLangs.push(_.get(navigator, 'userLanguage'));
    }
    if (navigator.language) {
      foundLangs.push(navigator.language);
    }
  }

  return [foundLangs.length > 0, foundLangs.length > 0 ? foundLangs[0] : ''];
}

// TODO: language 语言方案后续可能不再通过 window.LANG 实现，这里先暂时这样写
export function getCurrentLang(): string {
  const langFromBackend = _.get(window, 'LANG');
  if (langFromBackend && LangEnum[langFromBackend]) return LangEnum[langFromBackend];

  const [hasNavigatorLanguage, curNavigatorLang] = navigatorLookUp();
  if (hasNavigatorLanguage) {
    return LangEnum[curNavigatorLang] || LangEnum['en-US'];
  }

  return LangEnum['en-US']; // 一般不会执行到这里
}

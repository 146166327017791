import { IPolicy } from 'common/components/StrategyDescription';
import { IResetPasswordUrlParams } from 'common/constants/Types';
import utils from 'common/utils';
import { UserApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';

export default class ResetPasswordStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ResetPasswordStore>;
  public resetStore: () => void;
  public isValid: boolean;
  public pwdErrorMessage: string;
  public userName: string;
  public logoUrl: string;
  public enterpriseName: string;
  public policy: IPolicy;
  private token: string;

  public async init(params: IResetPasswordUrlParams) {
    this.token = params.reset_token;
    const [err, res] = await utils.resolvePromise(UserApi.getResetPasswordInitialData(params));
    if (err) {
      this.global.handleError(err);
      return Promise.reject(err);
    }

    this.updateStore({
      ...res,
      policy: m.get(res, 'passwordPolicy.complexity'),
    });
    return res;
  }

  public async resetPwd(params: { password: string }) {
    const [err, credential] = await utils.resolvePromise(
      this.global.rootStore.encryptRequest({
        password: params.password,
      }),
    );
    if (err) {
      this.global.handleError(err);
      return Promise.reject(err);
    }
    const [err2, res] = await utils.resolvePromise(
      UserApi.resetPwdByUrl({
        ..._.omit(params, 'password'),
        reset_token: this.token,
        credential,
      }),
    );
    if (err2) {
      this.updateStore({
        pwdErrorMessage: m.get(err2, 'data.errMessage') || '',
      });
      return Promise.reject(err2);
    }
    return res;
  }
}

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  className?: string;
  defaultSrc?: string;
  alt?: string;
  onLoadError?: () => void;
}

const ImageApply: React.FC<IProps> = (props: IProps) => {
  const { src, alt, defaultSrc, onLoadError, ...extraProps } = props;
  const [stateSrc, setStateSrc] = React.useState(src);
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    setStateSrc(src);
    setReload(false);
  }, [src]);

  const handleError = () => {
    if (!reload) {
      if (defaultSrc) setStateSrc(defaultSrc);
      setReload(true);
      if (_.isFunction(onLoadError)) {
        onLoadError();
      }
    }
  };

  return <img src={stateSrc} onError={handleError} alt={alt || ''} {...extraProps} />;
};

export default React.memo(ImageApply);

import qs from 'qs';
import { AuthnApi as CommonAuthnApi } from 'common/api';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { LOGIN_ERR_MSG, SSO_STEP } from './constants/Constants';
import { IIdentity, ILoginParams } from './constants/Types';

class SSOLoginStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<SSOLoginStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public step: SSO_STEP = SSO_STEP.ACCOUNT;
  public identities: IIdentity[] = []; // 认证源列表
  public currentIdentity: IIdentity | undefined = undefined; // TODO 整理删除
  public accountId = '';
  public loginErrMsg: string | undefined = undefined;
  public locationIdpId = ''; // 从地址栏获得的IdP id
  public isCustomAccountFinished = false;
  public isValidateLoading = false;
  public domainLoginResult: Record<string, any> | null = null;
  public redirectTo: (path: string) => void = () => {};
  /*
    当前登录方式对应的state值
    使用RedirectIdentity类方法直接跳转时，是通过参数传的stateId
    在组件里使用时，用的是store里的stateId
  */
  public stateId = '';
  public isStateIdLoading = false;

  // 点击其他认证源loading
  public isSSOSuccessLoading = false;

  // 当前只有 domain 和 accountId 在使用， email 暂未使用
  public loginByEmailOrDomain(
    params: Partial<Record<'domain' | 'domainName' | 'accountId', string>>,
  ) {
    return AuthnApi.loginByEmailOrDomain(params);
  }

  public initDomain() {
    if (JSRuntime.isCustomDomain || this.global.rootStore.accountId) {
      // 优先用url上的domain
      this.loginByEmailOrDomain(
        JSRuntime.isCustomDomain
          ? { domainName: window.location.hostname }
          : { accountId: this.global.rootStore.accountId },
      )
        .then((res) => {
          if (_.get(res, 'domain') && !JSRuntime.isCustomDomain) {
            this.jumpToSsoDomain(_.get(res, 'domain'));
          } else {
            this.updateStore({
              domainLoginResult: res,
            });
          }
        })
        .catch((err) => {
          const errCode = m.get(err, 'data.errCode');
          const errMessage = m.get(err, 'data.errMessage');

          if (errCode) {
            const query: Record<string, string> = { errCode };
            if (errMessage && !LOGIN_ERR_MSG[errCode]) query.errMessage = errMessage;
            // E0010041: 如果没有找到匹配的企业，跳回到自然人登录页
            // E0010042: 这个时候应该是没有认证源，如果不是demo展示，应该跳转到自然人登录页
            if (
              (errCode === 'E0010041' ||
                (errCode === 'E0010042' &&
                  !JSRuntime.isBrandDemoOrigin && // admin-品牌化自定义-演示登录区域-所有按钮不支持点击
                  !JSRuntime.isLoginDemoOrigin)) && // admin-品牌化自定义-跳转演示登录页-忽略登录态-走正常登录流程
              !JSRuntime.isPrivate // 私有化环境没有login页，去error页
            ) {
              Utils.redirectToOriginalIdp(`${window.location.search}`, 'login');
            } else {
              this.redirectTo(`${'/sso_login/error'}?${qs.stringify(query)}`);
            }
          } else {
            this.updateStore({
              isBlockLoading: false,
            });
            this.global.handleError(err);
          }
        });
    } else {
      this.updateStore({ isBlockLoading: false });
    }
  }
  // AD 等认证源的登录
  public loginIdentity = (
    identityId: string,
    data: { credential: string; isTest?: boolean },
    curAccountId?: string,
  ) => AuthnApi.loginIdentity(curAccountId || this.accountId, identityId, data);

  /*
    申请sso登录, 获取当前sso方式对应的state值
    在SSOAccount里用时，需要通过参数传accountId和currentIdentity(store里还没有)
    在Methods组件里，使用的是store里的accountId和currentIdentity
  */
  public getSSOState(accountId?: string, currentIdentity?: IIdentity) {
    this.updateStore({ isStateIdLoading: true });
    const realAccountId = accountId || this.accountId;
    const identity = currentIdentity || this.currentIdentity;
    const idpInstanceId = m.get(identity, 'id');

    return CommonAuthnApi.getSSOLoginState(realAccountId, idpInstanceId)
      .then((res: { state: string }) => {
        this.updateStore({ stateId: m.get(res, 'state') });
        return Promise.resolve(m.get(res, 'state'));
      })
      .catch((err) => {
        this.global.handleError(err);
        return Promise.reject();
      })
      .finally(() => {
        this.updateStore({ isStateIdLoading: false });
      });
  }

  // 登录页面账号密码登录
  public login(params: ILoginParams) {
    return AuthnApi.loginEnterprise({ ...params }).catch((error: Error) => {
      const errorCode = m.get(error, 'data.errCode');
      this.global.rootStore.dataReport('e#login_result_web', {
        web_login_type: 'sso',
        login_result: 'failed',
        login_error_code: errorCode,
      });
      const loginErrMsg = LOGIN_ERR_MSG[m.get(error, 'data.errCode')];
      this.global.handleError(loginErrMsg || error);
      return Promise.reject();
    });
  }

  public jumpToSsoDomain(sso: string) {
    const configUrl = new URL(window.ONEID_PUBLIC_ORIGINAL_IDP_SITE);
    const { pathname, protocol, search } = window.location;
    const hash = Utils.getPureHashPath();

    Utils.safeRedirect(
      `${protocol}//${sso}.${configUrl.host}${pathname}${search}${
        hash.includes('#/sso_login/identity') ? hash : `${hash}/identity`
      }`,
    );
  }
}

export default SSOLoginStore;

export enum STEP_MODE {
  LOGIN = 'LOGIN', // 登录页面
  MOBILE_VERIFY = 'MOBILE_VERIFY',
  ACCOUNT_OPTIONS = 'ACCOUNT_OPTIONS', // 进入账号选择
  PERSONAL_CREATE = 'PERSONAL_CREATE', // 创建个人账号
}

export const FORGET_PWD_ERROR_MSG = {
  E0010028: '手机号或验证码不正确，请重新输入', // 手机号+验证码: 验证码有误
};

export const BINDING_ERROR_MSG = {
  E0010102: '该企业已激活应用，请更换企业激活',
  E0010153: '您暂无激活应用权限，请联系管理员',
  E0010035: '您在当前企业已被停用，请联系管理员',
};

export const PERSONAL_BINDING_ERROR_MSG = {
  E0010102: '您已激活过应用，无法再次激活',
};

export const DEFAULT_POS = 'QA'; // NOTICE position 为后端对接第三方必传项，但是产品不想要，这里写个默认数据

export enum LOGIN_RES_TYPE {
  ACCOUNT_OPTIONS = 'ACCOUNT_OPTIONS', // 进入账号选择
  ACCOUNT_CREATE = 'ACCOUNT_CREATE', // 进入企业创建页面
}

/*
 * 对业务模块下的API进行通用请求封装
 */

import Request, { TRequestConfig } from '../Request';

export default abstract class BaseApi {
  protected abstract requestInstance: Request;

  // GET
  public getAll() {
    return this.requestInstance.get();
  }

  public getById(id: string) {
    return this.requestInstance.get(id);
  }

  public getByParams(params: TRequestConfig['params']) {
    return this.requestInstance.get(null, params);
  }

  public getWithParams(path: Array<string>, params: TRequestConfig['params']) {
    return this.requestInstance.get(path, params);
  }

  public getBySuperAdmin(params: TRequestConfig['params']) {
    return this.requestInstance.get('getall', params);
  }

  // POST
  public create(bodyData: TRequestConfig['data']) {
    return this.requestInstance.post(null, bodyData);
  }

  public createWithParams(bodyData: TRequestConfig['data'], params: TRequestConfig['params']) {
    return this.requestInstance.post(null, bodyData, params);
  }

  // PUT
  public updateById(id: string, bodyData: TRequestConfig['data']) {
    return this.requestInstance.put(id, bodyData);
  }

  // PATCH
  public patchById(id: string, bodyData: TRequestConfig['data']) {
    return this.requestInstance.patch(id, { ...bodyData, idpId: undefined });
  }

  // DELETE
  public deleteById(id: string, data?: TRequestConfig['data'], params?: TRequestConfig['params']) {
    return this.requestInstance.del(id, data, params);
  }

  // SEARCH
  public searchByKeyword(keyword: string, params?: TRequestConfig['params']) {
    return this.requestInstance.get('', { ...params, keyword });
  }

  // FORM DATA
  public sendFormData(config: TRequestConfig) {
    const { method = 'POST', headers, ...otherParams } = config;
    return this.requestInstance.request({
      method,
      headers: { 'Content-Type': 'multipart/form-data', ...headers },
      ...otherParams,
    });
  }
}

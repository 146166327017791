/**
 * 设置或者修改密码
 * */

import { Form, FormProps } from 'tdesign';
import FormItem from 'common/components/Form/FormItem';
import PasswordInput from 'common/components/PasswordInput';

interface IProps {
  pwdLabel: string;
  confirmPwdLabel: string;
  pwdPlaceholder?: string; // 默认 = pwdLabel
  confirmPwdPlaceholder?: string; // 默认 = confirmPwdLabel
  pwdRequiredRule?: string; // 默认 = 请输入 pwdLabel
  confirmPwdRequiredRule?: string; // 默认 = 请输入 confirmPwdLabel
  autofocus?: boolean;
  required?: boolean;
  onEnter: () => void;
  form: ReturnType<typeof Form.useForm>[0];
  onValuesChange?: FormProps['onValuesChange'];
}

const EmailPwdSetForm = (props: IProps) => {
  const {
    pwdLabel,
    confirmPwdLabel,
    pwdPlaceholder,
    confirmPwdPlaceholder,
    pwdRequiredRule,
    confirmPwdRequiredRule,
    autofocus,
    required = false,
    onEnter,
    form,
    onValuesChange,
  } = props;
  const pwdHolder = pwdPlaceholder || pwdLabel;
  const confirmPwdHolder = confirmPwdPlaceholder || confirmPwdLabel;
  const pwdReqRule = pwdRequiredRule || `请输入${pwdLabel}`;
  const confirmPwdReqRule = confirmPwdRequiredRule || `请输入${confirmPwdLabel}`;

  const onFormValuesChange: FormProps['onValuesChange'] = (changedValues, allValues) => {
    const { password, confirmPassword } = allValues;

    if (
      (changedValues.password && confirmPassword) ||
      (changedValues.confirmPassword && password)
    ) {
      form.validate?.({ fields: ['confirmPassword', 'password'] });
    }
    onValuesChange?.(changedValues, allValues);
  };

  return (
    <Form form={form} labelAlign="top" onValuesChange={onFormValuesChange}>
      <FormItem
        label={pwdLabel}
        name="password"
        rules={[
          { required: true, message: pwdReqRule },
          { password: true },
          {
            validator: (val: string) => {
              const confirmPwd = form?.getFieldValue?.('confirmPassword') || '';
              if (confirmPwd) return val === confirmPwd;
              return true;
            },
            message: '两次输入的密码需要相同',
          },
        ].filter((item) => {
          if (item.required) {
            return !!required;
          }
          return true;
        })}
      >
        <PasswordInput autofocus={autofocus} placeholder={pwdHolder} onEnter={onEnter} />
      </FormItem>
      <FormItem
        label={confirmPwdLabel}
        name="confirmPassword"
        rules={[
          { required: true, message: confirmPwdReqRule },
          {
            validator: (val: string) => {
              const password = form?.getFieldValue?.('password') || '';
              if (password) return val === password;
              return true;
            },
            message: '两次输入的密码需要相同',
          },
        ].filter((item) => {
          if (item.required) {
            return !!required;
          }
          return true;
        })}
      >
        <PasswordInput placeholder={confirmPwdHolder} onEnter={onEnter} />
      </FormItem>
    </Form>
  );
};

export default React.memo(EmailPwdSetForm);

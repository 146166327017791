/**
 * 通过传入参数（例如baseURL，拦截器等），生成装饰Request类
 */

import Request, { IRequestInstanceConfig, IResponseInterceptors } from './index';

export type IDecoratorRequest = Request;

export interface IDecoratorRequestConfig {
  baseURL: string;
  getResponseInterceptors?: () => IResponseInterceptors;
}

export default (decoratorConfig?: IDecoratorRequestConfig) => {
  const { baseURL: decoratorBaseURL = '', getResponseInterceptors } = decoratorConfig || {};
  return class DecoratorRequest extends Request implements IDecoratorRequest {
    constructor(serviceName?: string, instanceConfig?: IRequestInstanceConfig) {
      super(serviceName, {
        ...instanceConfig,
        baseURL: _.get(instanceConfig, 'baseURL') || decoratorBaseURL,
      });
    }

    protected getResponseInterceptors(): IResponseInterceptors {
      return _.isFunction(getResponseInterceptors)
        ? getResponseInterceptors()
        : super.getResponseInterceptors();
    }
  };
};

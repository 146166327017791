import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator';

class SocialIdpApi extends BaseApi {
  public requestInstance = new Request(APINames.SOCIAL_IDP);

  unbindSocialIdp(idpType: string) {
    return this.requestInstance.post([idpType, ':unbind']);
  }

  bindSocialIdp(idpType: string, body: { state: string; code: string; redirectUri?: string }) {
    return this.requestInstance.post([idpType, ':bind'], body);
  }
}

export default new SocialIdpApi();

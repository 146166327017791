import {
  DEFAULT_COUNTDOWN_SECOND,
  VERIFY_CODE_LIMIT_ERR,
  CREDENTIAL_ERROR,
} from 'common/constants/Constants';
import RequestPromise from 'common/apiRequest/Request/RequestPromise';
import JSRuntime from 'common/utils/JSRuntime';
import { TUpdateStore, TGlobalStore } from 'idpBase/store';
import { AuthnApi } from 'idpBase/api';
import { EMAIL_VERIFY_CODE_SCENE, PASSWORD_LOGIN_TYPE } from 'idpBase/Constants';
import { IAccountResult } from '../../constants/Types';
import {
  EMAIL_LOGIN_ERROR_MSG,
  EMAIL_LOGIN_STATUS,
  PWD_SET_TYPE,
  PWD_LOGIN_ERROR_MSG,
  SMS_LOGIN_ERROR_MSG,
} from '../../constants/Constants';

class EmailLoginStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<EmailLoginStore>;
  public resetStore: () => void;

  public email = '';
  public emailLoginMethod = EMAIL_LOGIN_STATUS.VERIFY_CODE_LOGIN;
  public emailLoginStatus = EMAIL_LOGIN_STATUS.INPUT_EMAIL;
  public loginErrMsg = '';
  public verificationSessionId = '';
  public countdown = DEFAULT_COUNTDOWN_SECOND;
  public isLoginLoading = false;
  public isEmailVerifyLoading = false;
  public isForgetPwdVerifyLoading = false;
  public hasSetPassword = false;
  public forgetPwdErrMsg = '';
  public forgetPwdVerificationSessionId = '';

  public loginApi: RequestPromise<any> | undefined = undefined;

  private get getAppType() {
    if (JSRuntime.isTencentMeeting) {
      return 'meeting';
    }

    return undefined;
  }

  public getVerifyCode(event: EMAIL_VERIFY_CODE_SCENE) {
    if (!this.email) return Promise.reject();
    return AuthnApi.sendEmailVerificationCode({ email: this.email, event });
  }

  public getLoginVerifyCode() {
    this.updateStore({ isEmailVerifyLoading: true });
    return AuthnApi.sendEmailVerificationCode({
      email: this.email,
      event: EMAIL_VERIFY_CODE_SCENE.EMAIL_LOGIN,
    })
      .then((codeRes) => {
        this.updateStore({
          isEmailVerifyLoading: false,
          emailLoginStatus: EMAIL_LOGIN_STATUS.VERIFY_CODE_LOGIN,
          verificationSessionId: _.get(codeRes, 'verificationSessionId'),
          countdown: _.get(codeRes, 'expire') || 0,
          loginErrMsg: '',
        });
      })
      .catch((error) => {
        const errorCode = _.get(error, 'data.errCode');
        if (VERIFY_CODE_LIMIT_ERR.has(errorCode)) {
          this.updateStore({
            emailLoginStatus: EMAIL_LOGIN_STATUS.VERIFY_CODE_LOGIN,
            loginErrMsg: errorCode,
            isEmailVerifyLoading: false,
          });
        } else {
          this.updateStore({ isEmailVerifyLoading: false });
          this.global.handleError(error);
        }
      });
  }

  public loginByCode(values: { verificationCode: string; verificationSessionId: string }) {
    this.isLoginLoading = true;
    if (this.loginApi) this.loginApi.abort?.();
    this.loginApi = AuthnApi.loginByEmailCode({
      verificationCode: _.get(values, 'verificationCode'),
      verificationSessionId: _.get(values, 'verificationSessionId'),
      app: this.getAppType,
    });

    this.loginApi
      .then((res: IAccountResult) => {
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: 'email_ver',
          login_result: 'success',
        });
        this.updateStore({ isLoginLoading: false });
        this.global.login.loginBaseStore.dealLoginSuccess(res, undefined, this.email);
      })
      .catch((error) => {
        const errorMap = { ...EMAIL_LOGIN_ERROR_MSG, ...SMS_LOGIN_ERROR_MSG };
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: 'email_ver',
          login_result: 'failed',
          login_error_code: _.get(error, 'data.errCode'),
        });
        this.updateStore({
          loginErrMsg: errorMap[_.get(error, 'data.errCode')] || '登录失败，请稍后重试',
          isLoginLoading: false,
        });
      });
  }

  // 滑块验证后登录需要带verify
  public async loginByPwd(password: string) {
    this.isLoginLoading = true;
    if (this.loginApi) this.loginApi.abort?.();

    const credential = await this.global.rootStore.encryptRequest({
      subject: this.email,
      password,
    });
    this.loginApi = credential
      ? (AuthnApi.loginByPwd({
          credential,
          loginType: PASSWORD_LOGIN_TYPE.EMAIL,
          app: this.getAppType,
        }) as RequestPromise<any>)
      : (Promise.reject({ data: { errCode: CREDENTIAL_ERROR } }) as RequestPromise<any>);

    return this.loginApi
      .then((res: IAccountResult) => {
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: 'email',
          login_result: 'success',
        });
        this.updateStore({ isLoginLoading: false });
        this.global.login.loginBaseStore.dealLoginSuccess(res, undefined, this.email);
        return res;
      })
      .catch((error) => {
        const errorMap = { ...EMAIL_LOGIN_ERROR_MSG, ...PWD_LOGIN_ERROR_MSG };
        this.global.login.loginBaseStore.reportLoginResult({
          web_login_type: 'email',
          login_result: 'failed',
          login_error_code: _.get(error, 'data.errCode'),
        });
        this.updateStore({ isLoginLoading: false });
        this.global.handleError(errorMap[_.get(error, 'data.errCode')] || '登录失败，请稍后重试');
      });
  }

  public forgetPwdVerifyCode(data: { verificationCode: string; verificationSessionId: string }) {
    this.isForgetPwdVerifyLoading = true;
    return AuthnApi.verifyEmailCode({
      ...data,
      event: EMAIL_VERIFY_CODE_SCENE.PASSWORD_FORGOT,
      app: this.getAppType,
    })
      .then(() => AuthnApi.checkPwdResetType())
      .then((res) => {
        this.updateStore({
          emailLoginStatus: EMAIL_LOGIN_STATUS.FORGET_PASSWORD_NEW,
          isForgetPwdVerifyLoading: false,
          hasSetPassword: m.get(res, 'type') === PWD_SET_TYPE.RESET,
        });
      })
      .catch((err) => {
        this.updateStore({ isForgetPwdVerifyLoading: false });
        return Promise.reject(err);
      });
  }

  public async setOrResetPassword(password: string) {
    const credential = await this.global.rootStore.encryptRequest({ password });
    // 外部处理 catch 逻辑
    return credential
      ? AuthnApi.resetEmailPassword({
          credential,
          event: EMAIL_VERIFY_CODE_SCENE.PASSWORD_FORGOT,
        })
      : Promise.reject({ data: { errCode: CREDENTIAL_ERROR } });
  }
}

export default EmailLoginStore;

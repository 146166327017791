import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator/adminV2';
import APINames from '../APINames';

// 企业黑白名单group参数类型
export enum GROUP_TYPE {
  ACCOUNT = 'account',
}

class BlackAndWhiteListApi extends BaseApi {
  public requestInstance = new Request(APINames.BLACK_AND_WHITELIST);

  // 获取企业黑白名单配置
  public getBlackAndWhiteList(params: { group: GROUP_TYPE; accountId: string }) {
    return this.requestInstance.get('', params);
  }
}

export default new BlackAndWhiteListApi();

export default {
  AUTHN: 'authn',
  AUTHORIZE: 'authorize',
  USER: 'user',
  ACCOUNTS: 'accounts',
  VENDOR: 'vendor',
  APPS: 'apps',
  ENTERPRISE: 'enterprise',
  PWD_MATCHED_POLICY: 'matchedPasswordPolicies',
  PERSONAL_EVENTS: 'personalEvents',
  APP_STORE: 'appStore',
  ACCOUNT: 'account',
};

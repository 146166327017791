import type { WebConfig } from '@tencent/aegis-web-sdk-v2';
import AegisV2 from '@tencent/aegis-web-sdk-v2';
import { JSONParse } from '@tencent/oneid-utils';
import { AEGIS_IGNORE_DOMAINS } from 'common/constants/Constants';
import JSRuntime from './JSRuntime';

/**
 * aegis 默认配置
 */
export const defaultConfig: WebConfig = {
  hostUrl: { url: 'https://galileotelemetry.tencent.com/collect' },
  env: JSRuntime.isProductSite ? AegisV2.environment.PROD : AegisV2.environment.TEST,
  repeat: 0,
  plugin: {
    spa: true,
    apiSpeed: true,
    assetSpeed: true,
    session: true,
    api: {
      // 收集接口请求参数和返回信息
      apiDetail: true,
      // http status === 200 && 有retcode不上报，属于正常业务报错
      retCodeHandler: (data: string, url: string, ctx: { code: string; isErr: boolean }) => {
        return { isErr: false, code: ctx.code };
      },
    },
  },
};

/**
 * 处理上报数据
 * @param domains 需要过滤的域名
 */
export const handleBeforeSend = (domains: string[]) => {
  return (logs: any, aegisInfo: any = {}) => {
    // 页面来源非本项目使用的域名不上报
    if (!domains.some((domain) => String(aegisInfo.snapshootInfo?.from).startsWith(domain))) {
      return [];
    }
    return logs.filter((log: any) => {
      const { type = '', status = 200, url = '', msg = '', data = '', level = '' } = log;
      if (type === 'normal') {
        // 文件加载错误（js、css、图片、媒体）
        if (
          level === 'script_error' ||
          level === 'image_error' ||
          level === 'css_error' ||
          level === 'media_error'
        ) {
          return domains.some((domain) => msg.includes(domain));
        }
      }
      // 不上报接口状态码4xx且有 data.errCode 的报错，因为对于老接口，这是正常的业务报错
      // 不上报忽略的域名，如埋点域名
      if (type === 'api') {
        if (
          (data && /^4\d{2,}/.test(status) && JSONParse(data)?.errCode) ||
          AEGIS_IGNORE_DOMAINS.some((domain) => url.startsWith(domain))
        ) {
          return false;
        }
      }
      return true;
    });
  };
};

import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/User';

class UserAccountApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNT);

  // 获取当前登录用户命中的密码策略
  getPasswordPolicy() {
    return this.requestInstance.get(['user', 'passwordPolicy']);
  }
}
export default new UserAccountApi();

import { CustomValidator } from 'tdesign';
import { POLICY_INCLUDE_TEXT } from './Constants';
import { INCLUDE } from 'common/constants/Constants';
import { IPolicy } from './index';

type IPolicyValidator = (policy: IPolicy) => CustomValidator;

export const pwdValidator: IPolicyValidator = (policy: IPolicy) => (val: string) => {
  if (!policy) return true;
  const minLength = policy?.minLength || 0;
  const maxLength = policy?.maxLength || 0;
  const include = policy?.include || [];
  if (!_.isString(val)) return true;

  if (!(val.length >= minLength && val.length <= maxLength)) {
    return {
      type: 'error',
      message: `您的密码字符长度需要在${minLength}～${maxLength}位`,
      result: false,
    };
  }

  const isLowerSuccess =
    !include.includes(INCLUDE.MIN_LOWER_CASE) ||
    (include.includes(INCLUDE.MIN_LOWER_CASE) && /[a-z]/.test(val));
  const isUpperSuccess =
    !include.includes(INCLUDE.MIN_UPPER_CASE) ||
    (include.includes(INCLUDE.MIN_UPPER_CASE) && /[A-Z]/.test(val));
  const isNumSuccess =
    !include.includes(INCLUDE.MIN_NUMBER) ||
    (include.includes(INCLUDE.MIN_NUMBER) && /[0-9]/.test(val));
  const isSymbolSuccess =
    !include.includes(INCLUDE.MIN_SYMBOL) ||
    (include.includes(INCLUDE.MIN_SYMBOL) && /["!#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~ ]+/.test(val));

  if (!isLowerSuccess || !isUpperSuccess || !isNumSuccess || !isSymbolSuccess) {
    return {
      type: 'error',
      message: `您的密码需要包含${include
        .map((str) => m.get(POLICY_INCLUDE_TEXT, str))
        .join('、')}`,
      result: false,
    };
  }

  return true;
};

export const NO_HEADER_ROUTER = ['/init_account'];
export const REDIRECT_WHITE_LIST = [/^.+\.(tencent|tencentcs)\.com$/gi];

export const ACCOUNT_OPERATION = {
  '/login': {
    text: '注册',
    url: '/register',
    tips: '没有账号？',
  },
  '/sso_login': {
    text: '注册',
    url: '/register',
    tips: '没有账号？',
  },
  '/register': {
    text: '登录',
    url: '/login',
    tips: '已有账号？',
  },
};

export const DEFAULT_SETTINGS = {
  account: {
    brands: {
      position: 'DEFAULT' as 'DEFAULT' | 'LEFT' | 'CENTER' | 'RIGHT',
      fillingType: '', // 'FIT' | 'FULL'
      template: 'SaaS' as 'SaaS' | 'doc' | 'meeting',
      backgroundType: '', // 'IMAGE' | 'COLOR',
      customCssEnabled: false,
      css: '',
      imageUrl: '',
      color: '',
      id: '', // 有值则为修改后的数据，无值则为默认数据
    },
    companyInfo: {
      logoUrl: '',
      companyName: '',
    },
    apps: [] as {
      name: string;
      vendorType: string;
      appType: string;
    }[],
  },
  system: {
    smsEnabled: true,
  },
};

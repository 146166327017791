import BeaconAction from '@tencent/beacon-web-sdk';
import JSRuntime from 'common/utils/JSRuntime';

let beacon: BeaconAction | null = null;
let beaconReportCommonInfo: Record<string, any> = {};

/**
 * 上传路径
 */
const uploadUrl = JSRuntime.isProductSite
  ? 'https://report.calendar.tencent.com/api/data-report-beacon'
  : 'https://reporttest.calendar.tencent.com/api/data-report-beacon';

/**
 * 上传空间appkey
 */
const appkey = '0WEB05HILO8Y6OQI'; // NOTICE 不区分测试与开发环境，用统一的appkey

/**
 * 获取灯塔 sdk 提供的 common 参数
 * @param beacon
 * @returns
 */
const getCommonInfo = (beacon: any) => {
  try {
    const info = beacon?.commonInfo;
    return {
      ...info,
      language: info?.language,
      resolution: info?.pixel,
      query: info?.query,
      user_agent: info?.userAgent,
      sdkVersion: info?.sdkVersion,
      deviceId: info?.deviceId,
      channelID: info?.channelID,
      openid: info?.openid,
      unid: info?.unid,
    };
  } catch (err) {
    return {};
  }
};

/**
 * 初始化灯塔SDK
 */
const initBeaconSDK = () => {
  if (beacon) return;

  beacon = new BeaconAction({
    appkey,
    uploadUrl,
  });

  // 参数具体见：https://iwiki.woa.com/p/1426737014
  const commonOptions = {
    source_id: '7',
    report_cmd: 'cmd_test_new_atta', // 实现的新老通道双报
    report_flag: 3, // 请求协议，默认设置
    request_time: `${new Date().getTime()}`,
    product: 'tencent_oneid',
  };

  beacon.addAdditionalParams(commonOptions);

  // 获取 sdk 提供的 common 参数
  const commonInfo = getCommonInfo(beacon);
  beaconReportCommonInfo = commonInfo;
};

/**
 * 数据上报
 * @param eventName
 * @param reportData
 */
export const dealBeaconReport = (
  eventName: string,
  reportData: Record<string, any>,
  isSendBeacon?: boolean,
) => {
  try {
    const reportParams = {
      ...reportData,
      ...beaconReportCommonInfo,
    };
    initBeaconSDK();
    if (isSendBeacon) {
      beacon?.onSendBeacon(eventName, reportParams);
      return;
    }
    beacon?.onDirectUserAction(eventName, reportParams);
  } catch (error) {
    /** empty */
  }
};

import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { AUTH_TYPE } from 'idpBase/Constants';
import { AccountApi } from 'idpBase/api';

class AuthorizationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<AuthorizationStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public isAuthorized = false;
  public authInfo = {};

  public init() {
    this.getAuthInfo();
  }
  public authorize() {
    return AccountApi.authorize().catch((err) => {
      this.global.handleError(m.get(err, 'data.errMessage'));
      return Promise.reject(err);
    });
  }

  public syncAuthInfo() {
    return AccountApi.syncAuthInfo().catch((err) => {
      this.global.handleError(m.get(err, 'data.errMessage'));
      return Promise.reject(err);
    });
  }

  /**
   * 获取认证信息
   */
  public getAuthInfo() {
    return AccountApi.getAuthInfo(AUTH_TYPE.UNKNOWN)
      .then((res) => {
        this.updateStore({
          isAuthorized: m.get(res, 'is_authorized'),
          authInfo: res,
          isBlockLoading: false,
        });
        return res;
      })
      .catch((error: Error) => {
        this.global.handleError(error);
        this.updateStore({
          isBlockLoading: false,
        });
        return Promise.reject(error);
      });
  }
}
export default AuthorizationStore;

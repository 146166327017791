import { Navigate, useRoutes } from 'react-router-dom';
import CidpEntry from './index';
import Error from './Error';
import DingTalkTransfer from './identityTransfer/DingTalk';
import RedirectIdentityTransfer from './identityTransfer/RedirectIdentity';
import ErrorTransfer from './identityTransfer/Error';
import SSOResult from './result/SSOTestResult';
import SDKTransfer from './SDKTransfer';
import Oauth2Transfer from './Oauth2Transfer';

const CidpRouteEntry = () =>
  useRoutes([
    { index: true, element: <CidpEntry /> },
    { path: 'error', element: <Error /> },
    { path: 'dingTalk', element: <DingTalkTransfer /> },
    { path: 'redirect_identity', element: <RedirectIdentityTransfer /> },
    { path: 'error/transfer', element: <ErrorTransfer /> },
    { path: 'sso/result', element: <SSOResult /> },
    { path: 'sdk/transfer', element: <SDKTransfer /> },
    { path: 'oauth2', element: <Oauth2Transfer /> },
    { path: '*', element: <Navigate to="/notFound" /> },
  ]);

export default React.memo(CidpRouteEntry);

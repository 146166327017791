/**
 * 数据上报公有参数
 * https://doc.weixin.qq.com/sheet/e3_AO4AiAb_ACkYPgrtGMPReSX3stOhx?scode=AJEAIQdfAAoWGaoaJLAO4AiAb_ACk&tab=h8apao
 */

import {
  getBrowserInfo,
  getCookie,
  getOsName,
  getWindow,
  setCookie,
  uuidv4,
} from '@tencent/web-base';
import JSRuntime from 'common/utils/JSRuntime';

export interface WareType {
  windows: string;
  android: string;
  ios: string;
  mac: string;
  linux: string;
  unknown: string;
}

// 获取 nav_platform
const getPlatForm = () => {
  if (getWindow()?.navigator?.platform) {
    return getWindow()?.navigator?.platform;
  }
  return '';
};

// 操作系统
const hardwareObj: WareType = {
  windows: 'Windows',
  android: 'Android',
  ios: 'IOS',
  mac: 'Mac',
  linux: 'Linux',
  unknown: getPlatForm() as string,
};

// 获取 cookie 中的 web_uid
const getWebUid = () => {
  let uuid = '';
  const locaUuid = getCookie('web_uid');
  if (locaUuid) {
    uuid = locaUuid;
  } else {
    uuid = uuidv4();
    setCookie('web_uid', uuid, 365 * 100, '/');
  }
  return uuid;
};

export const getGlobalReportParams = (eventName: string, params?: Record<string, any>) => ({
  source_id: '7', // web前端
  /** ---- 得传入的参数 --- */
  account_type: '',
  account_uid: '',
  union_id: '',
  alias_id: '',
  account_name: '',
  /* ------------------- */
  event_time: `${new Date().getTime()}`,
  event_code: eventName,
  unique_report_id: uuidv4(),
  web_uid: getWebUid(),

  /** -- 前端无法独立上传 -- */
  // country: '',
  // province: '',
  // city: '',
  // operator: '',
  // client_ip: '',
  /** ------------------ */

  resolution: `${window?.screen?.width}*${window?.screen?.height}`,
  hardware_os: hardwareObj[getOsName()],
  hardware_os_new: hardwareObj?.[getOsName()],
  prev_url: '',
  current_url: getWindow()?.location?.href,
  current_path: `${getWindow()?.location?.origin}${getWindow()?.location?.pathname}`,

  /** ===== landing相关 ===== */
  // landing_url: '',
  // landing_path: '',
  // landing_referraurl: '',
  // landing_referraldomain: '',
  // landing_url_session: '',
  // landing_path_session: '',
  // landing_referraurl_session: '',
  // landing_referraldomain_session: '',
  /** ====================== */

  explore_name: getBrowserInfo()?.type,
  explore_version: getBrowserInfo()?.versions,
  user_agent: getWindow()?.navigator?.userAgent,
  from_source: '', // NOTICE 页面多入口数据来源，暂时不要
  app_name: JSRuntime.backEndApp || 'oneid',
  product: 'tencent_oneid',

  ...params,
});

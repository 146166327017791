/**
 * 人员头像组件
 * */
import Utils from 'common/utils';
import DefaultAvatar from 'common/static/images/lightAvatar.png';
import UserAvatarScss from './UserAvatar.module.scss';
import ImageApply from '../ImageApply';

interface IProps {
  logoUrl: string;
  defaultLogoUrl?: string;
  displayName?: string;
  border?: boolean;
  color?: string;
  className?: string;
  size?: number | string;
}

const UserAvatar: React.FC<IProps> = (props: IProps) => {
  const { logoUrl, defaultLogoUrl, displayName = '', color, border, className, size } = props;
  const commonStyle = size ? { width: size, height: size } : {};

  // 传入 displayName 则在无 logoUrl 时显示 displayName 的一部分， 没传入 displayName 则显示默认底图
  if (!logoUrl && displayName) {
    let displayStr = '';
    /**
     * 设置了全局标志的正则，在使用test 时，会改变正则的 lastIndex 属性
     * https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
     * */
    if (/[\u4e00-\u9fa5]/gm.test(displayName)) {
      displayStr = displayName.substring(displayName.length - 2);
    } else {
      displayStr = (displayName[0] || '').toUpperCase();
    }

    return (
      <div
        className={Utils.uniteClass(
          UserAvatarScss.noLogo,
          border && UserAvatarScss.hasBorder,
          className,
        )}
        style={color ? { backgroundColor: color, ...commonStyle } : commonStyle}
      >
        {displayStr}
      </div>
    );
  }

  return (
    <ImageApply
      className={Utils.uniteClass(
        UserAvatarScss.hasLogo,
        border && UserAvatarScss.hasBorder,
        className,
      )}
      src={logoUrl || defaultLogoUrl || DefaultAvatar}
      defaultSrc={defaultLogoUrl || DefaultAvatar}
      style={commonStyle}
    />
  );
};

export default React.memo(UserAvatar);

import { getWindow, isMobile, AnyObject } from '@tencent/web-base';

/**
 * 合并基础的report数据上报信息
 * @param input object
 * @return report object
 */
export function mergeBaseReport(input = {}, userInput = {}): AnyObject {
  const reportObj: AnyObject = {};
  if (typeof reportObj === 'object') {
    reportObj.userid = m.get(input, 'alias_id') || '';
    // 设备类型 'mobile' : 'pc'
    reportObj.device_type = m.get(input, 'device_type') || (isMobile() ? 'mobile' : 'pc');
    // 浏览器ua
    reportObj.user_agent = getWindow().navigator.userAgent;
    reportObj.app_uid = m.get(input, 'union_id') || '';
    reportObj.app_id = m.get(input, 'account_uid') || '';
    // 租户类型
    reportObj.eid_account_type = m.get(input, 'account_type') || '';
    /**
     * 是否为海外版本: yes or no
     */
    reportObj.is_overseas = reportObj.is_overseas || 'no';
    // 浏览器平台
    let navPlatform = '';
    if (getWindow().navigator?.platform) {
      navPlatform = getWindow().navigator.platform;
    }
    reportObj.nav_platform = navPlatform;
    /**
     * 是否来自EID
     */
    reportObj.from_eid = 1; // 因为我们就是EID，写死
  }

  return { ...reportObj, ...userInput };
}

import { SOCIAL_IDP_TYPE } from 'common/components/SocialIdpCertWindow';
import { qqImg, wechatImg, weComImg } from 'idpBase/compositions/LoginMethods';

export const SOCIAL_IDP_NAME = {
  [SOCIAL_IDP_TYPE.WECHAT]: { name: '微信', img: wechatImg },
  [SOCIAL_IDP_TYPE.QQ]: { name: 'QQ', img: qqImg },
  [SOCIAL_IDP_TYPE.WECOM]: { name: '企业微信', img: weComImg },
};

export enum BIND_STEP {
  BIND_PHONE,
  BIND_PHONE_VERIFY_CODE,
}

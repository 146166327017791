/**
 * 全局可选公参
 * 优先级：业务参数 > 默认取值逻辑 > 空值
 * @param options 用户上报参数
 * @returns
 */
export function getOptionalReportParams(options = {}) {
  const reportObj: any = {};
  reportObj.multi_from = m.get(options, 'multi_from') || '';
  reportObj.experiment_id = m.get(options, 'experiment_id') || '';
  reportObj.experiment_group_id = m.get(options, 'experiment_group_id') || '';
  reportObj.mid = m.get(options, 'mid') || '';

  return {
    ...reportObj,
  };
}

/**
 * @description 封装数据上报公共参数
 * @param options object
 * @return report object
 */
import {
  getCookie,
  setCookie,
  getWindow,
  isMobile,
  getOsName,
  isIpad,
  isAndroidPad,
  isServer,
  getBrowserInfo,
  uuidv4,
  md5,
} from '@tencent/web-base';
import { getActPageModule } from './utils';
import { navigator } from './netType';

export interface WareType {
  windows: string;
  android: string;
  ios: string;
  mac: string;
  linux: string;
  unknown: string;
}

// 获取 nav_platform
const getPlatForm = () => {
  if (getWindow()?.navigator?.platform) {
    return getWindow()?.navigator?.platform;
  }
  return '';
};

// 获取 device_type tablet mobile pc
const getDeviceType = () => {
  if (isIpad() || isAndroidPad()) {
    return 'tablet';
  }
  if (isMobile()) {
    return 'mobile';
  }
  return 'pc';
};

// 获取 cookie 中的 web_uid
function getWebUid() {
  if (isServer) {
    return uuidv4();
  }
  let uuid = '';
  const locaUuid = getCookie('web_uid');
  if (locaUuid) {
    uuid = locaUuid;
  } else {
    uuid = uuidv4();
    setCookie('web_uid', uuid, 365 * 100, '/');
  }
  return uuid;
}

// 格式化 md5 字符串为 uuid 的格式
function formatMd5ToUuid(str: string) {
  let s = str;
  try {
    s = `${str.slice(0, 8)}-${str.slice(8, 12)}-${str.slice(12, 16)}-${str.slice(
      16,
      20,
    )}-${str.slice(20)}`;
  } catch (err) {
    // console.log(err, 'formatMd5ToUuid, err');
  }
  return s;
}

// 获取网络类型 net_type
function getNetType() {
  return navigator?.connection?.effectiveType;
}

/**
 * 获取全局公共参数
 * 不允许业务参数覆盖，数据分析所需基础字段
 * @param eventName 上报事件
 * @returns
 */
export function getGlobalReportParams(eventName = '', globalParams?: Record<string, any>) {
  const reportObj: any = {};

  // 操作系统
  const hardwareObj: WareType = {
    windows: 'Windows',
    android: 'Android',
    ios: 'IOS',
    mac: 'Mac',
    linux: 'Linux',
    unknown: reportObj.nav_platform as string,
  };
  // 获取浏览器名称和版本号
  const browserInfo = getBrowserInfo();

  // 获取 e# 事件的 act page_name element_name
  const actPageModule = getActPageModule(eventName);
  if (actPageModule) {
    reportObj.act = actPageModule.act;
    reportObj.page_name = actPageModule.page_name;
    reportObj.element_name = actPageModule.element_name;
  }

  reportObj.source_id = '7';
  reportObj.userid = m.get(globalParams, 'alias_id') || '';
  reportObj.device_type = getDeviceType();
  reportObj.user_agent = getWindow()?.navigator?.userAgent;
  reportObj.eid_union_id = m.get(globalParams, 'union_id') || '';
  reportObj.eid_account_id = m.get(globalParams, 'account_uid') || '';
  reportObj.corp_type = ''; // NOTICE 暂时先不传
  reportObj.corp_name = m.get(globalParams, 'account_name') || '';
  reportObj.nick_name = m.get(globalParams, 'name') || '';
  reportObj.is_overseas = 'no';
  reportObj.nav_platform = getPlatForm();
  reportObj.unique_report_id = uuidv4();
  reportObj.hardware_os =
    getOsName() === 'unknown' ? reportObj.nav_platform : hardwareObj[getOsName()];
  reportObj.web_uid = getWebUid() || '';
  reportObj.prev_url = m.get(globalParams, 'prev_url') || document.referrer; // TODO 待处理
  reportObj.current_url = getWindow()?.location?.href;
  reportObj.current_path = `${getWindow()?.location?.origin}${getWindow()?.location?.pathname}`;
  reportObj.explore_name = browserInfo.type;
  reportObj.explore_version = browserInfo.versions;
  reportObj.from_source = ''; // NOTICE 页面多入口数据来源，暂时不需要
  reportObj.net_type = getNetType() || '';
  reportObj.event_time = `${new Date().getTime()}`;
  reportObj.event_code = eventName;
  const objStr = JSON.stringify({
    ...reportObj,
  });

  // 上报id，唯一
  reportObj.unique_report_id = formatMd5ToUuid(md5(objStr));

  if (!reportObj?.unique_report_id || reportObj?.unique_report_id?.length !== 36) {
    reportObj.unique_report_id = uuidv4();
  }

  return {
    ...reportObj,
  };
}

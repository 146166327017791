import { BLOCKED_ERROR_CODE, BLOCKED_ERROR_MSG } from 'idpBase/Constants';
import { SOCIAL_IDP_TYPE } from 'common/components/SocialIdpCertWindow';

export const ERROR_MSG = {
  E0010028: '验证码不正确，请重新输入',
  E0010061: '手机号已被别的账号绑定，绑定失败',
  E0010007: '当前密码输入错误',
  E0000051: '当前密码输入错误', // 验证旧密码时，长度不符合，也是一种密码错误
  E0010030: '图片格式错误',
  E0010020: '当前企业内已存在相同的手机号码，请更换后重试',
};

// 邮件相关 error
export const EMAIL_ERROR_MSG = {
  E0010007: '当前密码输入错误',
  E0000051: '当前密码输入错误', // 验证旧密码时，长度不符合，也是一种密码错误
  E0010161: '今日验证码发送次数已达上限',
  E0010377: '验证码错误次数过多，请稍后再试',
};

export const SOCIAL_IDP_TYPE_NAME = {
  [SOCIAL_IDP_TYPE.WECOM]: '企业微信',
  [SOCIAL_IDP_TYPE.WECHAT]: '微信',
  [SOCIAL_IDP_TYPE.QQ]: 'QQ',
};

export enum VERIFY_TYPE {
  PWD_SET = 'PWD_SET',
  PWD_FORGET = 'PWD_FORGET',
}

export const OPERATION_TEXT = {
  [VERIFY_TYPE.PWD_SET]: '设置登录密码',
  [VERIFY_TYPE.PWD_FORGET]: '重置登录密码',
};

export enum RESET_PWD_WAY {
  OLD_PWD = 'OLD_PWD',
  MOBILE = 'MOBILE',
  SET_ENTERPRISE_PWD = 'SET_ENTERPRISE_PWD',
  CREATE_MOBILE_PWD = 'CREATE_MOBILE_PWD',
}

export const RESET_PWD_TEXT = (companyName = '本企业') => ({
  [RESET_PWD_WAY.OLD_PWD]: {
    title: '重置密码',
    desc: '请输入新的密码。更改后，原密码将无法登录。',
    successText: '密码更改成功，请重新登录',
    errorText: '密码更改失败',
    cancelBtnContent: '取消',
    confirmBtnContent: '确认',
  },
  [RESET_PWD_WAY.MOBILE]: {
    title: '修改密码',
    desc: '请输入新的密码。更改后，原密码将无法登录。',
    successText: '密码重置成功，请重新登录',
    errorText: '密码重置失败',
    cancelBtnContent: '取消',
    confirmBtnContent: '确认',
  },
  [RESET_PWD_WAY.SET_ENTERPRISE_PWD]: {
    title: '设置密码',
    desc: `请输入您的密码。该密码将用于在${companyName}中的登录。`,
    successText: '密码设置成功，请重新登录',
    errorText: '密码设置失败',
    cancelBtnContent: '暂不设置',
    confirmBtnContent: '完成',
  },
  // 创建手机号对应的密码
  [RESET_PWD_WAY.CREATE_MOBILE_PWD]: {
    title: '设置密码',
    desc: `请输入您的密码。`,
    successText: '密码设置成功，请重新登录',
    errorText: '密码设置失败',
    cancelBtnContent: '暂不设置',
    confirmBtnContent: '完成',
    pwdFormLabel: '输入密码',
    confirmPwdFormLabe: '确认密码',
    pwdFormPlaceholder: '输入密码',
    confirmPwdFormLabePlaceholder: '确认密码',
  },
});

export const SET_PASSWORD_TIPS = {
  [RESET_PWD_WAY.SET_ENTERPRISE_PWD]: {
    POLICY_UPGRADE: '管理员更新了密码策略，请您重新设置密码',
  },
};

// 租户换绑手机号，目标手机号已被占用
export const OCCUPIED_ERROR = {
  E0010214: '该账号已被其他用户占用',
};

// 验证码输入错误
export const CODE_VERIFY_ERROR = {
  E0010028: '验证码不正确，请重新输入',
};

// 企业退出、换绑、解散等的统一报错文案
export const TENANT_REBIND_ERROR = {
  E0010222: '目标账号个人版注销未满15天，无法换绑',
  E0010117: '该账号已被其他用户占用',
  [BLOCKED_ERROR_CODE]: BLOCKED_ERROR_MSG[BLOCKED_ERROR_CODE],
};

// 企业退出、换绑、解散等的统一报错文案
export const TENANT_ERROR_MSG = '服务器出现了问题，请刷新后重试';

export const enum LOGIN_RES_TYPE {
  EMAIL_PWD_SET = 'EMAIL_PWD_SET', // 去设置邮箱密码
}

export const SET_EMAIL_PWD_TEXT = {
  SET_PWD: {
    desc: '请为您的邮箱设置登录密码',
    successText: '密码设置成功',
    errorText: '密码设置失败',
    pwdLabel: '密码',
    confirmPwdLabel: '再次输入密码',
    cancelBtnContent: '返回',
    confirmBtnContent: '完成',
  },
  RESET_PWD: {
    desc: '请输入新的密码，修改后，原密码将无法登录',
    successText: '密码修改成功',
    errorText: '密码修改失败',
    pwdLabel: '新密码',
    confirmPwdLabel: '再次输入新密码',
    cancelBtnContent: '取消',
    confirmBtnContent: '完成',
  },
  SET_OLD_EMAIL_FIRST_PWD: {
    desc: '请设置邮箱密码',
    successText: '密码设置成功',
    errorText: '密码设置失败',
    pwdLabel: '密码',
    confirmPwdLabel: '再次输入密码',
    cancelBtnContent: '取消',
    confirmBtnContent: '完成',
  },
  SET_NEW_EMAIL_PWD: {
    desc: '请为您的新邮箱设置登录密码',
    successText: '邮箱换绑成功',
    errorText: '邮箱换绑失败',
    pwdLabel: '密码',
    confirmPwdLabel: '再次输入密码',
    cancelBtnContent: '返回',
    confirmBtnContent: '完成',
  },
};

export const VERIFY_EMAIL_PWD_TEXT = {
  REBIND_EMAIL: {
    desc: '您正在换绑登录邮箱，请先完成身份验证',
    pwdLabel: '密码',
    cancelBtnContent: '取消',
    confirmBtnContent: '下一步',
  },
  UNBIND_EMAIL: {
    desc: '您正在解绑登录邮箱，请先完成身份验证',
    pwdLabel: '密码',
    cancelBtnContent: '取消',
    confirmBtnContent: '完成',
  },
  RESET_EMAIL_PWD: {
    desc: '为了您的账号安全，请先输入旧密码进行验证',
    pwdLabel: '旧密码',
    cancelBtnContent: '取消',
    confirmBtnContent: '下一步',
  },
};

export enum PERSON_STATUS {
  PERSON = 'Person', // 有自然人的企业账号
  NET = 'Net', // 网状结构的企业账号
  ACCOUNT_USER = 'AccountUser', // 未关联自然人且未绑定手机号的企业账号
}

/**
 * 后端鉴权后返回页
 * */
import { useStore } from 'idpBase/store';

const CidpEntry = () => {
  const { rootStore } = useStore();
  React.useEffect(() => {
    rootStore.redirectToLandingPage();
  }, []);

  return null;
};

export default React.memo(CidpEntry);

/**
 * 腾讯会议侧 QR Code URL 内容生成接口， 保持与会议侧类似的实现逻辑
 * */
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { useState, useEffect, useCallback, useRef } from 'react';
import { uuid } from './utils';
import weMeetQRCodeStore from '../WeMeetQrCodeStore';
import { QRCodeStatus, InstanceType, ScanClientType, IdentifyType } from '../constants/Constants';

export type TQRCodeReturns = {
  isLoading: boolean; // 是否正在加载
  isUrlError: boolean; // 是否生成 url 出错了
  isCheckError: boolean; // 是否check status 出错了
  qrCodeUrl: string; // 二维码 url
  state: QRCodeStatus; // 二维码状态
  sessionKey: string;
  scanType: ScanClientType;
  generateQRCodeUrl: () => void;
};

export interface IQRCodeStatus {
  status: QRCodeStatus; // 扫码状态（1-未扫码，2-已扫码，3-已同意，4-已拒绝，5-失效）
  account_client_type: ScanClientType; // 扫码端类型（1-微信，2-企业微信，3-会议APP）
  auth_login_code: string; // 授权登录码
  corp_id: string; // 企业ID，只在扫码端是会议APP时存在
  identity_type: IdentifyType; // 账号类型（1个人账号 2企业账号），只在扫码端是会议APP时存在
}

export type TAuthorizedFn = (result: IQRCodeStatus) => void;

let customerId = '';
const getQRCodeUrl = (sessionKey: string, instance: number): string =>
  `${JSRuntime.meetingSite}/v2/scan-login-qrcode?sessionKey=${sessionKey}&instance=${instance}`;

const useQrCode = (
  instanceID: InstanceType,
  onAuthorized: TAuthorizedFn,
  onNotAuthorized: () => Promise<void>,
): TQRCodeReturns => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState<QRCodeStatus>(QRCodeStatus.none);
  const [scanType, setScanType] = useState<ScanClientType>(ScanClientType.none);
  const [qrCodeUrl, setQRCodeUrl] = useState<string>('');
  const [sessionKey, setSessionKey] = useState<string>('');
  const [isUrlError, setUrlError] = useState<boolean>(false);
  const [isCheckError, setCheckError] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  // 每次的刷新的 customer Id 都不一样
  useEffect(() => {
    customerId = uuid();
  }, []);

  // 获取 sessionID
  const getSessionKeyByID = useCallback(async () => {
    const [, sessionKey] = await Utils.resolvePromise(
      weMeetQRCodeStore.getQRCodeSessionKey({
        uuid: customerId,
        instance_id: instanceID,
      }),
    );
    setUrlError(!sessionKey);

    return sessionKey;
  }, [instanceID]);

  const generateQRCodeUrl = useCallback(async () => {
    setUrlError(false);
    setCheckError(false);
    setLoading(true);
    setState(QRCodeStatus.none);
    const sessionKey = await getSessionKeyByID();
    setSessionKey(sessionKey);

    const scanUrl = getQRCodeUrl(sessionKey, instanceID);
    setQRCodeUrl(scanUrl);
    setLoading(false);
  }, [instanceID, getSessionKeyByID]);

  const checkQRCodeState = useCallback(
    async (sessionKey: string): Promise<IQRCodeStatus | null> => {
      const [, result] = await Utils.resolvePromise(
        weMeetQRCodeStore.queryQRCodeStatus({
          session_key: sessionKey,
        }),
      );

      setCheckError(!result);

      return result;
    },
    [],
  );

  // 默认打开生成一次 url
  useEffect(() => {
    if (!instanceID) return;
    generateQRCodeUrl();
  }, [generateQRCodeUrl, instanceID]);

  const handleAuthorized = useCallback(
    (result: IQRCodeStatus) => {
      onAuthorized(result);
    },
    [onAuthorized],
  );

  const handleNotAuthorized = useCallback(() => onNotAuthorized(), [onNotAuthorized]);

  const qrCodeStateChecker = async (sessionKey: string) => {
    const timer = () => new Promise((resolve) => setTimeout(resolve, 3000));
    const result = (await Promise.race([checkQRCodeState(sessionKey), timer()])) as IQRCodeStatus;

    const { status, account_client_type: rAccountClientType } = result || {};

    status && setState(status);
    rAccountClientType && setScanType(rAccountClientType);

    if (status === QRCodeStatus.authorized) {
      handleAuthorized(result);
    }
    if (status === QRCodeStatus.unAuthorized) {
      handleNotAuthorized();
    }

    const { unAuthorized, authorized, loseEfficacy } = QRCodeStatus;

    if (![authorized, unAuthorized, loseEfficacy].some((s) => status === s)) {
      timeoutRef.current && clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        qrCodeStateChecker(sessionKey);
      }, 2000);
    }
  };

  useEffect(() => {
    if (!sessionKey) return;
    qrCodeStateChecker(sessionKey);

    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [sessionKey]);

  return {
    isLoading,
    isUrlError,
    isCheckError,
    qrCodeUrl,
    state,
    sessionKey,
    scanType,
    generateQRCodeUrl,
  };
};

export default useQrCode;

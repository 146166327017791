// 二维码状态
export enum QRCodeStatus {
  none = 0,
  unScanned = 1, // 未扫码
  scanned = 2, // 已扫码
  authorized = 3, // 已授权
  unAuthorized = 4, // 已拒绝
  loseEfficacy = 5, // 已失效
}

// instance 类型
export enum InstanceType {
  none = 0,
  win = 1, // PC - windows
  mac = 2, // Mac
  android = 3, // Android
  iPhone = 4, // iPhone
  web = 5, // web
  iPad = 6, // iPad
  aPad = 7, // aPad
  miniProgram = 8, // wechat mini program
}

export enum ScanClientType {
  none = 0,
  wechat = 1, // 微信
  wecom = 2, // 企业微信
  tencentMeeting = 3, // 腾讯会议 app
}

export enum IdentifyType {
  none = 0,
  personal = 1,
  corp = 2,
}

import { EMAIL_WHITELIST_ERR } from 'common/constants/Constants';

export const LOGIN_ERROR_MSG = {
  E0010007: '密码不正确，请重新输入', // 手机号+密码: 密码有误
  E0010028: '验证码不正确，请重新输入', // 手机号+验证码: 验证码有误
  E0010035: '切换失败！人员状态不可用', // 人员状态不可用（单个企业情况下）
  E0010036: '账号未绑定企业，登录失败！', // 账号未关联任何企业
  E0010019: '切换失败！人员状态不可用', // 用户被删除
  E0010006: '切换失败！当前租户不可用', // 企业被解散
  E0010061: '该手机号已经被绑定，请直接使用该手机号登录或更换绑定的手机号。', // 手机号已被绑定或者处于网状状态中
  E0010161: '今日验证码发送达到限制',
  [EMAIL_WHITELIST_ERR.code]: EMAIL_WHITELIST_ERR.msg,
};

export enum VERIFY_TYPE {
  CODE,
  PWD,
}

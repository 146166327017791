import { Input, InputValue } from 'tdesign';
import VerificationCodeScss from './VerificationCode.module.scss';
import Utils from 'common/utils';

interface IProps {
  onChange: (values: InputValue) => void;
  validator?: any;
  error?: boolean;
  disabled?: boolean;
}

interface IState {
  code: InputValue[];
}

const INPUT_LENGTH = 6; // 目前我们的验证码只有6位

const prefixCls = 'eid-verify-code-box';
class VerificationCode extends React.PureComponent<IProps, IState> {
  private inputDom: any[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      code: new Array(INPUT_LENGTH).fill(''),
    };
  }

  componentDidMount() {
    const { disabled } = this.props;
    if (disabled) return;
    // autofocus问题较多，手动focus一下
    requestAnimationFrame(() => {
      this.focusOn(0);
    });
  }

  private onPaste = (pasteValue: string) => {
    if (!pasteValue) return;
    const isNumber = /^\d+$/.test(pasteValue);
    if (isNumber) {
      // input组件有点问题 setState之后不会触发onchange 这里直接调用onChange
      // 不要改动这个onChange和下面setState的顺序！！不然会和didUpdate中的onChange产生冲突
      this.props.onChange?.(pasteValue);
      this.setState({
        code: pasteValue.split(''),
      });
      this.focusOn(Math.min(pasteValue.length, INPUT_LENGTH - 1));
    }
  };

  componentDidUpdate() {
    const { error, onChange, disabled } = this.props;
    const { code } = this.state;
    // 如果是error，并且code中含有6个值才去清空
    const codeValue = _.isArray(code) ? code.join('') : '';
    if (error && codeValue.length === INPUT_LENGTH) {
      this.setState({
        code: new Array(INPUT_LENGTH).fill(''),
      });
      if (!disabled) this.focusOn(0);
      if (_.isFunction(onChange)) {
        onChange('');
      }
    }
  }

  private onChange(value: InputValue, i: number) {
    const { code } = this.state;
    const { onChange, validator } = this.props;
    if (_.isFunction(validator)) {
      if (value !== '' && !validator(value, i)) {
        return;
      }
    }
    // 一次性超过6位的输入认为是粘贴、键盘回填事件
    if (value?.length >= INPUT_LENGTH) {
      // 从末位开始取值，倒数6位，因为粘贴前原格子中可能已经有数字了。
      const pasteValue = value?.slice?.(value.length - INPUT_LENGTH) || '';
      this.onPaste(pasteValue);
      return;
    }

    const newCode = code.map((v: InputValue) => v);
    newCode[i] = value.slice(-1); // 保证每个格子只有1位数字
    this.setState({ code: newCode });
    if (value !== '') {
      this.focusOn(i + 1);
    }
    if (_.isFunction(onChange)) {
      if (newCode.every((v) => v !== '')) {
        this.inputDom[i]?.inputElement?.blur();
      }
      const codeValue = _.isArray(newCode) ? newCode.join('') : '';
      onChange(codeValue);
    }
  }

  private getPrevBox(i: number) {
    return this.inputDom[i - 1];
  }

  private getNextBox(i: number) {
    return this.inputDom[i + 1];
  }

  private focusOn(i: number) {
    const element = this.inputDom[i];
    if (element) {
      element.focus();
    }
  }

  private onKeyDown(
    value: InputValue,
    context: { e: React.KeyboardEvent<HTMLInputElement> },
    i: number,
  ) {
    const event = context.e;
    if (event.stopPropagation) event.stopPropagation();

    switch (_.get(event, 'keyCode')) {
      case 8: // 删除完之后，退回到上一个输入框
        if (value === '') {
          // 如果空的话，那么就退回到上一个输入框
          this.focusOn(i - 1);
        }
        break;
      case 37: // 左
      case 38: // 上
        if (this.getPrevBox(i)) {
          this.focusOn(i - 1);
        } else {
          this.focusOn(i);
        }
        break;
      case 39: // 右
      case 40: // 下
        if (this.getNextBox(i)) {
          this.focusOn(i + 1);
        } else {
          this.focusOn(i);
        }
        break;
      default: {
        // NOTICE input的maxLength是1，先清空才能在focus的时候修改已输入的值
        const { code } = this.state;
        const newCode = [...code];
        newCode[i] = '';
        this.setState({ code: newCode });
        break;
      }
    }
  }

  render() {
    const { code = [] } = this.state;
    const { error, disabled } = this.props;
    const prefixCodeBox = [];
    const suffixCodeBox = [];
    for (let i = 0; i < INPUT_LENGTH; i++) {
      const content = (
        <div
          key={`verifyItem${i}`}
          className={Utils.uniteClass(VerificationCodeScss.codeBoxWrapper, `${prefixCls}-wrapper`)}
        >
          <Input
            type="tel"
            autocomplete="false"
            value={code[i]}
            ref={(dom) => {
              this.inputDom[i] = dom;
            }}
            disabled={disabled}
            placeholder=""
            onChange={(value: InputValue) => this.onChange(value, i)}
            onKeydown={(value: InputValue, context: { e: React.KeyboardEvent<HTMLInputElement> }) =>
              this.onKeyDown(value, context, i)
            }
          />
        </div>
      );
      if (i < INPUT_LENGTH / 2) {
        prefixCodeBox.push(content);
      } else {
        suffixCodeBox.push(content);
      }
    }
    return (
      <div
        className={Utils.uniteClass(
          VerificationCodeScss.codeBoxContainer,
          error ? VerificationCodeScss.errorBoxContainer : '',
          disabled ? VerificationCodeScss.disabled : '',
          `${prefixCls}-container`,
        )}
      >
        {prefixCodeBox}
        <div className={Utils.uniteClass(VerificationCodeScss.codeLine, `${prefixCls}-line`)} />
        {suffixCodeBox}
      </div>
    );
  }
}

export default VerificationCode;

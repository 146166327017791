import Utils from '../../utils';
import IconScss from './Icon.module.scss';

interface IProps {
  /** 图标类名 */
  className?: string;
  /** Icon 名称 */
  type: string;
  /** font size 或 svg size */
  size?: number;
  /** 鼠标 hover 图标 */
  pointer?: boolean;
  /** 禁用状态 */
  disabled?: boolean;
  /** 点击事件 */
  onClick?: (e?: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>) => void;
  /** 鼠标按下事件 */
  onMouseDown?: (e?: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>) => void;
  /** font color */
  color?: string;
  /** 是否为 svg Icon */
  svg?: boolean;
  /** 宽 */
  width?: number | string;
  /** 高 */
  height?: number | string;
  /** 覆盖样式 */
  style?: object;
}

const Icon: React.FC<IProps> = ({
  className,
  type,
  size,
  color,
  pointer,
  disabled,
  style,
  svg,
  width,
  height,
  onClick,
  ...restProps
}: IProps) => {
  const isPointer = pointer || _.isFunction(onClick);
  const normalProps = {
    className: Utils.uniteClass(
      'iconfont',
      IconScss.iconWrapper,
      svg ? IconScss.svg : '',
      svg ? '' : `icon-${type}`,
      className,
    ),
    onClick: disabled ? undefined : onClick,
  };

  let cursor = 'default';
  if (disabled) {
    cursor = 'not-allowed';
  } else if (isPointer) {
    cursor = 'pointer';
  }
  const commonStyle = {
    cursor,
  };

  if (!svg) {
    return (
      <i
        {...restProps}
        {...normalProps}
        style={{
          fontSize: size,
          color,
          ...style,
          ...commonStyle,
        }}
      />
    );
  }

  return (
    <svg
      {...restProps}
      {...normalProps}
      aria-hidden="true"
      style={{
        width: width || size,
        height: height || size,
        ...style,
        ...commonStyle,
      }}
    >
      <use xlinkHref={`#icon-${type}`} />
    </svg>
  );
};

export default React.memo(Icon);

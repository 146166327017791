import Utils from 'common/utils';
import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';
import message from 'common/components/Message';
import Icon from 'common/components/Icon';
import { QRCodeStatus, InstanceType, ScanClientType } from './constants/Constants';
import useQrCode, { TAuthorizedFn, IQRCodeStatus } from './hooks/useQrCode';
import WeMeetQrCodeScss from './style/WeMeetQrCode.module.scss';

const QR_CODE_SIZE = 240 - 16 * 2; // 默认大小 200，白边每边 18px

interface IProps {
  /** 认证成功后回调 */
  onAuthorized?: TAuthorizedFn; // account_client_type 用来判断是那种客户端的认证
  /** 是否只允许微信扫码 */
  onlyWechat?: boolean;
  /** 后端保存后的 error 处理 */
  authError?: string;
  /** error 后刷新二维码的回调，外部要在这个回调里清空 authError */
  afterRefreshError?: () => void;
}

export * from './constants/Constants';
export type { TAuthorizedFn, IQRCodeStatus };

// 登录入口
const WeMeetQrCode: React.FC<IProps> = (props: IProps) => {
  const { authError, onAuthorized, onlyWechat, afterRefreshError } = props;
  // 被授权时处理请求
  const handleAuthorized: TAuthorizedFn = (result) => {
    const scanType = result.account_client_type;
    if (scanType === ScanClientType.wechat) {
      onAuthorized?.(result);
    } else if (scanType === ScanClientType.tencentMeeting) {
      if (onlyWechat) {
        message.error('请使用微信扫码登录');
      } else {
        onAuthorized?.(result);
      }
    } else {
      message.error('请使用微信或者腾讯会议App扫码登录');
    }
  };

  const { isLoading, isUrlError, qrCodeUrl, state, scanType, generateQRCodeUrl } = useQrCode(
    InstanceType.web,
    handleAuthorized,
    async () => generateQRCodeUrl(),
  );

  useEffect(() => {
    isUrlError && message.error('生成二维码错误, 请重新生成');
  }, [isUrlError]);

  // 扫码提示
  const renderScannedTips = () => {
    switch (scanType) {
      case ScanClientType.tencentMeeting:
        return '请在腾讯会议手机端确认登录';
      case ScanClientType.wechat:
        return '请在微信端确认登录';
      default:
        return '';
    }
  };

  // 是否改变下面的tips
  const shouldShowScannedMask = state === QRCodeStatus.authorized || state === QRCodeStatus.scanned;
  // 是否需要展示 重新生成 mask
  const shouldShowReGenerateMask = isUrlError || state === QRCodeStatus.loseEfficacy;

  return (
    <div className={WeMeetQrCodeScss.qrCodeContainer}>
      {isLoading ? (
        <div className={WeMeetQrCodeScss.loading}>
          <Icon type="TxLoading" size={32} />
        </div>
      ) : null}
      {shouldShowReGenerateMask ? (
        <div className={WeMeetQrCodeScss.qrCodeMask} onClick={generateQRCodeUrl}>
          <Icon type="TxRefresh" size={20} pointer className={WeMeetQrCodeScss.qrCodeFlash} />
          <span className={Utils.uniteClass('eid-heading-2', WeMeetQrCodeScss.qrCodeFlashHint)}>
            刷新二维码
          </span>
        </div>
      ) : null}
      {shouldShowScannedMask && !authError ? (
        <div className={WeMeetQrCodeScss.qrCodeScannedMask}>
          <Icon type="TxCircle2" size={52} className={WeMeetQrCodeScss.qrCodeScanned} />
          <span className={Utils.uniteClass('eid-heading-2', WeMeetQrCodeScss.qrCodeScannedTitle)}>
            扫码成功
          </span>
          <span className={Utils.uniteClass('eid-text', WeMeetQrCodeScss.qrCodeScannedTips)}>
            {renderScannedTips()}
          </span>
        </div>
      ) : null}
      {authError ? (
        <div className={WeMeetQrCodeScss.qrCodeScannedMask}>
          <Icon type="TxRemind2" size={52} className={WeMeetQrCodeScss.qrCodeScannedError} />
          <span className={Utils.uniteClass('eid-heading-2', WeMeetQrCodeScss.qrCodeScannedTitle)}>
            扫码失败
          </span>
          <span
            className={Utils.uniteClass(
              'eid-text',
              WeMeetQrCodeScss.qrCodeScannedTips,
              WeMeetQrCodeScss.scanError,
            )}
          >
            {authError}
          </span>
          <span
            className={WeMeetQrCodeScss.qrCodeScannedRefresh}
            onClick={() => {
              generateQRCodeUrl();
              afterRefreshError?.();
            }}
          >
            刷新二维码
          </span>
        </div>
      ) : null}
      <div className={WeMeetQrCodeScss.qrCodeWrapper}>
        <QRCode value={qrCodeUrl} size={QR_CODE_SIZE} />
      </div>
    </div>
  );
};

export default React.memo(WeMeetQrCode);

import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator';
import APINames from '../APINames';

// idpType: 遵循 common/constants/Constants 下的IDP_TYPE

class SsoIdpApi extends BaseApi {
  public requestInstance = new Request(APINames.SSO_IDP);

  bindUser(idpType: string, params: { code: string }) {
    return this.requestInstance.post([`${idpType}`, ':bind'], params);
  }

  unbindUser(idpType: string) {
    return this.requestInstance.post([`${idpType}`, ':unbind']);
  }
}

export default new SsoIdpApi();

import BeaconAction from '@tencent/beacon-web-sdk';
import { ConstantEnum } from '@tencent/web-base';
import JSRuntime from 'common/utils/JSRuntime';

let beaconActionInsNew: BeaconAction | null = null;
let wemeetBeaconReportCommonInfo: Record<string, any> = {};

const PROD_REPORT_URL = 'https://report.meeting.tencent.com/api/data-report-beacon';
const TEST_REPORT_URL = 'https://report-test.meeting.tencent.com/api/data-report-beacon';

const isProd = JSRuntime.isProductSite;

/**
 * 获取 e# 事件的 act page_name element_name
 * @param eventName
 * @returns
 */
export function getActPageModule(eventName: string) {
  if (eventName.startsWith('e#')) {
    const temp = eventName.split('#');
    const [, pageName, elementName, act] = temp;
    return {
      act,
      page_name: pageName,
      element_name: elementName,
    };
  }
  return null;
}

/**
 * 获取灯塔 sdk 提供的 common 参数
 * @param beacon
 * @returns
 */
function getCommonInfo(beacon: any) {
  try {
    const info = beacon?.commonInfo;
    return {
      ...info,
      language: info?.language,
      resolution: info?.pixel,
      query: info?.query,
      user_agent: info?.userAgent,
      sdkVersion: info?.sdkVersion,
      deviceId: info?.deviceId,
      channelID: info?.channelID,
      openid: info?.openid,
      unid: info?.unid,
    };
  } catch (err) {
    return {};
  }
}

// 初始化灯塔 sdk
export function initBeaconSDK(reportParams: any = {}) {
  if (beaconActionInsNew) {
    return;
  }

  generateBeacon(reportParams);
}

/**
 * 生成灯塔 sdk 实例并挂载在 window 上
 * @param isDebug
 * @param debugKey
 * @param prodKey
 * @param type
 * @param report
 */
function generateBeacon(report: any = {}) {
  // 新通道实例
  const beaconNew = new BeaconAction({
    appkey: isProd ? ConstantEnum.WEMEET_BEACON_PROD_KEY : ConstantEnum.WEMEET_BEACON_DEBUG_KEY,
    uploadUrl: isProd ? PROD_REPORT_URL : TEST_REPORT_URL,
  });

  const commonOptions = {
    source_id: report?.source_id || '7',
    report_cmd: isProd ? 'cmd_prod_old_atta' : 'cmd_test_new_atta',
    report_flag: report?.report_flag || '3',
    request_time: `${new Date().getTime()}`,
    product: 'meeting',
  };
  // 新通道需要添加 common 参数
  beaconNew.addAdditionalParams(commonOptions);
  // 获取 sdk 提供的 common 参数
  const commonInfo = getCommonInfo(beaconNew);

  beaconActionInsNew = beaconNew;
  wemeetBeaconReportCommonInfo = commonInfo;
}

/**
 * 开始上报(会议)
 * @param eventName
 * @param reportData
 */
export function doBeaconReport(eventName: string, reportData: any) {
  initBeaconSDK(reportData);
  beaconActionInsNew?.onDirectUserAction(eventName, {
    ...reportData,
    ...wemeetBeaconReportCommonInfo,
  });
}

/**
 * 邮件密码设置
 * */
import { Form, FormProps } from 'tdesign';
import { useStore, convert } from 'idpBase/store';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import Button from 'common/components/Button';
import EmailPwdSetForm from 'common/components/EmailPwdSetForm';
import { CONTENT, RESULT_TYPES } from '../constants/Constants';
import VerifyScss from '../style/Verify.module.scss';

const EmailPwdSet = () => {
  const { invitationStore } = useStore();
  const [form] = Form.useForm();
  const [shouldSubmit, setShouldSubmit] = React.useState(false);
  const isMobileDevice = JSRuntime.isMobileDevice;
  const { isJoinLoading } = invitationStore;

  const onBack = () => {
    invitationStore.updateStore({ dialogVisible: false });
  };

  const onSubmit = async () => {
    const [, res] = await Utils.resolvePromise(form.validate());
    if (res !== true) return;

    invitationStore.updateStore({ isJoinLoading: true });
    const values = form.getFieldsValue(true);
    const password = _.get(values, 'password');
    const [error] = await Utils.resolvePromise(invitationStore.setEmailPassword(password));

    invitationStore.updateStore({ isJoinLoading: false });
    if (error) {
      invitationStore.global.handleError(error);
    } else {
      invitationStore.updateStore({
        dialogVisible: false,
        currentContent: CONTENT.RESULT_PAGE,
        resultType: RESULT_TYPES.JOIN_SUCCESS,
      });
    }
  };

  const onValuesChange: FormProps['onValuesChange'] = (changedValues, allValues) => {
    const { password, confirmPassword } = allValues;

    if (password && confirmPassword) setShouldSubmit(true);
  };

  return (
    <>
      <EmailPwdSetForm
        pwdLabel="密码"
        pwdRequiredRule="请输入密码"
        confirmPwdLabel="再次输入密码"
        confirmPwdRequiredRule="请再次输入密码"
        form={form}
        onEnter={onSubmit}
        onValuesChange={onValuesChange}
      />
      <div className={VerifyScss.btnWrapper}>
        {isMobileDevice ? null : (
          <Button
            className={VerifyScss.backBtn}
            color="secondary"
            disabled={isJoinLoading}
            onClick={onBack}
          >
            取消
          </Button>
        )}
        <Button loading={isJoinLoading} disabled={!shouldSubmit} onClick={onSubmit}>
          完成
        </Button>
      </div>
    </>
  );
};

export default convert(EmailPwdSet);

/**
 * 登录后的 ERROR 页面展示
 * */
import Button from 'common/components/Button';
import Utils from 'common/utils';
import Icon from 'common/components/Icon';
import JSRuntime from 'common/utils/JSRuntime';
import ErrorScss from './error.module.scss';

const CidpError = () => {
  const query = JSRuntime.parseLocationSearch();
  const { errorCode, requestId, errorMsg, redirectUrl, idpType } = query || {};

  const jumpToLogin = React.useCallback(() => {
    Utils.redirectToIdp(
      redirectUrl ? `?redirectUrl=${window.encodeURIComponent(redirectUrl)}` : '',
    );
  }, []);

  return (
    <div className={ErrorScss.wrapper}>
      <div className={ErrorScss.content}>
        <div className={ErrorScss.errorMsg}>
          <Icon className={ErrorScss.icon} type="TxNotice" size={64} />
          <p className={Utils.uniteClass('eid-text-2', ErrorScss.loginError)}>登录失败</p>
          <p className="eid-desc-2">请重试或联系管理员</p>
          <div className={ErrorScss.error}>
            {errorCode && <p>error: {errorCode}</p>}
            <p>error_description: {errorMsg || '未知错误'}</p>
            {requestId && <p>request_id: {requestId}</p>}
          </div>
        </div>
        {idpType === 'jwt' ? null : (
          <Button className={ErrorScss.loginButton} onClick={jumpToLogin}>
            重新登录
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(CidpError);

import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator';

class RolesApi extends BaseApi {
  public requestInstance = new Request(APINames.ROLES);

  // 获取角色列表, 不传 pageNumber 可获得全量数据
  getRoles(params: { pageNumber?: number; pageSize?: number; keyword?: string }) {
    return this.requestInstance.get('', params);
  }
}
export default new RolesApi();

/** 企业认证认证状态 */
export enum AUTH_STATUS {
  UNKNOWN = 0,
  INITIAL = 1,
  /** 认证中 */
  INPROGRESS = 2,
  /** 认证失败 */
  FAIL = 3,
  /** 认证成功 */
  SUCCESS = 4,
  /** 过期 */
  EXPIRED = 5,
  /** 失效 */
  INVALID = 6,
  /** 即将过期 */
  COMINGEXPIRED = 7,
  /** 撤回状态 */
  WITHDRAW = 8,
}

/** 企业认证授权状态 */
export enum AUTHORIZATION_STATUS {
  /** 初始状态，未授权 */
  INITIAL = 0,
  /** 已授权 */
  AUTHORIZED = 1,
  /** 未授权，但是以前授权过 */
  NO_AUTHORIZE = 2,
}

/** OCR类型 */
export enum OCR_TYPE {
  /** 身份证正面 */
  IDCARD_FRONT = 1,
  /** 身份证反面 */
  IDCARD_BACK = 2,
  /** 电子营业执照 */
  BUSINESS_LICENSE = 3,
}

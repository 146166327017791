import { AccountApi } from 'idpBase/api';
import { AUTH_TYPE } from 'idpBase/Constants';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';

class ProgressStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ProgressStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public authInfo = {};

  /**
   * 获取认证信息
   */
  public getAuthInfo(authType: AUTH_TYPE) {
    AccountApi.getAuthInfo(authType)
      .then((res) => {
        this.updateStore({
          authInfo: res,
        });
      })
      .catch((error: Error) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isBlockLoading: false,
        });
      });
  }

  public revokeAuth(id: string) {
    return AccountApi.revokeAuth(id);
  }
}
export default ProgressStore;

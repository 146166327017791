import { VERIFY_CODE_LIMIT_ERR } from 'common/constants/Constants';
import { useStore, convert } from 'idpBase/store';
import { VERIFY_CODE_ERROR } from '../constants/Constants';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import VerifyCode from 'common/compositions/VerifyCodeWithMsg';
import Button from 'common/components/Button';
import VerifyScss from '../style/Verify.module.scss';

const CodeVerify = () => {
  const { invitationStore } = useStore();
  const { isJoinLoading, invitationInfo, countdown, codeErrMsg } = invitationStore;
  const [code, setCode] = React.useState<string>('');

  const mobile = m.get(invitationInfo, 'mobile');
  const isMobileDevice = JSRuntime.isMobileDevice;

  const onBack = () => {
    invitationStore.updateStore({
      dialogVisible: false,
    });
  };

  const onSubmit = async (value?: string) => {
    invitationStore.updateStore({ isJoinLoading: true });

    const [err] = await Utils.resolvePromise(invitationStore.checkInvitationInfo());
    if (err) {
      invitationStore.updateStore({ isJoinLoading: false });
      return;
    }

    invitationStore
      .verifyCode(value || code)
      .then(() => {
        invitationStore.acceptInvitation();
      })
      .catch((errCode) => {
        if (VERIFY_CODE_ERROR[errCode]) {
          setCode('');
        }
      })
      .finally(() => {
        invitationStore.updateStore({ isJoinLoading: false });
      });
  };

  const onCodeChange = (value: string) => {
    setCode(value);
    if (value) {
      invitationStore.updateStore({
        codeErrMsg: '',
      });
    }
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  const onSendCode = () =>
    invitationStore
      .getVerifyCode(mobile)
      .then((res) => {
        invitationStore.updateStore({
          verificationSessionId: _.get(res, 'verificationSessionId'),
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        const errorCode = _.get(err, 'data.errCode');
        if (VERIFY_CODE_LIMIT_ERR.has(errorCode)) {
          invitationStore.updateStore({
            codeErrMsg: errorCode,
          });
        } else {
          invitationStore.global.handleError(err);
        }

        return Promise.reject(err);
      });

  return (
    <div className={VerifyScss.verifyCodeWrapper}>
      <div className="eid-desc-2">我们需要先验证您的账号，验证通过后才能完成加入企业</div>
      <VerifyCode
        mobile={mobile}
        seconds={countdown}
        errorMsg={codeErrMsg}
        onCodeChange={onCodeChange}
        onSendCode={onSendCode}
      />
      <div className={VerifyScss.btnWrapper}>
        {isMobileDevice ? null : (
          <Button
            className={VerifyScss.backBtn}
            color="secondary"
            disabled={isJoinLoading}
            onClick={onBack}
          >
            返回
          </Button>
        )}
        <Button
          loading={isJoinLoading}
          disabled={!code || code.length < 6}
          onClick={() => {
            onSubmit();
          }}
        >
          完成
        </Button>
      </div>
    </div>
  );
};

export default convert(CodeVerify);

/**
 * 退出登录页面，暂时只提供iframe内使用
 * */
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import BlankPage from 'common/components/BlankPage';
import { useStore } from 'idpBase/store';

const LogoutEntry = () => {
  const { rootStore } = useStore();
  React.useEffect(() => {
    rootStore
      .logout()
      .then(() => {
        if (JSRuntime.thirdLandingPage) {
          Utils.safeRedirect(JSRuntime.thirdLandingPage);
        }
      })
      .catch(Utils.noop);
  }, []);

  return <BlankPage type="LOADING" style={{ height: '100%' }} />;
};

export default React.memo(LogoutEntry);
